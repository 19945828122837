import { Stack } from '@mui/material';
import {
  LoadingContentContainer,
  LoadingImg,
  LoadingText,
} from './LoadingContentStyled';
import LogoIReferIcon from '../../../assets/images/icon-logo-irefer-trademark.png';

type LoadingContentProps = {
  text?: string;
};

const LoadingContent = ({ text }: LoadingContentProps) => {
  return (
    <LoadingContentContainer>
      <Stack direction="column" gap={3} alignItems="center">
        <LoadingImg src={LogoIReferIcon} alt="Triangle Loading" />
        {text ? (
          <LoadingText>{text}</LoadingText>
        ) : (
          <LoadingText>Just a moment...</LoadingText>
        )}
      </Stack>
    </LoadingContentContainer>
  );
};

export default LoadingContent;
