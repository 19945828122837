import {
  CartIcon,
  CashIcon,
  ChainIcon,
  ClassicMouseIcon,
  EyeIcon,
  ShoppingCartIcon,
  TargetIcon,
  WalletIcon,
} from '../components/Icons';
import { WatchIcon } from '../components/Icons/WatchIcon';

const commonConstants = {
  SOMETHING_WENT_WRONG: 'Something went wrong',
  EXPIRED_SESSION_MESSAGE: 'Your session has expired. Please Login',
  UNABLE_TO_VERIFY: 'Unable to verify',
  STRIPE_ACCOUNT_AGREEMENT_LINK: 'https://stripe.com/legal/connect-account',
  MERCHANT_TERMS_AND_CONDITIONS_LINK:
    'https://www.i-refer.app/merchanttermsandconditions-ireferapp',
  PRIVACY_POLICY_LINK: 'https://www.i-refer.app/privacypolicy',
  VENDOR_ROLE: 'vendor',
  BUSINESS_CATEGORY_LIST: [
    { label: 'Fashion', value: 'Fashion' },
    { label: 'Travel', value: 'Travel' },
    { label: 'Leisure', value: 'Leisure' },
    { label: 'Food', value: 'Food' },
    { label: 'Beverage', value: 'Beverage' },
    { label: 'Lifestyle', value: 'Lifestyle' },
    { label: 'Living', value: 'Living' },
    { label: 'Beauty', value: 'Beauty' },
    { label: 'Wellness', value: 'Wellness' },
    { label: 'Health', value: 'Health' },
    { label: 'Fitness', value: 'Fitness' },
    { label: 'Video Gaming', value: 'Video Gaming' },
    { label: 'Automotive', value: 'Automotive' },
    { label: 'Technology', value: 'Technology' },
  ],
  DOCUMENT_TYPE_LIST: [
    { label: 'Passport', value: 'Passport' },
    {
      // eslint-disable-next-line quotes
      label: `Driver Licence (Driver's license)`,
      // eslint-disable-next-line quotes
      value: `Driver Licence (Driver's license)`,
    },
    {
      label: 'Photo Card',
      value: 'Photo Card',
    },
    {
      label: 'New South Wales Driving Instructor Licence',
      value: 'New South Wales Driving Instructor Licence',
    },
    {
      label: 'Tasmanian Government Personal Information Card',
      value: 'Tasmanian Government Personal Information Card',
    },
    {
      label: 'ImmiCard',
      value: 'ImmiCard',
    },
    {
      label: 'Proof of Age card',
      value: 'Proof of Age card',
    },
    {
      label: 'Australian Defence Force (ADF) identification card (Military ID)',
      value: 'Australian Defence Force (ADF) identification card (Military ID)',
    },
    {
      label: 'Other',
      value: 'Other',
    },
  ],
  REFERRERS_DASHBOARD_DEFAULT_QUERY_PARAMS: {
    page: 1,
    limit: 10,
    keyword: '',
    direction: 'desc',
    filter: 'date',
  },
  REFERRALS_DASHBOARD_DEFAULT_QUERY_PARAMS: {
    page: 1,
    limit: 10,
    keyword: '',
    direction: 'asc',
    dateFrom: '',
    dateTo: '',
    filter: '',
  },
  TRANSACTIONS_DASHBOARD_DEFAULT_QUERY_PARAMS: {
    page: 1,
    limit: 10,
    keyword: '',
    direction: 'desc',
    dateFrom: '',
    dateTo: '',
    filter: '',
  },
  ROLE: {
    VENDOR: 'vendor',
    USER: 'user',
  } as const,
  API_NAME: {
    VENDOR: 'vendor',
    USER: 'user',
    PAYMENT: 'payment',
    NOTIFICATION: 'notification',
  } as const,
  API_PATH: {
    INFO: '/info',
    SEND_SMS: '/send-sms',
    SMS_VERIFICATION: '/sms-verification',
    SMS_2FA_VERIFICATION: '/2fa-verification',
    VALIDATE_INFO: '/validate-info',
    UPDATE_INFO: '/update-info',
    REFERRERS: '/referees',
    REFERRER: '/referee',
    REFERRALS: '/referrals',
    REFERRAL: '/referral',
    LINK_BANK_ACCOUNT: '/link-bank-account',
    GET_LINKED_BANK_INFO: '/bank-account',
    DASHBOARD: '/dashboard',
    TRANSACTIONS: '/transactions',
    TRANSACTION: '/transaction',
    LINK_CARD: '/link-card',
    UPDATE_DOMAIN: '/update-domain',
    REFUND: '/refund',
    BUSINESS_CATEGORIES: '/business-categories',
  } as const,
  DIRECTION: {
    ASC: 'asc',
    DESC: 'desc',
  } as const,
  FILTER: {
    DATE: 'date',
    PERFORMANCE: 'performance',
  } as const,
  FILTER_TIME: {
    ALL_TIME: { label: 'All Time', value: '' },
    LAST_3_MONTHS: { label: 'Last 3 Months', value: 'LAST_3_MONTHS' },
    LAST_30_DAYS: { label: 'Last 30 Days', value: 'LAST_30_DAYS' },
    LAST_7_DAYS: { label: 'Last 7 Days ', value: 'LAST_7_DAYS' },
  } as const,
  DATE_FORMAT: {
    MM_DD_YYYY: 'MM/DD/YYYY',
    MM_DD_YY: 'MM/DD/YY',
    DD_MM_YYYY: 'DD/MM/YYYY',
    DD_MM_YYYY_1: 'DD-MM-YYYY',
    DD_MMM_YYYY: 'DD MMM YYYY' /* 12 Apr 2023 */,
  } as const,
  TYPE_2FA: '2fa',
  BANK_ACCOUNT_TYPE: {
    INDIVIDUAL: 'individual',
    COMPANY: 'company',
  } as const,
  STRIPE_STATUS_TYPE: {
    NOT_CREATED: 'not-created',
    ACTION_REQUIRED: 'action-required',
    PENDING: 'pending',
    ACTIVE: 'active',
  } as const,
  LINK_BANK_ACCOUNT_ACTION: {
    CREATE_STRIPE_ACCOUNT: 'CREATE_STRIPE_ACCOUNT',
    UPDATE_STRIPE_ACCOUNT: 'UPDATE_STRIPE_ACCOUNT',
  } as const,
  MAPPING_TOTAL_PERFORMANCE_ICON: {
    total_link_purchases: <ShoppingCartIcon />,
    total_referral_sales: <CashIcon />,
    consersions: <CartIcon />,
    consersions_rate: <TargetIcon />,
    total_impressions: <EyeIcon />,
    total_link_clicks: <ClassicMouseIcon />,
    active_referrals: <ChainIcon />,
    total_referee_profit: <WalletIcon />,
    duration: <WatchIcon />,
  } as const,
  /* millisecond */
  STALE_TIME: {
    MIN_1: 1000 * 60,
    SEC_20: 1000 * 20,
  } as const,
  SOCIAL_MEDIA: {
    GOOGLE: 'google',
    APPLE: 'apple',
  } as const,
  VERIFIED: 'verified',
  REFUND_REASON_LIST: [
    {
      label: 'The product did not meet the customer’s expectations',
      value: 'The product did not meet the customer’s expectations',
    },
  ] as const,
  QUERY_KEYS: {
    DATE_FROM: 'dateFrom',
    DATE_TO: 'dateTo',
    PAGE: 'page',
  },
};

export default commonConstants;
