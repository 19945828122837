import { styled, Stack } from '@mui/material';
import { MessageParagraph } from 'pages/PageNotFound/PageNotFoundStyled';
import { useEffect, useRef } from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';
import useCounter from '../../hooks/Common/useCounter';
import i_refer_text_without_logo from '../../assets/images/irefer-text-without-logo.png';

const StyledImage = styled('img')(({ theme }) => ({
  width: 500,
  [theme.breakpoints.down('md')]: {
    width: 400,
  },
}));

const Center = styled(Stack)({
  height: '100vh',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'center',
});

export default function TransitionStripe() {
  const [searchParams] = useSearchParams();
  const stripeLink = searchParams.get('stripe-link');
  const { counter } = useCounter(3);
  const runOnce = useRef(false);
  useEffect(() => {
    if (counter <= 1 && !!stripeLink && !runOnce.current) {
      window.location.href = decodeURIComponent(stripeLink);
      runOnce.current = true;
    }
  }, [counter, stripeLink]);
  if (!stripeLink) {
    return <Navigate to="/invalid-link" />;
  }

  return (
    <Center>
      <Stack justifyContent="center" alignItems="center" gap={4}>
        <StyledImage src={i_refer_text_without_logo} />
        <Stack gap={2}>
          <MessageParagraph>
            You are now being securely connected with Stripe.
          </MessageParagraph>
          <MessageParagraph sx={{ textAlign: 'center' }}>
            You will be redirected in {counter}s
          </MessageParagraph>
        </Stack>
      </Stack>
    </Center>
  );
}
