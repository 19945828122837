import {
  Container,
  AccountProfile,
  Links,
  LinkItem,
  LinkTitle,
  LinkCaption,
  ChevronIcon,
} from '../AccountStyled';
import BackButton from '../../../components/Common/BackButton/BackButton';
import { useNavigate } from 'react-router-dom';
import { CommonTitleText } from '../../../components/Common/Styled/TypographyStyed';

const links = [
  {
    title: 'Password',
    caption: 'Update password details.',
    page: '/auth/update-password',
  },
  {
    title: 'Website URL',
    caption: 'Update your eCommerce store URL.',
    page: '/auth/update-website-url',
  },
];

const Settings = () => {
  const navigate = useNavigate();
  return (
    <Container>
      <AccountProfile sx={{ position: 'relative' }}>
        <BackButton onClick={() => navigate('/account')} />
        <CommonTitleText mb={2}>SETTINGS</CommonTitleText>
        <Links>
          {links.map(({ title, caption, page }, index) => {
            return (
              <LinkItem key={index} onClick={() => navigate(page)}>
                <LinkTitle>{title}</LinkTitle>
                <LinkCaption>{caption}</LinkCaption>
                <ChevronIcon />
              </LinkItem>
            );
          })}
        </Links>
      </AccountProfile>
    </Container>
  );
};

export default Settings;
