import { Stack } from '@mui/material';
import { useLoadScript } from '@react-google-maps/api';
import LoadingContent from 'components/Common/LoadingContent/LoadingContent';
import {
  StyledBox,
  StyledLoadingButton,
  StyledOutlinedLoadingButton,
} from 'components/Common/Styled/CommonStyled';
import {
  CommonSubtext,
  CommonTitleText,
  Text16Weight600,
} from 'components/Common/Styled/TypographyStyed';
import commonConstants from 'constants/common.constant';
import { useCreateStripeAccountV2, useGetBankInfoV2 } from 'hooks/Info/useInfo';
import { useCallback, useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation, useNavigate } from 'react-router-dom';
import { themes } from 'theme';
import StripeLogo from 'assets/images/stripe-logo.png';
import { ExclamationIcon, SuccessIcon } from 'components/Icons';

export const INDEX_STEP_LBA = {
  YOUR_WEBSITE: 4,
  PERSONAL_DETAILS: 1,
  ADDRESS: 2,
  POID: 3, // Proof of identity document
  PAYOUTS: 5,
  REVIEW: 6,
};

const libraries = ['places'];

type LinkBankAccountProps = {
  willNavigateToApiScreenIfConnected?: boolean;
};

const LinkBankAccount = ({
  willNavigateToApiScreenIfConnected,
}: LinkBankAccountProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY ?? '',
    // @ts-ignore
    libraries,
  });
  const {
    data: linkedBankInfo,
    isLoading: isLoadingLinkedBankInfo,
    isFetching,
  } = useGetBankInfoV2();

  useEffect(() => {
    if (
      willNavigateToApiScreenIfConnected &&
      linkedBankInfo?.status === commonConstants.STRIPE_STATUS_TYPE.ACTIVE
    ) {
      navigate('/');
    }
  }, [willNavigateToApiScreenIfConnected, linkedBankInfo?.status, navigate]);

  const {
    mutate: mutateCreateStripeAccount,
    isLoading: isLoadingCreateStripeAccount,
  } = useCreateStripeAccountV2();

  let isLoading = !isLoaded || isLoadingLinkedBankInfo || isFetching;
  if (location?.state?.notLinked) isLoading = false;
  const onHandleCreateStripeAccount = useCallback(() => {
    mutateCreateStripeAccount(undefined, {
      onSuccess: (response) => {
        if (response?.onboardingLink) {
          window.open(
            `${
              window.location.origin
            }/transition-stripe-connect?stripe-link=${encodeURIComponent(
              response?.onboardingLink,
            )}`,
          );
          navigate('/');
        }
      },
    });
  }, [mutateCreateStripeAccount, navigate]);

  const content = useMemo(() => {
    switch (linkedBankInfo?.status) {
      case commonConstants.STRIPE_STATUS_TYPE.NOT_CREATED:
        return (
          <NotCreated
            isLoadingCreateStripeAccount={isLoadingCreateStripeAccount}
            onHandleCreateStripeAccount={onHandleCreateStripeAccount}
            willNavigateToApiScreenIfConnected={
              willNavigateToApiScreenIfConnected
            }
          />
        );
      case commonConstants.STRIPE_STATUS_TYPE.PENDING:
        return <Pending />;
      case commonConstants.STRIPE_STATUS_TYPE.ACTION_REQUIRED:
        return (
          <ActionRequired
            updateLink={linkedBankInfo?.url}
            willNavigateToApiScreenIfConnected={
              willNavigateToApiScreenIfConnected
            }
          />
        );
      case commonConstants.STRIPE_STATUS_TYPE.ACTIVE:
        return <ActiveState updateLink={linkedBankInfo?.url} />;
      default:
        return null;
    }
  }, [
    isLoadingCreateStripeAccount,
    linkedBankInfo?.status,
    linkedBankInfo?.url,
    onHandleCreateStripeAccount,
    willNavigateToApiScreenIfConnected,
  ]);

  return (
    <>
      <Helmet>
        <title>Link Bank Account</title>
        <meta name="description" content="iRefer Link Bank Account" />
      </Helmet>
      <Stack
        justifyContent="center"
        alignItems="center"
        sx={{ minHeight: 'calc(100vh - 100px)' }}
      >
        {isLoading ? (
          <LoadingContent text="Just a moment while we get your bank account details..." />
        ) : (
          content
        )}
      </Stack>
    </>
  );
};

const NotCreated = ({
  isLoadingCreateStripeAccount,
  onHandleCreateStripeAccount,
  willNavigateToApiScreenIfConnected,
}: {
  isLoadingCreateStripeAccount: boolean;
  onHandleCreateStripeAccount: () => void;
  willNavigateToApiScreenIfConnected?: boolean;
}) => {
  const navigate = useNavigate();
  return (
    <StyledBox
      sx={{
        padding: '52px',
        display: 'flex',
        position: 'relative',
        alignItems: 'flex-start',
        paddingTop: '100px',
      }}
    >
      <Stack sx={{ width: '100%', height: '100%' }} gap={5}>
        <Stack gap={2}>
          <CommonTitleText>LINK YOUR BANK ACCOUNT</CommonTitleText>
          <CommonSubtext>
            To start creating the stripe account click on the button below.
          </CommonSubtext>
        </Stack>
        <Stack sx={{ width: '100%' }} gap={3}>
          <StyledLoadingButton
            loading={isLoadingCreateStripeAccount}
            onClick={onHandleCreateStripeAccount}
            variant="contained"
          >
            <Text16Weight600 sx={{ color: themes.light.colorWhite }}>
              Create Stripe Account
            </Text16Weight600>
          </StyledLoadingButton>
          {willNavigateToApiScreenIfConnected ? (
            <StyledOutlinedLoadingButton
              onClick={() => navigate('/')}
              variant="contained"
            >
              Go Home
            </StyledOutlinedLoadingButton>
          ) : null}
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="center">
          <img src={StripeLogo} style={{ width: 200 }} alt="Stripe logo" />
        </Stack>
      </Stack>
    </StyledBox>
  );
};

const ActiveState = ({ updateLink }: { updateLink?: string }) => {
  const navigate = useNavigate();
  return (
    <Stack gap={3}>
      <Stack alignItems="center">
        <SuccessIcon />
      </Stack>
      <Stack gap={2}>
        <CommonTitleText>YOUR ACCOUNT HAS BEEN VERIFIED</CommonTitleText>
        <CommonSubtext>Your payments will now process normally.</CommonSubtext>
      </Stack>
      <StyledLoadingButton
        onClick={() =>
          updateLink
            ? window.open(
                `${
                  window.location.origin
                }/transition-stripe-connect?stripe-link=${encodeURIComponent(
                  updateLink,
                )}`,
              )
            : navigate('/')
        }
        variant="contained"
      >
        <Text16Weight600 sx={{ color: themes.light.colorWhite }}>
          {updateLink ? 'View details' : 'Return home'}
        </Text16Weight600>
      </StyledLoadingButton>
    </Stack>
  );
};

const Pending = () => {
  const navigate = useNavigate();
  return (
    <Stack gap={3}>
      <Stack alignItems="center">
        <ExclamationIcon />
      </Stack>
      <Stack gap={2}>
        <CommonTitleText>
          PLEASE WAIT WHILE WE VERIFY YOUR ACCOUNT.
        </CommonTitleText>
        <CommonSubtext>This usually only takes a few moments.</CommonSubtext>
      </Stack>
      <StyledLoadingButton onClick={() => navigate('/')} variant="contained">
        <Text16Weight600 sx={{ color: themes.light.colorWhite }}>
          Return home
        </Text16Weight600>
      </StyledLoadingButton>
    </Stack>
  );
};

const ActionRequired = ({
  updateLink,
  willNavigateToApiScreenIfConnected,
}: {
  updateLink?: string;
  willNavigateToApiScreenIfConnected?: boolean;
}) => {
  const navigate = useNavigate();
  return (
    <Stack gap={3}>
      <Stack alignItems="center">
        <ExclamationIcon />
      </Stack>
      <Stack gap={2}>
        <CommonTitleText>PLEASE UPDATE YOUR STRIPE ACCOUNT.</CommonTitleText>
        <CommonSubtext>Stripe needs additional details from you.</CommonSubtext>
      </Stack>
      <StyledLoadingButton
        onClick={() =>
          updateLink
            ? window.open(
                `${
                  window.location.origin
                }/transition-stripe-connect?stripe-link=${encodeURIComponent(
                  updateLink,
                )}`,
              )
            : navigate('/')
        }
        variant="contained"
      >
        <Text16Weight600 sx={{ color: themes.light.colorWhite }}>
          {updateLink ? 'Update details' : 'Return home'}
        </Text16Weight600>
      </StyledLoadingButton>
      {willNavigateToApiScreenIfConnected ? (
        <StyledOutlinedLoadingButton
          onClick={() => navigate('/')}
          variant="contained"
        >
          Go Home
        </StyledOutlinedLoadingButton>
      ) : null}
    </Stack>
  );
};

export default LinkBankAccount;
