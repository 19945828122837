import { Autocomplete, Box, styled, TextField } from '@mui/material';
import { countries, CountryType } from 'constants/countries.constant';
import { Field, useField } from 'formik';
import { useEffect, useState } from 'react';
import { themes } from 'theme';

const StyledField = styled(TextField)({
  '& .MuiInputLabel-root': {
    '&.Mui-focused': {
      color: themes.light.colorBlack,
    },
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      border: `1px solid ${themes.light.borderColor}`,
      borderRadius: '4px',
    },
    '&.Mui-focused fieldset': {
      borderColor: themes.light.colorMaximumBlueGreen,
    },
  },
});

const CountrySelect = (props: any) => {
  const { name, label, ...rest } = props;
  const myField: any = useField(name);
  const [value, setValue] = useState('');
  useEffect(() => {
    setValue(myField[1]?.value);
  }, [myField]);
  const handleOnChangeCountry = (e: any, value: CountryType) => {
    if (!!value) {
      myField[2].setValue(value.code);
    } else {
      myField[2].setValue('');
    }
  };
  return (
    <Field name={name}>
      {({ field, form, meta }: { field: any; form: any; meta: any }) => {
        return (
          <Autocomplete
            disablePortal
            getOptionLabel={(option: any) =>
              typeof option === 'string' ? option : option.label
            }
            onChange={handleOnChangeCountry}
            fullWidth
            value={value}
            id="country-select-demo"
            options={countries.filter((item) => item.code === 'AU') ?? []}
            isOptionEqualToValue={(option: any, value: any) => {
              option?.value.includes(value);
            }}
            renderOption={(props: any, option: any) => (
              <Box
                key={`${option.code}_${option.label}`}
                component="li"
                sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                {...props}
              >
                <img
                  loading="lazy"
                  width="20"
                  src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                  srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                  alt=""
                />
                {option.label} ({option.code})
              </Box>
            )}
            renderInput={(params) => (
              <StyledField
                {...params}
                label={label}
                error={Boolean(meta.error)}
                helperText={meta.error}
                inputProps={{
                  ...params.inputProps,
                  onBlur: form.handleBlur,
                  autoComplete: 'new-password', // disable autocomplete and autofill
                }}
                fullWidth
              />
            )}
            {...rest}
          />
        );
      }}
    </Field>
  );
};

export default CountrySelect;
