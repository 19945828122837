import * as React from 'react';
import { Box, Skeleton } from '@mui/material';
import {
  ProfileContainer,
  ProfileName,
} from '../Common/Dashboard/DashboardStyled';
import { Text14Weight400Gray } from '../Common/Styled/TypographyStyed';
import AvatarWithFallback from '../Common/AvatarWithFallback/AvatarWithFallback';

type Props = {
  logo: string;
  name: string;
  subText: string;
  isLoading?: boolean;
  avatarVariant?: AvatarVariantType;
};

export function ReferralsColumn({
  logo,
  name,
  subText,
  isLoading,
  avatarVariant,
}: Readonly<Props>): JSX.Element {
  if (isLoading) {
    return (
      <ProfileContainer>
        <Skeleton
          // @ts-ignore
          variant={avatarVariant}
          width={50}
          height={50}
        />
        <Box>
          <ProfileName>
            <Skeleton variant="rounded" width={210} height={20} />
          </ProfileName>
          <Text14Weight400Gray mt={1}>
            <Skeleton variant="rounded" width={210} height={15} />
          </Text14Weight400Gray>
        </Box>
      </ProfileContainer>
    );
  }
  return (
    <ProfileContainer>
      <AvatarWithFallback
        image={logo}
        width={50}
        height={50}
        avatarVariant="rounded"
        fromCDN
      />
      <Box>
        <ProfileName>{name ?? '--'}</ProfileName>
        <Text14Weight400Gray>{subText ?? '--'}</Text14Weight400Gray>
      </Box>
    </ProfileContainer>
  );
}
