import React, { useState } from 'react';
import { Logo, StyledLoadingButton, StyledStack } from '../AuthStyled';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { Stack } from '@mui/material';
import FormikControl, {
  CONTROL_TYPE,
} from '../../../components/Formik/FormikControl';
import { Helmet } from 'react-helmet-async';
import { useForgotPassword } from '../../../hooks/Auth/useAuth';
import { StyledBox } from '../../../components/Common/Styled/CommonStyled';
import {
  CommonSubtext,
  CommonTitleText,
} from '../../../components/Common/Styled/TypographyStyed';
import LogoIReferIcon from '../../../assets/images/icon-logo-irefer-trademark.png';

const validationSchema = Yup.object({
  email: Yup.string()
    .email('Not a valid email address')
    .required('Username / Email Address is required'),
});

type ForgotPasswordValues = {
  email: string;
};

const initialValues: ForgotPasswordValues = {
  email: '',
};

const TYPE = {
  FORGOT_PASSWORD: 'FORGOT_PASSWORD',
  CHECK_MAIL: 'CHECK_MAIL',
};

const CONTENT_DATA = [
  // FORGOT_PASSWORD
  {
    TYPE: TYPE.FORGOT_PASSWORD,
    TITLE: 'FORGOT PASSWORD',
    SUB_TEXT: 'Instructions to reset your password will be sent to your email.',
    BUTTON_TEXT: 'Reset password',
  },
  // CHECK_MAIL
  {
    TYPE: TYPE.CHECK_MAIL,
    TITLE: 'CHECK YOUR MAIL',
    SUB_TEXT: 'Instructions have been sent to your email address.',
    BUTTON_TEXT: 'Back to login',
  },
];

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [contentData, setContentData] = useState(
    CONTENT_DATA[0] /* FORGOT_PASSWORD */,
  );
  const { mutate: mutateForgotPassword, isLoading } = useForgotPassword();
  const onSubmit = (values: ForgotPasswordValues) => {
    mutateForgotPassword(values, {
      onSuccess: () => {
        setContentData(CONTENT_DATA[1] /* CHECK_MAIL */);
      },
    });
  };

  const navigateToLogin = () => {
    navigate('/auth/login');
  };

  const formikProps = { initialValues, validationSchema, onSubmit };

  return (
    <Formik {...formikProps}>
      {({ isValid, handleSubmit, dirty }) => (
        <Form>
          <Helmet>
            <title>Forgot Password</title>
            <meta name="description" content="iRefer Forgot Password" />
          </Helmet>
          <StyledBox>
            <StyledStack>
              <Stack alignItems="center" mb={2}>
                <Logo
                  alt="iReferLogo"
                  src={LogoIReferIcon}
                  style={{ width: '104px' }}
                />
              </Stack>
              <CommonTitleText>{contentData.TITLE}</CommonTitleText>
              <CommonSubtext mb="1rem">{contentData.SUB_TEXT}</CommonSubtext>
              <Stack mb="2rem" gap={3}>
                <FormikControl
                  autoCapitalize="none"
                  control={CONTROL_TYPE.INPUT}
                  name="email"
                  label="Username / Email Address"
                  disabled={contentData.TYPE === TYPE.CHECK_MAIL}
                  InputLabelProps={{ shrink: true }}
                />
              </Stack>
              <StyledLoadingButton
                loading={isLoading}
                variant="contained"
                disabled={!(isValid && dirty)}
                onClick={() => {
                  contentData.TYPE === TYPE.FORGOT_PASSWORD
                    ? handleSubmit()
                    : navigateToLogin();
                }}
                sx={{ marginBottom: '1.2rem' }}
              >
                {contentData.BUTTON_TEXT}
              </StyledLoadingButton>
            </StyledStack>
          </StyledBox>
        </Form>
      )}
    </Formik>
  );
};

export default ForgotPassword;
