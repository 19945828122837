import React from 'react';
import { StyledLoadingButton, StyledStack } from '../AuthStyled';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { Stack, IconButton, InputAdornment } from '@mui/material';
import FormikControl, {
  CONTROL_TYPE,
} from '../../../components/Formik/FormikControl';
import { useImmer } from 'use-immer';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { useUpdatePassword } from '../../../hooks/Auth/useAuth';
import { StyledBox } from '../../../components/Common/Styled/CommonStyled';
import {
  CommonSubtext,
  CommonTitleText,
} from '../../../components/Common/Styled/TypographyStyed';
import BackButton from '../../../components/Common/BackButton/BackButton';

const validationSchema = Yup.object({
  oldPassword: Yup.string().required('Required'),
  newPassword: Yup.string()
    .min(8, 'Minimum 8 Characters')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
      'Must Contain One Uppercase, One Lowercase, One Number',
    )
    .test(
      'password-differ',
      'New Password should be different from Old Password',
      function (value: any) {
        return this.parent.oldPassword !== value;
      },
    )
    .required('Required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
    .required('Required'),
});

type UpdatePasswordValues = {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
};

const initialValues: UpdatePasswordValues = {
  oldPassword: '',
  newPassword: '',
  confirmPassword: '',
};

const UpdatePassword = () => {
  const navigate = useNavigate();

  const { mutate: mutateUpdatePassword, isLoading } = useUpdatePassword();
  const onSubmit = (values: UpdatePasswordValues) => {
    const payload = {
      oldPassword: values.oldPassword,
      newPassword: values.newPassword,
    };

    mutateUpdatePassword(payload, {
      onSuccess: () => navigate('/account'),
    });
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
  };
  const [showPassword, setShowPassword] = useImmer({
    oldPassword: false,
    newPassword: false,
    confirmPassword: false,
  });

  const handleClickShowPassword = (name: string) => {
    setShowPassword((draft: any) => {
      draft[name] = !draft[name];
    });
  };

  const formikProps = { initialValues, validationSchema, onSubmit };

  return (
    <Formik {...formikProps}>
      {({ isValid, handleSubmit, dirty }) => (
        <Form>
          <Helmet>
            <title>Update Password</title>
            <meta name="description" content="iRefer Update Password" />
          </Helmet>
          <StyledBox
            sx={{
              position: 'relative',
              display: 'flex',
              width: '100%',
              alignItems: 'center',
            }}
          >
            <BackButton onClick={() => navigate('/account/settings')} />
            <StyledStack>
              <CommonTitleText>UPDATE YOUR PASSWORD</CommonTitleText>
              <CommonSubtext mb="1rem">
                Create a new secure password for your iRefer account.
              </CommonSubtext>
              <Stack mb="2rem" gap={3}>
                <FormikControl
                  autoCapitalize="none"
                  control={CONTROL_TYPE.INPUT}
                  name="oldPassword"
                  label="Old Password"
                  type={showPassword.oldPassword ? 'text' : 'password'}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => handleClickShowPassword('oldPassword')}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword.oldPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <FormikControl
                  autoCapitalize="none"
                  control={CONTROL_TYPE.INPUT}
                  name="newPassword"
                  label="New Password"
                  type={showPassword.newPassword ? 'text' : 'password'}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => handleClickShowPassword('newPassword')}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword.newPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <FormikControl
                  autoCapitalize="none"
                  control={CONTROL_TYPE.INPUT}
                  name="confirmPassword"
                  label="Confirm New Password"
                  type={showPassword.confirmPassword ? 'text' : 'password'}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() =>
                            handleClickShowPassword('confirmPassword')
                          }
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword.confirmPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Stack>
              <StyledLoadingButton
                loading={isLoading}
                variant="contained"
                disabled={!(isValid && dirty)}
                onClick={() => {
                  handleSubmit();
                }}
                sx={{ marginBottom: '1.2rem' }}
              >
                Update
              </StyledLoadingButton>
            </StyledStack>
          </StyledBox>
        </Form>
      )}
    </Formik>
  );
};

export default UpdatePassword;
