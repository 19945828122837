import { Checkbox, Stack, styled, Typography } from '@mui/material';
import { Field } from 'formik';
import { themes } from 'theme';

const CheckBox = (props: any) => {
  const {
    name,
    title,
    subText,
    maxLength,
    displayCheck,
    titleFontWeight,
    ...rest
  } = props;
  return (
    <Field type="checkbox" name={name}>
      {({ field, form, meta }: { field: any; form: any; meta: any }) => {
        return (
          <Stack
            flexDirection={'row'}
            alignItems={'flex-start'}
            justifyContent={'flex-start'}
          >
            <Checkbox
              sx={{ padding: '0 9px 0 0' }}
              {...field}
              {...rest}
              name={name}
              checked={field.value || false}
            />
            <Stack>
              {title && (
                <Title sx={{ fontWeight: titleFontWeight }}>{title}</Title>
              )}
              {subText && <SubText>{subText}</SubText>}
            </Stack>
          </Stack>
        );
      }}
    </Field>
  );
};

const Title = styled(Typography)({
  fontWeight: 500,
  fontSize: '1rem',
  lineHeight: '20px',
  color: themes.light.colorBlack,
  mixBlendMode: 'normal',
});
const SubText = styled(Typography)({
  marginTop: '4px',
  fontWeight: 400,
  fontSize: '12px',
  lineHeight: '15px',
  color: themes.light.colorGray,
  mixBlendMode: 'normal',
});

export default CheckBox;
