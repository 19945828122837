import { Stack } from '@mui/material';
import {
  CommonSubtext,
  CommonTitleText,
} from 'components/Common/Styled/TypographyStyed';
import FormikControl, { CONTROL_TYPE } from 'components/Formik/FormikControl';
import { Form, FormikProvider, useFormik } from 'formik';
import { useMemo, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getUser } from 'redux/selectors/auth.selector';
import * as Yup from 'yup';
import { StyledLoadingButton } from '../AuthStyled';
import CountDownButton from 'components/Common/CountDownButton/CountDownButton';
import { useSendSMSCode, useUpdateVendorURL } from 'hooks/Info/useInfo';
import { useAtomValue } from 'jotai';
import { updateStoreURLAtom } from './UpdateWebsiteURL';
import { useNavigate } from 'react-router-dom';
import { atomWithStorage } from 'jotai/utils';
import moment from 'moment';

export const expiredTimeSMSCodeAtom = atomWithStorage(
  'expiredTimeSMSCode',
  localStorage.getItem('expiredTimeSMSCode') ?? new Date().getTime(),
);

const validationSchema = Yup.object({
  code: Yup.string()
    .required('Required')
    .matches(/^[0-9]{7}$/, 'Must be exactly 7 digits'),
});

type Type = Yup.InferType<typeof validationSchema>;

const UpdateWebsiteUrlStep2 = () => {
  const expiredTimeSMSCode = useAtomValue(expiredTimeSMSCodeAtom);
  const initialRenderRef = useRef(false);
  const newDomain = useAtomValue(updateStoreURLAtom);
  const { mutateAsync: mutateSendSMSCode, isLoading: isLoadingSendSMSCode } =
    useSendSMSCode();
  const { mutate: mutateUpdateVendorURL, isLoading: isLoadingUpdateVendorURL } =
    useUpdateVendorURL();

  const isLoading = isLoadingSendSMSCode || isLoadingUpdateVendorURL;
  const user = useSelector(getUser);
  const hiddenPhoneNumbers = useMemo(() => {
    return user?.phone
      ? user?.phone.slice(-4).padStart(user?.phone.length, '•')
      : '';
  }, [user?.phone]);
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      try {
        const expiredTime = moment(new Date(expiredTimeSMSCode));
        const currentTime = moment(new Date());
        if (!initialRenderRef.current && currentTime.isAfter(expiredTime)) {
          await mutateSendSMSCode();
        }
        initialRenderRef.current = true;
      } catch (err) {}
    })();
  }, [expiredTimeSMSCode, mutateSendSMSCode]);

  const onSubmit = (data: Type) => {
    mutateUpdateVendorURL(
      {
        code: data.code,
        newDomain,
      },
      {
        onSuccess: () => navigate('/account/settings'),
      },
    );
  };

  const formik = useFormik<Type>({
    initialValues: { code: '' },
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  });

  return (
    <FormikProvider value={formik}>
      <Form>
        <Stack gap={2}>
          <CommonTitleText>VERIFY YOUR NUMBER</CommonTitleText>
          <CommonSubtext mb="1rem">
            Please enter the 7-digit SMS verification code sent via SMS to{' '}
            {hiddenPhoneNumbers}.
          </CommonSubtext>
          <FormikControl
            control={CONTROL_TYPE.INPUT}
            name="code"
            label="7 Digit code from SMS"
            placeholder="7 Digit code from SMS"
            maxLength={7}
          />
          <StyledLoadingButton
            type="submit"
            variant="contained"
            loading={isLoading}
          >
            Continue
          </StyledLoadingButton>
          <CountDownButton
            countingText="Resend Code in"
            resetText="Resend Code"
            callBack={mutateSendSMSCode}
            isLoading={isLoading}
          />
        </Stack>
      </Form>
    </FormikProvider>
  );
};

export default UpdateWebsiteUrlStep2;
