import { Box, IconButton, Popper, styled, Typography } from '@mui/material';
import { themes } from 'theme';

import { LinkTitle } from '../../AccountStyled';

export const LinkBankPopper = styled(Popper)({
  zIndex: 10000,
  background: themes.light.colorWhite,
  boxShadow:
    'rgb(0, 0, 0, 0.2) 0px 5px 5px -3px, rgb(0, 0, 0, 0.14) 0px 8px 10px 1px, rgb(0, 0, 0, 0.12) 0px 3px 14px 2px',
});

export const PopoverContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  width: 375px;
  padding: 15px;
  text-align: center;
`;

export const ClosePopover = styled(IconButton)({
  position: 'absolute',
  top: 0,
  right: 0,
});

export const VerifyingContentContainer = styled(Box)(({ theme }) => ({
  backgroundColor: themes.light.backgroundColorWhite,
  padding: '40px',
  minHeight: '600px',
  width: '600px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  [theme.breakpoints.down('md')]: {
    maxWidth: '90vw',
  },
}));

export const LoadingText = styled(Typography)({
  textAlign: 'center',
});

export const LoadingImg = styled('img')({
  width: '152px',
  height: '160px',
  textAlign: 'center',
  objectFit: 'cover',
});

export const FieldTitle = styled(LinkTitle)({
  marginBottom: '12px',
});
