import { CircularProgress, Grid, InputBase, Stack } from '@mui/material';
import FilterDateInput from 'components/Common/FilterDateInput/FilterDateInput';
import commonConstants from 'constants/common.constant';
import useModal, { MODAL_TYPES } from 'hooks/Common/useModal';
import { useGetReferralsDashboard } from 'hooks/Referrals/useReferrals';
import moment from 'moment';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { themes } from 'theme';
import formatUtil from 'utils/format.util';

import DashBoard, {
  useGetConstructReferralsDashboard,
} from '../Common/Dashboard/Dashboard';
import { SearchBox } from '../Common/Dashboard/DashboardStyled';
import {
  CircleButton,
  HeaderText,
  PageContainer,
} from '../Common/Styled/CommonStyled';
import { SearchIcon } from '../Icons';

type ReferralsDashboardProps = {
  isDashboard?: boolean;
};

export function ReferralsDashboard({
  isDashboard = false,
}: ReferralsDashboardProps): JSX.Element {
  const navigate = useNavigate();
  const {
    query: { data, isFetching, isLoading },
    totalPage,
    handleChangePage,
    onHandleChangeKeyword,
    onHandleChangeFilterTime,
    filter,
    page,
    keyword,
    handleChangeDateRange,
    dateFrom,
    dateTo,
  } = useGetReferralsDashboard(isDashboard);
  const { columns, noDataText, renderSkeletons, renderValue } =
    useGetConstructReferralsDashboard();

  const { setOpenModal, renderModal } = useModal({});

  let customColumns: ColumnDashboard[] = columns;

  if (isDashboard && columns) {
    customColumns = columns.map((item) =>
      item.id === 'action'
        ? {
            ...item,
            label: 'View All',
            headerStyle: {
              ...item.headerStyle,
              color: themes.light.colorMaximumBlueGreen,
              fontWeight: 700,
              cursor: 'pointer',
            },
            onClick: () => navigate('/referrals'),
          }
        : item,
    );
  }

  const renderListFilterButton = useMemo(
    () =>
      [
        commonConstants.FILTER_TIME.ALL_TIME,
        commonConstants.FILTER_TIME.LAST_3_MONTHS,
        commonConstants.FILTER_TIME.LAST_30_DAYS,
        commonConstants.FILTER_TIME.LAST_7_DAYS,
      ].map(({ label, value }, index) => (
        <CircleButton
          key={`${index}_${value}`}
          variant={filter === value ? 'contained' : 'outlined'}
          onClick={() => onHandleChangeFilterTime(value)}
        >
          {label}
        </CircleButton>
      )),
    [filter, onHandleChangeFilterTime],
  );

  return (
    <PageContainer sx={{ padding: isDashboard ? '0px' : '58px 56px' }}>
      {renderModal}
      {!isDashboard && (
        <>
          <HeaderText>Referrals</HeaderText>
          <Grid
            sx={{
              marginTop: isDashboard ? '50px' : '20px',
              alignItems: 'center',
              gap: '10px',
            }}
            container
            spacing={2}
          >
            <Grid item lg={3} sm={12} xs={12}>
              <SearchBox>
                <SearchIcon />
                <InputBase
                  placeholder="Search Referrals"
                  onChange={onHandleChangeKeyword}
                  defaultValue={keyword}
                />
              </SearchBox>
            </Grid>
            <Grid item lg={2} sm={12} xs={12}>
              <FilterDateInput
                onHandleClick={() =>
                  setOpenModal({
                    modalType: MODAL_TYPES.DAY_RANGE_PICKER_MODAL,
                    data: {
                      dateFrom,
                      dateTo,
                      handleChangeDateRange,
                    },
                  })
                }
                value={formatUtil.formatDateRangeString([
                  dateFrom ? moment(dateFrom, 'DD-MM-YYYY')?.toDate() : null,
                  dateTo ? moment(dateTo, 'DD-MM-YYYY')?.toDate() : null,
                ])}
              />
            </Grid>
            <Grid item lg={6} sm={12} xs={12}>
              <Stack direction="row" gap={2} alignItems="center">
                {renderListFilterButton}
                {isFetching && <CircularProgress />}
              </Stack>
            </Grid>
          </Grid>
        </>
      )}
      <DashBoard
        data={data?.data ?? []}
        page={page}
        columns={customColumns}
        totalPage={totalPage}
        renderValue={renderValue}
        renderSkeletons={renderSkeletons}
        handleChangePage={handleChangePage}
        isLoading={isLoading}
        noDataText={noDataText}
        showPagination={!isDashboard}
      />
    </PageContainer>
  );
}
