import { Stack } from '@mui/material';
import { StyledLoadingButton } from 'components/Common/Styled/CommonStyled';
import {
  CommonSubtext,
  CommonTitleText,
} from 'components/Common/Styled/TypographyStyed';
import FormikControl, { CONTROL_TYPE } from 'components/Formik/FormikControl';
import { Form, FormikProvider, useFormik } from 'formik';
import { useAtom } from 'jotai';
import * as Yup from 'yup';
import { updateStoreURLAtom } from './UpdateWebsiteURL';
import { useSelector } from 'react-redux';
import { getUser } from 'redux/selectors/auth.selector';

const validationSchema = Yup.object({
  domain: Yup.string()
    .transform((val) => val.trim())
    .matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      'Link should be a valid url.',
    )
    .required('Required'),
});

type Type = Yup.InferType<typeof validationSchema>;

type UpdateWebsiteUrlStep1Props = {
  nextStep: () => void;
};

const UpdateWebsiteUrlStep1 = ({ nextStep }: UpdateWebsiteUrlStep1Props) => {
  const [url, setURL] = useAtom(updateStoreURLAtom);
  const user = useSelector(getUser);
  const onSubmit = (data: Type) => {
    setURL(data.domain);
    nextStep();
  };
  const formik = useFormik<Type>({
    initialValues: { domain: url || user?.vendor?.domain || '' },
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  });
  return (
    <FormikProvider value={formik}>
      <Form>
        <Stack gap={2}>
          <CommonTitleText>STORE URL</CommonTitleText>
          <CommonSubtext mb="1rem">
            This is the URL of the eCommerce store that is linked with your
            iRefer account.
          </CommonSubtext>
          <FormikControl
            control={CONTROL_TYPE.INPUT}
            name="domain"
            label="Website URL"
            maxLength={200}
            displayCheck
          />
          <StyledLoadingButton type="submit" variant="contained">
            Update
          </StyledLoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
};

export default UpdateWebsiteUrlStep1;
