import {
  InputBase,
  Stack,
  styled,
  Box,
  CircularProgress,
  IconButton,
} from '@mui/material';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

export const SearchBox = styled(Box)`
  padding: 0 16px;
  /* width: 343px; */
  height: 49px;
  display: grid;
  grid-template-columns: auto 1fr auto;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  border: 1px solid #eceff1;
  box-shadow: 0px 0px 5px rgba(112, 129, 163, 0.102109);
  border-radius: 4px;

  & > .MuiInputBase-root {
    margin-left: 16px;
  }

  & > .MuiInputBase-root > .MuiInputBase-input {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #535f78;
  }
`;

type SearchFieldProps = {
  onHandleClick: () => void;
  value: string;
  isLoading?: boolean;
  placeHolder?: string;
};

const FilterDateInput = ({
  value,
  isLoading,
  onHandleClick,
  placeHolder = 'Select date',
}: SearchFieldProps) => {
  return (
    <Stack direction="row" gap={3} alignItems="center">
      <SearchBox width='100%'>
        <IconButton onClick={onHandleClick}>
          <CalendarTodayIcon />
        </IconButton>
        <InputBase value={value} placeholder={placeHolder} />
      </SearchBox>
      {isLoading ? <CircularProgress /> : null}
    </Stack>
  );
};

export default FilterDateInput;
