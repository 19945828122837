/* eslint-disable react-hooks/exhaustive-deps */
import { useQuery, useQueryClient } from '@tanstack/react-query';
import useAxios from 'hooks/Common/useAxios';
import { TotalPerformanceItemType } from 'hooks/TotalPerformance/useTotalPerformance';
import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import commonConstants from '../../constants/common.constant';
import { getUser } from '../../redux/selectors/auth.selector';
import { getReferralsDashboardFilterProperties } from '../../redux/selectors/referrals.selector';
import {
  PROPERTY_KEY,
  setProperties,
  setProperty,
} from '../../redux/slices/referralsSlice';
import formatUtil from '../../utils/format.util';
import getQueryKey from '../../utils/get-query-key';
import { useDebounce } from '../Common/useDebounce';

type ReferralsDashboardType = {
  data: ReferralsListItem[];
  limit: string;
  page: string;
  total: number;
};

export type ReferralsListItem = {
  link_purchases: number;
  referee_profit: number;
  referral: {
    code: string;
    id: string;
    product_image: string;
    product_title: string;
    referral_duration: string;
    referral_url: string;
  };
  referral_sales: number;
  vendor_net_profit: number;
};

export const useGetReferralsDashboard = (isDashboard: boolean) => {
  const axios = useAxios();
  const dispatch = useDispatch();
  const { page, limit, keyword, direction, filter, dateFrom, dateTo } =
    useSelector(getReferralsDashboardFilterProperties);
  const queryClient = useQueryClient();
  const user = useSelector(getUser);
  const returnQueryParams = useCallback(
    (isPrefetch: boolean = false) => {
      return {
        page: isPrefetch ? page + 1 : page,
        limit: isDashboard ? 5 : limit,
        keyword,
        direction,
        filter,
        userId: user?.id ?? '',
        ...(dateFrom ? { dateFrom } : {}),
        ...(dateTo ? { dateTo } : {}),
      };
    },
    [
      page,
      limit,
      keyword,
      direction,
      filter,
      user?.id,
      isDashboard,
      dateFrom,
      dateTo,
    ],
  );

  const handleChangeDateRange = ({
    dateFrom,
    dateTo,
  }: {
    dateFrom: string;
    dateTo: string;
  }) => {
    dispatch(
      setProperties([
        { type: PROPERTY_KEY.DATE_FROM, value: dateFrom },
        { type: PROPERTY_KEY.DATE_TO, value: dateTo },
        {
          type: PROPERTY_KEY.PAGE,
          value:
            commonConstants.TRANSACTIONS_DASHBOARD_DEFAULT_QUERY_PARAMS.page,
        },
      ]),
    );
  };

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    newPage: number,
  ) => {
    dispatch(setProperty({ type: PROPERTY_KEY.PAGE, value: newPage }));
  };
  const handleDebounceFn = useCallback(
    (inputValue: string) => {
      dispatch(
        setProperties([
          { type: PROPERTY_KEY.KEYWORD, value: inputValue },
          {
            type: PROPERTY_KEY.PAGE,
            value:
              commonConstants.REFERRALS_DASHBOARD_DEFAULT_QUERY_PARAMS.page,
          },
        ]),
      );
    },
    [dispatch],
  );
  const onHandleChangeKeyword = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleChangeInput(e.target.value);
  };
  const handleChangeInput = useDebounce(handleDebounceFn);
  const onHandleChangeDirection = (direction: string) => {
    dispatch(
      setProperties([
        {
          type: PROPERTY_KEY.DIRECTION,
          value: direction,
        },
        {
          type: PROPERTY_KEY.PAGE,
          value: commonConstants.REFERRALS_DASHBOARD_DEFAULT_QUERY_PARAMS.page,
        },
      ]),
    );
  };
  const onHandleChangeFilterTime = (filter: string) => {
    dispatch(
      setProperties([
        {
          type: PROPERTY_KEY.FILTER,
          value: filter,
        },
        {
          type: PROPERTY_KEY.PAGE,
          value: commonConstants.REFERRALS_DASHBOARD_DEFAULT_QUERY_PARAMS.page,
        },
      ]),
    );
  };

  const query = useQuery<ReferralsDashboardType>(
    getQueryKey.referralsDashboardQueryKey(returnQueryParams()),
    async () => {
      const response = await axios.get(
        '/vendors/referral-paginated-analytics',
        {
          params: returnQueryParams(),
        },
      );
      return response.data;
    },
    {
      keepPreviousData: true,
      staleTime: commonConstants.STALE_TIME.MIN_1, // 1m
    },
  );
  const totalPage = useMemo(() => {
    return formatUtil.parseTotalPage({
      data: query?.data,
      page: commonConstants.REFERRALS_DASHBOARD_DEFAULT_QUERY_PARAMS.page,
      limit: isDashboard
        ? 5
        : commonConstants.REFERRERS_DASHBOARD_DEFAULT_QUERY_PARAMS.limit,
    });
  }, [query?.data, isDashboard]);

  useEffect(() => {
    if (page <= totalPage - 1 && !isDashboard) {
      queryClient.prefetchQuery(
        getQueryKey.referralsDashboardQueryKey(returnQueryParams(true)),
        () =>
          axios.get('/vendors/referral-paginated-analytics', {
            params: returnQueryParams(true /* isPrefetch */),
          }),
      );
    }
  }, [
    page,
    keyword,
    direction,
    queryClient,
    returnQueryParams,
    totalPage,
    filter,
    isDashboard,
  ]);

  return {
    query,
    totalPage,
    page,
    handleChangePage,
    onHandleChangeKeyword,
    onHandleChangeDirection,
    onHandleChangeFilterTime,
    handleChangeDateRange,
    filter,
    keyword,
    dateFrom,
    dateTo,
  };
};

type ProductDetailsType = {
  created_at: string;
  product_description: string | undefined;
  product_image: string;
  product_referal_url: string;
  product_title: string;
  store_url: string;
};

type RefereeDataType = {
  first_name: string;
  last_name: string;
  logo: string;
  id: string;
};

type ReferralDetailsResponse = {
  productDetails: ProductDetailsType | null;
  refereeData: RefereeDataType | null;
  referralAnalytics: {
    referralPerformance: TotalPerformanceItemType[];
    salesPerformance: TotalPerformanceItemType[];
  };
};

export const useGetReferralDetails = ({
  referralId,
}: {
  referralId: string;
}) => {
  const axios = useAxios();
  return useQuery<ReferralDetailsResponse>(
    getQueryKey.getReferralDetails(referralId),
    async () => {
      const response = await axios.get('/vendors/referral-sales-analytics', {
        params: { referralId },
      });
      if (response.status === 200 && response.data) {
        return {
          productDetails: response.data?.productDetails as ProductDetailsType,
          refereeData: response.data?.refereeData as RefereeDataType,
          referralAnalytics: formatUtil.totalPerformanceFormat({
            sales_performance: response.data?.sales_performance,
            referral_performance: response.data?.referral_performance,
          }),
        } as ReferralDetailsResponse;
      }
      return {
        productDetails: null,
        refereeData: null,
        referralAnalytics: {
          referralPerformance: [],
          salesPerformance: [],
        },
      };
    },
    {
      enabled: !!referralId,
    },
  );
};

export const usePrefetchReferralDetail = () => {
  const axios = useAxios();
  const queryClient = useQueryClient();
  return (referralId: string) => {
    return queryClient.prefetchQuery(
      getQueryKey.getReferralDetails(referralId),
      async () => {
        const response = await axios.get('/vendors/referral-sales-analytics', {
          params: { referralId },
        });
        if (response.status === 200 && response.data) {
          return {
            productDetails: response.data?.productDetails as ProductDetailsType,
            refereeData: response.data?.refereeData as RefereeDataType,
            referralAnalytics: formatUtil.totalPerformanceFormat({
              sales_performance: response.data?.sales_performance,
              referral_performance: response.data?.referral_performance,
            }),
          } as ReferralDetailsResponse;
        }
        return {
          productDetails: null,
          refereeData: null,
          referralAnalytics: {
            referralPerformance: [],
            salesPerformance: [],
          },
        };
      },
      {
        staleTime:
          commonConstants.STALE_TIME
            .SEC_20 /* only pre-fetch if older than 20 seconds */,
      },
    );
  };
};
