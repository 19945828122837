import React from 'react';

import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { StyledBox } from '../../../components/Common/Styled/CommonStyled';
import { Box } from '@mui/material';
import BackButton from '../../../components/Common/BackButton/BackButton';
import useMultiStepBasicForm from 'hooks/Common/useMultiStepBasicForm';
import UpdateWebsiteUrlStep1 from './UpdateWebsiteURLStep1';
import UpdateWebsiteUrlStep2 from './UpdateWebsiteURLStep2';
import { atom } from 'jotai';

export const updateStoreURLAtom = atom('');

const UpdateWebsiteUrl = () => {
  const navigate = useNavigate();

  const nextStep = () => {
    next();
  };

  const { step, next, back, isLastStep } = useMultiStepBasicForm([
    { component: <UpdateWebsiteUrlStep1 nextStep={nextStep} /> },
    { component: <UpdateWebsiteUrlStep2 /> },
  ]);

  return (
    <>
      <Helmet>
        <title>Update Website URL</title>
        <meta name="description" content="iRefer Update Website URL" />
      </Helmet>
      <Box>
        <StyledBox
          sx={{
            position: 'relative',
            display: 'flex',
            width: '100%',
            alignItems: 'center',
          }}
        >
          <BackButton
            onClick={() =>
              isLastStep ? back() : navigate('/account/settings')
            }
          />
          {step}
        </StyledBox>
      </Box>
    </>
  );
};

export default UpdateWebsiteUrl;
