/* eslint-disable quotes */
import { CircularProgress, Divider, Grid } from '@mui/material';
import AvatarWithFallback from 'components/Common/AvatarWithFallback/AvatarWithFallback';
import DashBoard, {
  useGetConstructReferralsDashboard,
} from 'components/Common/Dashboard/Dashboard';
import SearchField from 'components/Common/SearchField/SearchField';
import { TotalPerformance } from 'components/Common/TotalPerformance';
import {
  useGetReferralsForReferrer,
  useGetReferrerDetailsSaleAnalytics,
} from 'hooks/Referrers/useReferrers';
import { useParams } from 'react-router-dom';
import { themes } from 'theme';

import {
  BackDropContainer,
  Container,
  ProfileContainer,
  ProfileDetails,
  ProfileImageGrid,
  ProfileName,
  ProfileStats,
  StatCaption,
  StatDetail,
  StatItem,
} from './ReferrerProfileStyled';
export function ReferrerProfile(): JSX.Element {
  let { referrerId } = useParams();

  const { data: dataAnalytics, isLoading: isLoadingAnalytics } =
    useGetReferrerDetailsSaleAnalytics({
      referrerId: referrerId ?? '',
    });
  const {
    query: { data, isLoading, isFetching },
    handleChangePage,
    page,
    totalPage,
    onHandleChangeKeyword,
  } = useGetReferralsForReferrer({
    referrerId: referrerId ?? '',
  });

  const { columns, noDataText, renderSkeletons, renderValue } =
    useGetConstructReferralsDashboard();
  if (isLoading) {
    return (
      <BackDropContainer>
        <CircularProgress />
      </BackDropContainer>
    );
  }
  return (
    <Container>
      <ProfileContainer>
        <Grid sx={{ alignItems: 'center' }} container spacing={3}>
          <ProfileImageGrid item lg={3} md={3} sm={12} xs={12}>
            <AvatarWithFallback
              image={dataAnalytics?.image}
              height={182}
              width={182}
              avatarVariant="circular"
              fromCDN
              hasBorder
            />
            {/* <ProfileImage /> */}
          </ProfileImageGrid>
          <Grid item lg={8} md={8} sm={12} xs={12}>
            <ProfileDetails>
              <ProfileName>{dataAnalytics?.name}</ProfileName>
              <ProfileStats>
                {/* <StatItem>
                  <StatCaption>iRefer Credit Point</StatCaption>
                  <StatDetail>{dataAnalytics?.creditPoint}</StatDetail>
                </StatItem>
                <Divider orientation="vertical" variant="middle" flexItem /> */}

                {dataAnalytics?.premiumMember ? (
                  <>
                    <StatItem>
                      <StatCaption>Premium Member</StatCaption>
                      <StatDetail
                        color={themes.light.colorJadeGreen}
                      ></StatDetail>
                    </StatItem>
                    <Divider orientation="vertical" variant="middle" flexItem />
                  </>
                ) : null}

                <StatItem>
                  <StatCaption>Active Referrals</StatCaption>
                  <StatDetail>{dataAnalytics?.activeReferrals}</StatDetail>
                </StatItem>
              </ProfileStats>
              {/* <ActionButton onClick={() => {}}>Message</ActionButton> */}
            </ProfileDetails>
          </Grid>
        </Grid>
      </ProfileContainer>
      <TotalPerformance
        headerText="Sales Performance"
        data={dataAnalytics?.salesPerformance ?? []}
        isLoading={isLoadingAnalytics}
      />
      <TotalPerformance
        headerText="Referral Performance"
        data={dataAnalytics?.referralPerformance ?? []}
        isLoading={isLoadingAnalytics}
      />
      <SearchField
        placeholder="Search Referrals"
        onHandleChangeKeyword={onHandleChangeKeyword}
        isLoading={isFetching}
        sx={{ margin: '3rem 0 2rem 0' }}
      />
      <DashBoard
        data={data}
        page={page}
        columns={columns}
        totalPage={totalPage}
        renderValue={renderValue}
        renderSkeletons={renderSkeletons}
        handleChangePage={handleChangePage}
        isLoading={isLoading}
        noDataText={noDataText}
      />
    </Container>
  );
}
