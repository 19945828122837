import LoadingButton from '@mui/lab/LoadingButton';
import {
  Button,
  LinearProgress,
  Stack,
  styled,
  Typography,
} from '@mui/material';
import { themes } from 'theme';

export const StyledStack = styled(Stack)({
  gap: 10,
});

export const TextButton = styled(Button)({
  display: 'flex',
  textTransform: 'unset',
  fontSize: '1rem',
});

export const Logo = styled('img')({
  width: '20px',
  objectFit: 'cover',
});

export const StyledLoadingButton = styled(LoadingButton)({
  height: '52px',
  textTransform: 'unset',
  backgroundColor: themes.light.backgroundColorBlack,
  '&:hover': {
    backgroundColor: themes.light.backgroundColorBlack,
  },
  '&:disabled': {
    backgroundColor: themes.light.backgroundColorDisabled,
    color: themes.light.colorWhite,
  },
});

export const StepText = styled(Typography)({
  textAlign: 'center',
  fontSize: '.875rem',
  fontWeight: 500,
});

export const ProgressBar = styled(LinearProgress)({
  backgroundColor: themes.light.colorSilver,
  '& .MuiLinearProgress-bar': {
    backgroundColor: themes.light.colorBlack,
  },
});
