import * as React from 'react';
import { Box, Skeleton } from '@mui/material';
import {
  ProfileContainer,
  ProfileName,
} from 'components/Common/Dashboard/DashboardStyled';

type Props = {
  name: string;
  isLoading?: boolean;
  avatarVariant?: AvatarVariantType;
};

export function CustomerColumn({
  name,
  isLoading,
  avatarVariant,
}: Readonly<Props>): JSX.Element {
  if (isLoading) {
    return (
      <ProfileContainer>
        <Box>
          <ProfileName>
            <Skeleton variant="rounded" width={210} height={20} />
          </ProfileName>
        </Box>
      </ProfileContainer>
    );
  }
  return (
    <ProfileContainer>
      <Box>
        <ProfileName>{name ?? '--'}</ProfileName>
      </Box>
    </ProfileContainer>
  );
}
