import { Box, CircularProgress, InputBase, Stack } from '@mui/material';

import { useGetTransactions } from 'hooks/Transactions/useTransactions';
import DashBoard, {
  useGetConstructTransactionsDashboard,
} from 'components/Common/Dashboard/Dashboard';
import { SearchBox } from 'components/Common/Dashboard/DashboardStyled';
import { SearchIcon } from 'components/Icons';
import {
  ContainerExcludeTopNav,
  HeaderText,
} from 'components/Common/Styled/CommonStyled';
import FilterDateInput from 'components/Common/FilterDateInput/FilterDateInput';
import useModal, { MODAL_TYPES } from 'hooks/Common/useModal';
import formatUtil from 'utils/format.util';
import moment from 'moment';

export function TransactionsDashboard(): JSX.Element {
  const {
    query: { data, isFetching, isLoading },
    totalPage,
    handleChangePage,
    onHandleChangeKeyword,
    page,
    keyword,
    dateFrom,
    dateTo,
    handleChangeDateRange,
  } = useGetTransactions();

  const { setOpenModal, renderModal } = useModal({});

  const { columns, noDataText, renderSkeletons, renderValue } =
    useGetConstructTransactionsDashboard();
  return (
    <ContainerExcludeTopNav>
      {renderModal}
      <Box>
        <HeaderText variant="h1">Transactions</HeaderText>
      </Box>
      <Stack direction="row" alignItems="center" gap={2} mt={4} flexWrap="wrap">
        <SearchBox sx={{ width: 'min(100%, 343px)' }}>
          <SearchIcon />
          <InputBase
            placeholder="Search customers, referrers, amounts"
            onChange={onHandleChangeKeyword}
            defaultValue={keyword}
          />
        </SearchBox>
        <FilterDateInput
          onHandleClick={() =>
            setOpenModal({
              modalType: MODAL_TYPES.DAY_RANGE_PICKER_MODAL,
              data: {
                dateFrom,
                dateTo,
                handleChangeDateRange,
              },
            })
          }
          value={formatUtil.formatDateRangeString([
            dateFrom ? moment(dateFrom, 'DD-MM-YYYY')?.toDate() : null,
            dateTo ? moment(dateTo, 'DD-MM-YYYY')?.toDate() : null,
          ])}
        />
        {isFetching && <CircularProgress />}
      </Stack>

      <DashBoard
        data={data?.data ?? []}
        page={page}
        columns={columns}
        totalPage={totalPage}
        renderValue={renderValue}
        renderSkeletons={renderSkeletons}
        handleChangePage={handleChangePage}
        isLoading={isLoading}
        noDataText={noDataText}
      />
    </ContainerExcludeTopNav>
  );
}
