import { styled, TextField } from '@mui/material';
import { Field } from 'formik';
import React from 'react';
import { themes } from 'theme';

const StyledField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      border: `1px solid ${themes.light.borderColor}`,
      borderRadius: '4px',
    },
  },
});

const Textarea = (props: any) => {
  const { label, name, maxLength, ...rest } = props;
  return (
    <Field name={name}>
      {({ field, form, meta }: { field: any; form: any; meta: any }) => {
        return (
          <StyledField
            multiline
            fullWidth
            {...field}
            {...rest}
            label={label}
            inputProps={{
              onBlur: form.handleBlur,
              maxLength,
            }}
            error={Boolean(meta.error)}
            helperText={meta.touched && meta.error}
          />
        );
      }}
    </Field>
  );
};

export default Textarea;
