import { useEffect, useRef } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Auth } from 'aws-amplify';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import ToastMessage, {
  ToastType,
} from '../../components/Common/ToastMessage/ToastMessage';
import { AddressType } from '../../components/Formik/SearchAddress';
import commonConstants from '../../constants/common.constant';
import errorConstants from '../../constants/error-constant';
import { getUser } from '../../redux/selectors/auth.selector';
import { login, logout } from '../../redux/slices/authSlice';
import { AppDispatch, persistor } from '../../redux/store';
import { getLowerCaseString } from '../../utils/commonFunctions';
import { generatePassword } from '../../utils/password.util';
import { handlePromise } from '../../utils/promise.util';
import {
  businessCategoriesAPI,
  getUserInfoAPI,
  send2FACodeAPI,
  sendSMSCodeAPI,
  updateVendorInfoAPI,
  validateBusinessAPI,
  verifySMS2FACodeAPI,
  verifySMSCodeAPI,
} from './authAPI';
import getQueryKey from 'utils/get-query-key';
import useAxios from 'hooks/Common/useAxios';

export const useLogin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return useMutation(
    async ({
      email,
      password,
      authProvider,
    }: {
      email: string;
      password?: string;
      authProvider?: string;
    }) => {
      const lowerCaseEmail = getLowerCaseString(email);
      // Step 1
      if (password) {
        await Auth.signIn(lowerCaseEmail, password);
      }
      // Step 2
      let [response, e] = await handlePromise(getUserInfoAPI(lowerCaseEmail));

      if (e) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, err] = await handlePromise(
          Auth.signUp({
            username: lowerCaseEmail,
            password: generatePassword(),
            attributes: {
              email: lowerCaseEmail,
              profile: commonConstants.VENDOR_ROLE,
              website: process.env.REACT_APP_WEBSITE_URL,
              'custom:authProvider':
                authProvider ?? commonConstants.SOCIAL_MEDIA.GOOGLE,
            },
          }),
        );
        if (!err) {
          response = await getUserInfoAPI(lowerCaseEmail);
        }
      }

      if (response?.data?.role !== commonConstants.VENDOR_ROLE) {
        throw new Error(
          'Incorrect Login Details. The email address or password you have used is incorrect. Please check if your details are correct.',
        );
      }

      return response;
    },
    {
      onSuccess: (response: any, { password }) => {
        toast(
          <ToastMessage
            text="Authenticated 1 of 2"
            type={ToastType.SUCCESS.type}
          />,
        );
        dispatch(login({ ...response.data, isSocialLogin: !password }));
      },
      onError: async (error: any, payload) => {
        try {
          Auth.signOut();
          const jsonError: { name: string; code: string } = JSON.parse(
            JSON.stringify(error),
          );
          if (jsonError?.name === errorConstants.USER_NOT_CONFIRMED) {
            navigate('/auth/resend-verify-email', {
              state: { verificationInfo: { username: payload.email } },
            });
          }
          if (!payload.password) {
            Auth.signOut();
          }
        } catch (err) {
          /* Do nothing */
        }
        toast(
          <ToastMessage
            text={error?.message ?? commonConstants.SOMETHING_WENT_WRONG}
            type={ToastType.ERROR.type}
          />,
        );
      },
    },
  );
};

type UseLogoutProps = {
  willUseToast?: boolean;
  willImmediatelyNavigateToLogin?: boolean;
};

export const useLogout = (props?: UseLogoutProps) => {
  const dispatch = useDispatch();
  const user = useSelector(getUser);
  const willUseToast = props?.willUseToast ?? true;
  const willImmediatelyNavigateToLogin =
    props?.willImmediatelyNavigateToLogin ?? false;
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  return useMutation(
    async () => {
      if (user?.isSocialLogin) {
        dispatch(logout());
        persistor.purge();
        return Auth.signOut();
      }
      await Auth.signOut();
      dispatch(logout());
      persistor.purge();
    },
    {
      onSettled: () => {
        queryClient.invalidateQueries();
        if (willUseToast) {
          toast(
            <ToastMessage
              text="You have been successfully logged out."
              type={ToastType.SUCCESS.type}
            />,
          );
        }
        if (willImmediatelyNavigateToLogin) {
          navigate('/auth/login');
        }
      },
    },
  );
};

export const useForgotPassword = () => {
  return useMutation(
    (payload: { email: string }) => {
      const lowerCaseEmail = getLowerCaseString(payload.email);
      return Auth.forgotPassword(
        lowerCaseEmail, // username
        {
          website: process.env.REACT_APP_WEBSITE_URL ?? '',
        }, // clientMetadata
      );
    },
    {
      onSuccess: () => {
        toast(
          <ToastMessage
            text="We have sent you an email to reset your password."
            type={ToastType.SUCCESS.type}
          />,
        );
      },
    },
  );
};

export const useResetPassword = () => {
  return useMutation(
    (payload: { email: string; code: string; newPassword: string }) => {
      const lowerCaseEmail = getLowerCaseString(payload.email);
      return Auth.forgotPasswordSubmit(
        lowerCaseEmail,
        payload.code,
        payload.newPassword,
      );
    },
    {
      onSuccess: () => {
        toast(
          <ToastMessage
            text="Your password has been reset."
            type={ToastType.SUCCESS.type}
          />,
        );
      },
    },
  );
};

export const useUpdatePassword = () => {
  return useMutation(
    async (payload: { oldPassword: string; newPassword: string }) => {
      const user = await Auth.currentAuthenticatedUser();
      return Auth.changePassword(
        user,
        payload.oldPassword,
        payload.newPassword,
      );
    },
    {
      onSuccess: () => {
        toast(
          <ToastMessage
            text="Your password has been updated."
            type={ToastType.SUCCESS.type}
          />,
        );
      },
      onError: (error: any) => {
        let errorMessage = error?.message ?? '';
        if (errorMessage?.includes('Incorrect username or password.')) {
          errorMessage = 'The Old Password is not correct.';
        }
        toast(
          <ToastMessage
            text={
              error?.response?.data?.message ??
              errorMessage ??
              commonConstants.SOMETHING_WENT_WRONG
            }
            type={ToastType.ERROR.type}
          />,
        );
      },
    },
  );
};

type SignUpPayload = {
  email: string;
  password: string;
};

export const useSignUp = () => {
  const navigate = useNavigate();
  return useMutation(
    async (payload: SignUpPayload) => {
      const { email, password } = payload;
      const lowerCaseEmail = getLowerCaseString(email);
      await Auth.signUp({
        username: lowerCaseEmail,
        password,
        attributes: {
          email: lowerCaseEmail,
          profile: commonConstants.VENDOR_ROLE,
          website: process.env.REACT_APP_WEBSITE_URL,
        },
      });
    },
    {
      onSuccess: (_data: any, payload: SignUpPayload) => {
        navigate('/auth/resend-verify-email', {
          state: {
            verificationInfo: { username: payload.email },
          },
        });
        toast(
          <ToastMessage
            text="We have sent you an email to verify your account."
            type={ToastType.SUCCESS.type}
          />,
        );
      },
    },
  );
};

export const useResendConfirmationEmail = () => {
  return useMutation(
    async (payload: Partial<SignUpPayload>) => {
      const { email } = payload;
      const lowerCaseEmail = getLowerCaseString(email as string);
      await Auth.resendSignUp(
        lowerCaseEmail /* username */,
        {
          website: process.env.REACT_APP_WEBSITE_URL ?? '',
        }, // clientMetadata
      );
    },
    {
      onSuccess: () => {
        toast(
          <ToastMessage
            text="We've resent the email."
            type={ToastType.SUCCESS.type}
          />,
        );
      },
    },
  );
};

export const useSend2FACode = (is_email: boolean) => {
  const user = useSelector(getUser);
  return useMutation(() => send2FACodeAPI(user?.id ?? '', is_email), {
    onSuccess: () => {
      toast(
        <ToastMessage
          text="We've sent the code."
          type={ToastType.SUCCESS.type}
        />,
      );
    },
  });
};

type SendSMSPayload = {
  phone: string;
};

export const useSendSMS = () => {
  const user = useSelector(getUser);
  return useMutation(
    async (payload: SendSMSPayload) => {
      const { phone } = payload;
      await sendSMSCodeAPI(user?.email ?? '', phone);
    },
    {
      onSuccess: () => {
        toast(
          <ToastMessage
            text="We've sent the code."
            type={ToastType.SUCCESS.type}
          />,
        );
      },
    },
  );
};

export const useVerify2FACodeAndUpdateUserInfo = () => {
  const user = useSelector(getUser);
  const dispatch = useDispatch();
  return useMutation(
    async (payload: VerifyCodePayload) => {
      const { code } = payload;
      await verifySMS2FACodeAPI(user?.id ?? '', code);
    },
    {
      onSuccess: async () => {
        toast(
          <ToastMessage
            text="You've successfully logged in."
            type={ToastType.SUCCESS.type}
          />,
        );
        try {
          const response = await getUserInfoAPI(user?.email ?? '');
          dispatch(login(response?.data));
        } catch (err: any) {
          dispatch(logout());
        }
      },
    },
  );
};

type VerifyCodePayload = {
  code: string;
};

export const useVerifyCodeAndUpdatePhoneInfo = () => {
  const user = useSelector(getUser);
  const dispatch = useDispatch();
  return useMutation(
    async (payload: VerifyCodePayload) => {
      const { code } = payload;
      await verifySMSCodeAPI(user?.email ?? '', code);
    },
    {
      onSuccess: async () => {
        toast(
          <ToastMessage
            text="Your account is now secure."
            type={ToastType.SUCCESS.type}
          />,
        );
        try {
          const response = await getUserInfoAPI(user?.email ?? '');
          dispatch(login(response?.data));
        } catch (err: any) {
          dispatch(logout());
        }
      },
    },
  );
};

export type ValidateBusinessPayload = {
  abn: string;
  domain: string;
};

export const useValidateBusiness = () => {
  return useMutation((payload: ValidateBusinessPayload) => {
    const { abn, domain } = payload;
    return validateBusinessAPI(abn, domain);
  });
};

export type UpdateVendorInfoPayload = {
  user_id?: string;
  first_name?: string;
  last_name?: string;
  business_name?: string;
  abn?: string;
  year_of_incorporation?: string;
  domain?: string;
  business_category?: string | string[];
  technology_use?: string[];
  logo?: string;
  address?: {
    hq_address: AddressType;
    branches: AddressType[];
  };
};

export const useUpdateVendorInfo = () => {
  const user = useSelector(getUser);
  const dispatch = useDispatch();
  const { mutate: mutateLogout } = useLogout();
  return useMutation(
    (payload: UpdateVendorInfoPayload) => {
      const apiPayload = {
        ...payload,
        user_id: user?.id,
      };
      return updateVendorInfoAPI(apiPayload);
    },
    {
      onSuccess: async () => {
        try {
          const response = await getUserInfoAPI(user?.email ?? '');
          dispatch(login(response?.data));
        } catch (err: any) {
          mutateLogout();
        }
      },
    },
  );
};

type GetBusinessCategoriesResponse = {
  label: string;
  value: string;
};

export const useGetBusinessCategories = () => {
  return useQuery(
    getQueryKey.getBusinessCategories(),
    () => businessCategoriesAPI(),
    {
      select: (response) => {
        let data: GetBusinessCategoriesResponse[] =
          [] as GetBusinessCategoriesResponse[];
        if (response?.data && response?.data?.length > 0) {
          data = response?.data?.map((item: { name: string; _id: string }) => ({
            label: item?.name,
            value: item?.name,
          }));
        }
        return data;
      },
      onError: (error: any) => {
        console.log(error.message);
      },
    },
  );
};

export const useGetTechnologies = () => {
  const axios = useAxios();
  return useQuery(
    getQueryKey.getTechnologies(),
    () => axios.get('/technology'),
    {
      select: (response) => {
        let data: string[] = [];
        if (response?.data && response?.data?.length > 0) {
          data = response?.data;
        }
        return data;
      },
      onError: (error: any) => {
        console.log(error.message);
      },
    },
  );
};

export const useOnlyAllowedIfVendorIsApproved = () => {
  const user = useSelector(getUser);
  const navigate = useNavigate();
  useEffect(() => {
    if (user?.vendor?.is_approved === false) {
      navigate('/api-plugins');
    }
  }, [navigate, user?.vendor?.is_approved]);
};

export const useFetchUserInfoOnce = () => {
  const user = useSelector(getUser);
  const dispatch = useDispatch<AppDispatch>();
  const runOnce = useRef(true);
  useEffect(() => {
    const fetchInfo = async () => {
      const response = await getUserInfoAPI(user?.email ?? '');
      dispatch(login(response?.data));
    };
    if (runOnce.current) {
      fetchInfo();
      runOnce.current = false;
    }
  }, [dispatch, user?.email, user?.vendor?.is_approved]);
};

export const useSendWelcomeEmail = () => {
  const axios = useAxios();
  return useMutation(() => axios.get('/notifications/confirm-email'));
};

export const useCheckValidUserPassword = () => {
  const axios = useAxios();
  return useMutation(({ password }: { password: string }) =>
    axios.post('/auth/check-password', { password }),
  );
};
