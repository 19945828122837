import {
  InputBase,
  Stack,
  styled,
  Box,
  CircularProgress,
  IconButton,
  SxProps,
  Theme,
} from '@mui/material';
import { useRef } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';

export const SearchBox = styled(Box)`
  padding: 0 16px;
  width: 343px;
  height: 49px;
  display: grid;
  grid-template-columns: auto 1fr auto;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  border: 1px solid #eceff1;
  box-shadow: 0px 0px 5px rgba(112, 129, 163, 0.102109);
  border-radius: 4px;

  & > .MuiInputBase-root {
    margin-left: 16px;
  }

  & > .MuiInputBase-root > .MuiInputBase-input {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #535f78;
  }
`;

type SearchFieldProps = {
  onHandleChangeKeyword: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onHandleClear?: () => void;
  placeholder: string;
  isLoading?: boolean;
  sx?: SxProps<Theme>;
};

const SearchField = ({
  placeholder,
  onHandleChangeKeyword,
  isLoading,
  onHandleClear,
  sx,
}: SearchFieldProps) => {
  const inputRef = useRef();
  const handleClearInput = () => {
    onHandleClear && onHandleClear();
    // @ts-ignore
    inputRef.current.value = '';
  };
  return (
    <Stack direction="row" gap={3} alignItems="center" sx={sx}>
      <SearchBox>
        <SearchIcon />
        <InputBase
          inputRef={inputRef}
          placeholder={placeholder}
          onChange={onHandleChangeKeyword}
        />
        {onHandleClear ? (
          <IconButton onClick={handleClearInput}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </SearchBox>
      {isLoading ? <CircularProgress /> : null}
    </Stack>
  );
};

export default SearchField;
