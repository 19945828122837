import { Stack } from '@mui/material';
import LogoIReferIcon from 'assets/images/icon-logo-irefer-trademark.png';
import { StyledBox } from 'components/Common/Styled/CommonStyled';
import {
  CommonSubtext,
  CommonSubtextLink,
  CommonTitleText,
} from 'components/Common/Styled/TypographyStyed';
import FormikControl, { CONTROL_TYPE } from 'components/Formik/FormikControl';
import commonConstants from 'constants/common.constant';
import { Form, Formik } from 'formik';
import {
  useSend2FACode,
  useVerify2FACodeAndUpdateUserInfo,
} from 'hooks/Auth/useAuth';
import useCounter from 'hooks/Common/useCounter';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getUser } from 'redux/selectors/auth.selector';
import { themes } from 'theme';
import * as Yup from 'yup';

import {
  Logo,
  StyledLoadingButton,
  StyledStack,
  TextButton,
} from '../AuthStyled';

const validationCodeSchema = Yup.object({
  code: Yup.string()
    .required('Required')
    .matches(/^[0-9]{7}$/, 'Must be exactly 7 digits'),
});

type CodeValues = {
  code?: string;
};

const initialCodeValues: CodeValues = {
  code: '',
};

const getContentByOption = (
  hiddenPhoneNumbers: string,
  email: string,
  isSendBySms: boolean,
) =>
  isSendBySms
    ? `We have texted a unique 7 digit code to your mobile number ${hiddenPhoneNumbers}. Please enter this code in the field below to continue.`
    : `Enter the 7-digit verification code sent to your email address: ${email}`;

const VerifyAccount = () => {
  const testRef = useRef(false);
  const { counter, resetCounter } = useCounter(-1);
  const [isSendBySms, setIsSendBySms] = useState(true);
  const {
    mutate: mutateSendSMS2FA,
    mutateAsync: mutateAsyncSendSMS2FA,
    isLoading: isLoadingSendSMS,
    reset,
  } = useSend2FACode(!isSendBySms /* is_email */);
  const {
    mutate: mutateVerify2FACodeAndUpdateInfo,
    isLoading: isLoadingVerifyCode,
  } = useVerify2FACodeAndUpdateUserInfo();

  const handleResend = () => {
    mutateSendSMS2FA(undefined, {
      onSuccess: () => {
        resetCounter();
      },
    });
  };

  useEffect(() => {
    const execute = async () => {
      await mutateAsyncSendSMS2FA();
      reset();
    };
    if (!testRef.current) {
      execute();
      testRef.current = true;
    }
  }, [mutateAsyncSendSMS2FA, reset]);

  const user = useSelector(getUser);
  const navigate = useNavigate();
  useEffect(() => {
    if (!!user?.is_2fa) {
      navigate('/auth/stripe-approval');
    }
  }, [navigate, user]);

  const onSubmitCode = (values: any, formikBag: any) => {
    mutateVerify2FACodeAndUpdateInfo(values, {
      onError: (error: any) => {
        const errorMessage =
          error?.response?.data?.message ??
          error?.message ??
          commonConstants.SOMETHING_WENT_WRONG;
        formikBag?.setFieldError('code', errorMessage);
      },
    });
  };

  const isLoading = useMemo(
    () => isLoadingSendSMS || isLoadingVerifyCode,
    [isLoadingSendSMS, isLoadingVerifyCode],
  );

  const hiddenPhoneNumbers = useMemo(() => {
    return user?.phone.slice(-4).padStart(user?.phone.length, '•');
  }, [user?.phone]);

  return (
    <Formik
      initialValues={initialCodeValues}
      validationSchema={validationCodeSchema}
      onSubmit={onSubmitCode}
      enableReinitialize={true}
    >
      {({ handleSubmit }) => (
        <Form>
          <Helmet>
            <title>Verify Your Account</title>
            <meta name="description" content="iRefer Verify your account" />
          </Helmet>
          <StyledBox
            sx={{
              padding: '52px',
              display: 'flex',
              alignItems: 'center',
              position: 'relative',
            }}
          >
            <StyledStack
              sx={{
                gap: 3,
                height: '100%!important',
              }}
            >
              <Stack alignItems="center" mb={2}>
                <Logo
                  alt="iReferLogo"
                  src={LogoIReferIcon}
                  style={{ width: '104px' }}
                />
              </Stack>
              <CommonTitleText>PLEASE ENTER VERIFICATION CODE</CommonTitleText>
              <StyledStack sx={{ gap: 0 }}>
                <CommonSubtext>
                  {getContentByOption(
                    hiddenPhoneNumbers ?? '',
                    user?.email ?? '',
                    isSendBySms,
                  )}
                </CommonSubtext>
              </StyledStack>

              <FormikControl
                control={CONTROL_TYPE.INPUT}
                name="code"
                label="7-digit verification code"
                placeholder="7-digit verification code"
                maxLength={7}
              />

              <StyledStack sx={{ gap: 0 }}>
                <StyledLoadingButton
                  loading={isLoading}
                  variant="contained"
                  onClick={() => {
                    handleSubmit();
                  }}
                  sx={{ marginBottom: '1.2rem' }}
                >
                  Continue
                </StyledLoadingButton>
                {counter > -1 ? (
                  <CommonSubtext
                    sx={{
                      color: themes.light.colorMaximumBlueGreen,
                      fontWeight: 500,
                    }}
                  >
                    Resend code {counter}s
                  </CommonSubtext>
                ) : (
                  <TextButton
                    disabled={isLoading}
                    onClick={() => handleResend()}
                  >
                    Resend code
                  </TextButton>
                )}
                {isSendBySms ? (
                  <CommonSubtext mt={2}>
                    Having trouble?{' '}
                    <CommonSubtextLink as="span">
                      <TextButton
                        onClick={() => setIsSendBySms(false)}
                        variant="text"
                        disabled={isLoading}
                      >
                        Verify with email
                      </TextButton>
                    </CommonSubtextLink>
                  </CommonSubtext>
                ) : null}
              </StyledStack>
            </StyledStack>
          </StyledBox>
        </Form>
      )}
    </Formik>
  );
};

export default VerifyAccount;
