import React, { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import {
  getIsAuthenticated,
  getUser,
} from '../../redux/selectors/auth.selector';

type ProtectedRouteProps = {
  skipRedirectForcingPages?: boolean;
  redirectPath?: string;
  children: ReactNode;
};

const ProtectedRoute = ({
  skipRedirectForcingPages = false,
  redirectPath = '/auth/login',
  children,
}: ProtectedRouteProps): any => {
  const isAuthenticatedVendorUser = useSelector(getIsAuthenticated);
  const user = useSelector(getUser);
  const location = useLocation();
  if (!isAuthenticatedVendorUser) {
    return <Navigate to={redirectPath} replace state={{ from: location }} />;
  }
  if (!skipRedirectForcingPages) {
    // Force to Fullfil Vendor Phone number
    if (!user?.phone) {
      return <Navigate to="/auth/verify-phone-sign-up" replace />;
    } else if (
      // Force to sign out and navigate to List Your Business Screens
      !user?.vendor?.abn
    ) {
      return <Navigate to="/auth/list-your-business" replace />;
    } else if (
      // Force to 2FA screen
      !user?.is_2fa
    ) {
      return <Navigate to="/auth/verify-account" replace />;
    }
  }

  return children ?? <Outlet />;
};

export default ProtectedRoute;
