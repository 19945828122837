import { Button, styled, Typography } from '@mui/material';
import { themes } from 'theme';

export const PageHeader = styled(Typography)({
  fontFamily: themes.light.fontLexendTera,
  fontWeight: 400,
  fontSize: '48px',
  lineHeight: '60px',
  letterSpacing: '-0.05em',
  textTransform: 'uppercase',
  color: themes.light.colorBlack,
});

export const Heading18 = styled(Typography)({
  fontSize: '18px',
  fontFamily: themes.light.fontLexendTera,
  fontWeight: 400,
});

// Font Size 72
export const Text72Weight600 = styled(Typography)({
  fontWeight: 600,
  fontSize: '72px',
  color: themes.light.colorBlack,
});

// Font Size 32
export const Text32ChineseBlack = styled(Typography)({
  fontWeight: 500,
  fontSize: '32px',
  color: themes.light.colorChineseBlack,
});
export const Text32ChineseBlackWeight700 = styled(Text32ChineseBlack)({
  fontWeight: 700,
});

// Font Size 30
export const Text30Weight600 = styled(Typography)({
  fontWeight: 600,
  fontSize: '30px',
  color: themes.light.colorBlack,
});

// Font Size 24
export const Text24ChineseBlackWeight700 = styled(Typography)({
  fontWeight: 700,
  fontSize: '24px',
  color: themes.light.colorChineseBlack,
});

// Font Size 18
export const Text18Weight500 = styled(Typography)({
  fontWeight: 500,
  fontSize: '18px',
  color: themes.light.colorBlack,
});
export const Text18ChineseBlackWeight700 = styled(Typography)({
  fontWeight: 700,
  fontSize: '18px',
  color: themes.light.colorChineseBlack,
});
export const Text18DarkBlueGreyWeight700 = styled(Typography)({
  fontWeight: 700,
  fontSize: '18px',
  color: themes.light.colorDarkBlueGrey,
});

// Font Size 16
export const Text16Weight400 = styled(Typography)({
  fontWeight: 400,
  fontSize: '16px',
  color: themes.light.colorBlack,
});
export const Text16GrayWeight400 = styled(Typography)({
  fontWeight: 400,
  fontSize: '16px',
  color: themes.light.colorGray,
});
export const Text16Weight500 = styled(Typography)({
  fontWeight: 500,
  fontSize: '16px',
  color: themes.light.colorBlack,
});
export const Text16Weight500Gray = styled(Typography)({
  fontWeight: 500,
  fontSize: '16px',
  color: themes.light.colorGray,
});
export const Text16ChineseBlackWeight500 = styled(Typography)({
  fontWeight: 500,
  fontSize: '16px',
  color: themes.light.colorChineseBlack,
});
export const Text16Weight600 = styled(Typography)({
  fontWeight: 600,
  fontSize: '16px',
  color: themes.light.colorBlack,
});
export const Text16MaximumBlueGreenWeight600 = styled(Typography)({
  fontWeight: 600,
  fontSize: '16px',
  color: themes.light.colorMaximumBlueGreen,
});

// Font Size 14
export const Text14Weight500 = styled(Typography)({
  fontWeight: 500,
  fontSize: '14px',
  color: themes.light.colorBlack,
});
export const Text14Weight400 = styled(Typography)({
  fontWeight: 400,
  fontSize: '14px',
  color: themes.light.colorBlack,
});
export const Text14Weight500Gray = styled(Typography)({
  fontWeight: 500,
  fontSize: '14px',
  color: themes.light.colorGray,
});
export const Text14ChineseBlackWeight500 = styled(Typography)({
  fontWeight: 500,
  fontSize: '14px',
  color: themes.light.colorChineseBlack,
});
export const Text14Weight600Gray = styled(Typography)({
  fontWeight: 600,
  fontSize: '14px',
  color: themes.light.colorGray,
});
export const Text14Weight400Gray = styled(Typography)({
  fontWeight: 400,
  fontSize: '14px',
  color: themes.light.colorGray,
});

// Font Size 12
export const Text12Weight500Gray = styled(Typography)({
  fontWeight: 500,
  fontSize: '12px',
  color: themes.light.colorGray,
});
export const Text12Weight600 = styled(Typography)({
  fontWeight: 600,
  fontSize: '12px',
  color: themes.light.colorBlack,
});

export const TextButton = styled(Button)({
  display: 'flex',
  textTransform: 'unset',
  fontSize: '1rem',
});

export const CommonTitleText = styled(Text16Weight400)({
  textAlign: 'center',
  fontFamily: themes.light.fontLexendTera,
  letterSpacing: '-0.05em',
  textTransform: 'uppercase',
});

export const CommonSubtext = styled(Typography)({
  textAlign: 'center',
  fontWeight: 400,
  fontSize: '1rem',
  color: themes.light.colorGray,
  textTransform: 'none',
});

export const CommonSmallSubtext = styled(CommonSubtext)({
  fontSize: '0.75rem',
});

export const CommonSubtextLink = styled(CommonSubtext)({
  display: 'inline-block',
  color: themes.light.colorMaximumBlueGreen,
  cursor: 'pointer',
});

export const CommonErrorText = styled(Text16Weight400)({
  color: themes.light.colorElectricRed,
});
