import { useFormikContext } from 'formik';
import FormikControl, {
  CONTROL_TYPE,
} from '../../../components/Formik/FormikControl';
import { StyledLoadingButton, StyledStack } from '../AuthStyled';
import * as Yup from 'yup';

export const validationSchemaLYBStep1 = Yup.object({
  first_name: Yup.string()
    .transform((val) => val.trim())
    .required('Required'),
  last_name: Yup.string()
    .transform((val) => val.trim())
    .required('Required'),
});

export type ValuesLYBStep1Type = {
  first_name: string;
  last_name: string;
};

export const initialValuesLYBStep1 = (values: Partial<ValuesLYBStep1Type>) => ({
  first_name: values?.first_name ?? '',
  last_name: values?.last_name ?? '',
});

const LYBStep1 = () => {
  const { handleSubmit } = useFormikContext();
  return (
    <StyledStack
      sx={{
        gap: 3,
      }}
    >
      <FormikControl
        control={CONTROL_TYPE.INPUT}
        name="first_name"
        label="First Name"
        maxLength={100}
        displayCheck
      />
      <FormikControl
        control={CONTROL_TYPE.INPUT}
        name="last_name"
        label="Last Name"
        maxLength={100}
        displayCheck
      />
      <StyledLoadingButton onClick={() => handleSubmit()} variant="contained">
        Next
      </StyledLoadingButton>
    </StyledStack>
  );
};

export default LYBStep1;
