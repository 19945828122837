import CloseIcon from '@mui/icons-material/Close';
import { StyledLoadingButton } from 'components/Common/Styled/CommonStyled';
import {
  Text14Weight400,
  Text18Weight500,
} from 'components/Common/Styled/TypographyStyed';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  ClosePopover,
  LinkBankPopper,
  PopoverContainer,
} from './LinkBankAccountStyled';
import commonConstants from 'constants/common.constant';

type LinkBankAccountPopoverProps = {
  anchor: any;
  setAnchor: any;
  linkBankAccountStatus?: Extract<
    ValueOf<typeof commonConstants.STRIPE_STATUS_TYPE>,
    'not-created' | 'action-required'
  >;
};

const CONTENT: Record<
  Extract<
    ValueOf<typeof commonConstants.STRIPE_STATUS_TYPE>,
    'not-created' | 'action-required'
  >,
  { title: string; subTitle: string; actionBtnText: string }
> = {
  [commonConstants.STRIPE_STATUS_TYPE.NOT_CREATED]: {
    title: 'AUTHENTICATE ACCOUNT',
    subTitle: 'Begin set-up process to start receiving payments.',
    actionBtnText: 'Authenticate now',
  },
  [commonConstants.STRIPE_STATUS_TYPE.ACTION_REQUIRED]: {
    title: 'Verify your account',
    subTitle: 'There is further actions required to verify your account.',
    actionBtnText: 'View requirements',
  },
} as const;

const LinkBankAccountPopover = ({
  anchor,
  setAnchor,
  linkBankAccountStatus,
}: LinkBankAccountPopoverProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const open =
    Boolean(anchor) &&
    location.pathname !== '/account/payments/link-bank-account';
  const id = open ? 'simple-popover' : undefined;

  const handleClose = () => {
    setAnchor(null);
  };

  const content = linkBankAccountStatus ? CONTENT[linkBankAccountStatus] : null;

  const goLinkBankAccount = () => {
    setAnchor(null);
    navigate('/account/payments/link-bank-account', {
      state: {
        notLinked: true,
      },
    });
  };

  return (
    <div>
      <LinkBankPopper
        id={id}
        open={open}
        anchorEl={anchor}
        sx={{
          zIndex: 10000,
          background: 'white',
          boxShadow:
            'rgb(0, 0, 0, 0.2) 0px 5px 5px -3px, rgb(0, 0, 0, 0.14) 0px 8px 10px 1px, rgb(0, 0, 0, 0.12) 0px 3px 14px 2px',
        }}
      >
        <PopoverContainer>
          <ClosePopover onClick={handleClose}>
            <CloseIcon />
          </ClosePopover>
          <Text18Weight500>{content?.title}</Text18Weight500>
          <Text14Weight400>{content?.subTitle}</Text14Weight400>

          <StyledLoadingButton
            sx={{ width: '100%' }}
            onClick={goLinkBankAccount}
            variant="contained"
          >
            {content?.actionBtnText}
          </StyledLoadingButton>
        </PopoverContainer>
      </LinkBankPopper>
    </div>
  );
};

export default LinkBankAccountPopover;
