import { Box, styled, Typography } from '@mui/material';
import { themes } from 'theme';

export const LoadingContentContainer = styled(Box)(({ theme }) => ({
  backgroundColor: themes.light.backgroundColorWhite,
  padding: '40px',
  minHeight: '600px',
  width: '600px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  [theme.breakpoints.down('md')]: {
    maxWidth: '90vw',
  },
}));

export const LoadingText = styled(Typography)({
  textAlign: 'center',
});

export const LoadingImg = styled('img')({
  width: '152px',
  height: '160px',
  textAlign: 'center',
  objectFit: 'cover',
});
