import { Stack } from '@mui/material';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation, useNavigate } from 'react-router-dom';
import LogoIReferIcon from '../../../assets/images/icon-logo-irefer-trademark.png';
import { StyledBox } from '../../../components/Common/Styled/CommonStyled';
import {
  CommonSubtext,
  CommonTitleText,
} from '../../../components/Common/Styled/TypographyStyed';
import { Logo, StyledLoadingButton, StyledStack } from '../AuthStyled';

export const CONTENT_PROCESSING_YOUR_APPLICATION = {
  PROCESSING: {
    title: 'We are currently processing your application.',
    subText: `Great things are on the horizon! Within the next 72 hours, we'll get in touch to update you on your account status. If you haven’t heard from us in 72 hours please email us at connect@i-refer.global`,
    subText2: `Meanwhile, consider installing the iRefer plugin to ensure you're ready to go live the instant we approve your account. Rest assured, we'll notify you and our team will contact you the moment your store becomes visible on the iRefer app. Get set for a smooth launch!`,
  },
  CONGRATULATION: {
    title: 'Congratulations! Your request has been received.',
    subText: `Great things are on the horizon! Within the next 72 hours, we'll get in touch to update you on your account status.`,
    subText2: `Meanwhile, consider installing the iRefer plugin to ensure you're ready to go live the instant we approve your account. Rest assured, we'll notify you and our team will contact you the moment your store becomes visible on the iRefer app. Get set for a smooth launch!`,
  },
};

const WaitingApprove = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!location.state?.contentMessage) {
      navigate('/');
    }
  }, [location, navigate]);

  const content = location.state?.contentMessage;

  return (
    <StyledBox
      sx={{
        padding: '52px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
      }}
    >
      <Helmet>
        <title>iRefer</title>
        <meta name="description" content="iRefer" />
      </Helmet>
      <StyledStack
        sx={{ gap: 5, width: '100%', height: '100%', justifyContent: 'center' }}
      >
        <Stack alignItems="center" mb={2}>
          <Logo
            alt="IReferLogo"
            src={LogoIReferIcon}
            style={{ width: '104px' }}
          />
        </Stack>
        <CommonTitleText sx={{ textTransform: 'uppercase' }}>
          {content?.title}
        </CommonTitleText>
        <CommonSubtext sx={{ padding: '0 1rem' }}>
          {content?.subText}
        </CommonSubtext>
        <CommonSubtext sx={{ padding: '0 1rem' }}>
          {content?.subText2}
        </CommonSubtext>
        <StyledLoadingButton
          variant="contained"
          onClick={() => navigate('/api-plugins')}
        >
          Install the iRefer Plugin
        </StyledLoadingButton>
      </StyledStack>
    </StyledBox>
  );
};

export default WaitingApprove;
