import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  CodeText,
  CustomBox,
  Logo,
  MessageParagraph,
} from './PageNotFoundStyled';
import LogoIReferIcon from '../../assets/images/icon-logo-irefer-trademark.png';
import { Helmet } from 'react-helmet-async';
import { Wrapper } from 'components/Common/Styled/CommonStyled';

function PageNotFound() {
  const navigate = useNavigate();

  return (
    <Wrapper>
      <Helmet>
        <title>404 Page Not Found</title>
        <meta name="description" content="iRefer Page not found" />
      </Helmet>
      <CustomBox>
        <Logo src={LogoIReferIcon} onClick={() => navigate('/')} />
        <CodeText>PAGE NOT FOUND</CodeText>
        <MessageParagraph>
          The link you followed may be broken, or the page may have been
          removed.
        </MessageParagraph>
      </CustomBox>
    </Wrapper>
  );
}

export default PageNotFound;
