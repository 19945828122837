import moment from 'moment';

import commonConstants from '../constants/common.constant';

export type DobType = {
  day: string;
  month: string;
  year: string;
};

export const getDobString = (value: DobType) =>
  value?.day
    ? moment(
        `${value?.day}/${value?.month}/${value?.year}`,
        'DD/M/YYYY',
      ).format(commonConstants.DATE_FORMAT.DD_MM_YYYY)
    : '--';

export const getLowerCaseString = (value: string) => value.toLowerCase();
