import { styled, IconButton } from '@mui/material';

export const StyledCloseButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: 26,
  right: 24,
  [theme.breakpoints.down(500)]: {
    top: 15,
    right: 15,
  },
}));
