import { createTheme } from '@mui/material';

const lightTheme = {
  fontLexendTera: 'Lexend Tera',
  fontBaiJamjuree: 'Bai Jamjuree',
  fontMontserrat: 'Montserrat',

  colorElectricRed: '#E10600',
  colorErrorRed: '#FF2020',
  colorFashionFuchsia: '#E10098',
  colorDarkOrchid: '#963CBD',
  colorIslamicGreen: '#8C0077',
  colorMaximumBlueGreen: '#2CCCD3',
  colorMediumTurquoise: '#3FCFD5',
  colorVividYellow: '#FCE300',
  colorVividGamboge: '#FF9800',
  colorIrishGreen: '#008C15',
  colorJadeGreen: '#1EAD54',
  colorSpanishGray: '#999999',
  colorWhite: '#FFFFFF',
  colorBlack: '#000000',
  colorChineseBlack: '#171717',
  colorLightGray: '#B4B4B4',
  colorGray: '#646464',
  colorSilver: '#C4C4C4',
  colorAlabaster: '#FAFAFA',
  colorDarkBlueGrey: '#183F50',

  backgroundColorGray: 'rgba(0, 0, 0, 0.6)',
  backgroundColorBlack: 'rgba(0, 0, 0, 1)',
  backgroundColorWhite: 'rgba(255, 255, 255, 1)',
  backgroundColorDisabled: 'rgba(153, 153, 153, 1)',
  backgroundColorAlabaster: '#F9FAFC',

  borderColor: '#313131',
};

// export type Theme = typeof lightTheme

export const themes = {
  light: lightTheme,
  //add theme if customer like
};

export const themeMaterial = createTheme({
  palette: {
    primary: {
      main: lightTheme.colorMaximumBlueGreen,
      contrastText: lightTheme.colorWhite,
    },
  },
  typography: {
    fontFamily: [
      'Montserrat',
      'Lexend Tera',
      'Bai Jamjuree',
      'SF ProDisplay',
      'Opens Sans',
      'serif',
    ].join(','),
  },
});
