import { Badge, Stack, styled } from '@mui/material';
import { themes } from 'theme';

export const UploadSectionContainer = styled(Stack)({
  justifyContent: 'center',
  alignContent: 'center',
  flexDirection: 'row',
});

export const Logo = styled('img')({
  width: '200px',
  height: '200px',
});

export const StyledImage = styled('img')({
  width: '100%',
  height: '100%',
  objectFit: 'contain',
});

export const StyledBadge = styled(Badge)({
  '& .MuiBadge-badge': {
    right: 30,
    bottom: 20,
    border: `4px solid ${themes.light.colorWhite}`,
    width: '3rem',
    height: '3rem',
    borderRadius: '100%',
    backgroundColor: themes.light.colorBlack,
  },
});
