import { StyledLoadingButton, StyledStack } from '../AuthStyled';
import * as Yup from 'yup';
import { useFormikContext } from 'formik';
import UploadSection from 'components/Common/UploadSection/UploadSection';
import { CommonErrorText } from 'components/Common/Styled/TypographyStyed';

export const validationSchemaLYBStep6 = Yup.object({
  logo: Yup.string().required('Logo is required.'),
});

export type ValuesLYBStep6Type = {
  logo: string;
};

export const initialValuesLYBStep6 = (values: Partial<ValuesLYBStep6Type>) => ({
  logo:
    values?.logo && values?.logo?.split('/').pop() !== 'undefined'
      ? values?.logo
      : '',
});

type LYBStep6Props = {
  handleSkip: () => void;
  isLoading: boolean;
};

const LYBStep6 = ({ isLoading }: LYBStep6Props) => {
  const { handleSubmit, errors, setFieldValue, values, touched, isValid } =
    useFormikContext<ValuesLYBStep6Type>();

  return (
    <StyledStack
      sx={{
        gap: 3,
      }}
    >
      <UploadSection
        errors={errors}
        name="logo"
        setFieldValue={setFieldValue}
        value={values['logo']}
        typeAllow={['image']}
      />
      <StyledStack sx={{ gap: 3 }}>
        {Boolean(touched['logo'] && errors['logo']) && (
          <CommonErrorText sx={{ textAlign: 'center' }}>
            Please upload your Company Logo
          </CommonErrorText>
        )}
        <StyledLoadingButton
          loading={isLoading}
          disabled={!isValid}
          variant="contained"
          onClick={() => {
            handleSubmit();
          }}
          sx={{ fontSize: '1rem' }}
        >
          Confirm
        </StyledLoadingButton>
        {/* <TextButton disabled={isLoading} onClick={() => handleSkip()}>
          Skip for now
        </TextButton> */}
      </StyledStack>
    </StyledStack>
  );
};

export default LYBStep6;
