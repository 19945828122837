const DEFAULT_TIMEOUT = 1000 * 10; /* milliseconds */

export const handlePromise = (promise: Promise<any>) => {
  return promise
    .then((data) => [data, undefined])
    .catch((error) => Promise.resolve([undefined, error]));
};

export const racingPromise = (timer: number = DEFAULT_TIMEOUT) => {
  return new Promise((_, reject) => {
    setTimeout(() => {
      return reject(new Error('Time is out'));
    }, timer);
  });
};

export const timeoutPromises = async (
  promiseList: any[],
  timer: number = DEFAULT_TIMEOUT,
) => {
  try {
    const response = await Promise.race([...promiseList, racingPromise(timer)]);
    return response;
  } catch (error) {
    throw error;
  }
};
