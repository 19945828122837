import { Auth } from 'aws-amplify';

import commonConstants from '../../constants/common.constant';
import { UpdateVendorInfoPayload } from './useAuth';
import { callAPIPost, callAPIGet } from './commonAuthAPI';

export const getUserInfoAPI = async (email: string) => {
  const apiName = commonConstants.API_NAME.USER;
  const path = commonConstants.API_PATH.INFO;
  const body = {
    email,
  };
  return callAPIPost(body, apiName, path);
};

export const getAuthToken = async () => {
  try {
    const session = await Auth.currentSession();
    return session.getIdToken().getJwtToken();
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const send2FACodeAPI = (user_id: string, is_email: boolean = false) => {
  const apiName = commonConstants.API_NAME.NOTIFICATION;
  const path = commonConstants.API_PATH.SEND_SMS;
  const body = {
    user_id,
    type: commonConstants.TYPE_2FA,
    is_email,
  };
  return callAPIPost(body, apiName, path);
};

export const verifySMS2FACodeAPI = (user_id: string, code: string) => {
  const apiName = commonConstants.API_NAME.NOTIFICATION;
  const path = commonConstants.API_PATH.SMS_2FA_VERIFICATION;
  const body = {
    user_id,
    code,
  };
  return callAPIPost(body, apiName, path);
};

export const sendSMSCodeAPI = (email: string, phone: string) => {
  const apiName = commonConstants.API_NAME.NOTIFICATION;
  const path = commonConstants.API_PATH.SEND_SMS;
  const body = {
    email,
    phone,
  };
  return callAPIPost(body, apiName, path);
};

export const verifySMSCodeAPI = (email: string, code: string) => {
  const apiName = commonConstants.API_NAME.NOTIFICATION;
  const path = commonConstants.API_PATH.SMS_VERIFICATION;
  const body = {
    email,
    code,
  };
  return callAPIPost(body, apiName, path);
};

export const validateBusinessAPI = (abn: string, domain: string) => {
  const apiName = commonConstants.API_NAME.NOTIFICATION;
  const path = commonConstants.API_PATH.VALIDATE_INFO;
  const body = {
    abn,
    domain,
  };
  return callAPIPost(body, apiName, path);
};

export const updateVendorInfoAPI = (body: UpdateVendorInfoPayload) => {
  const apiName = commonConstants.API_NAME.VENDOR;
  const path = commonConstants.API_PATH.UPDATE_INFO;
  return callAPIPost(body, apiName, path);
};

export const businessCategoriesAPI = () => {
  const apiName = commonConstants.API_NAME.VENDOR;
  const path = commonConstants.API_PATH.BUSINESS_CATEGORIES;
  return callAPIGet(apiName, path, {});
};
