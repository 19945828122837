import { Box } from '@mui/material';
import CustomCountUp from '../CountUp/CountUp';
import {
  Caption,
  Content,
  IconContainer,
  Main,
  MobileContent,
  SubMain,
} from './TotalPerformanceStyled';

type Props = {
  caption: string;
  main: string;
  footer: string;
  icon: JSX.Element | undefined;
  isCurrency?: boolean;
  isPercentage?: boolean;
  sub_main?: string;
};

export function TotalPerformanceItem({
  caption,
  main,
  footer,
  icon,
  isCurrency,
  isPercentage,
  sub_main,
}: Props): JSX.Element {
  let numberString = isCurrency ? +main / 100 : main;
  try {
    if (numberString === 'NaN') {
      numberString = '0';
    }
  } catch (err) {
    numberString = '0';
  }
  return (
    <Box key={caption}>
      <Content>
        <Caption>{caption}</Caption>
        <Main>
          <CustomCountUp
            end={+numberString}
            duration={1}
            prefix={isCurrency ? '$' : ''}
            suffix={isPercentage ? '%' : ''}
            decimalPlaces={!!isCurrency || !!isPercentage ? 2 : 0}
          />
          {sub_main ? <SubMain component="span"> {sub_main}</SubMain> : null}
        </Main>
        {/* <Footer>{footer}</Footer> */}
        <IconContainer>{icon}</IconContainer>
      </Content>
      <MobileContent>
        <IconContainer>{icon}</IconContainer>
        <Caption>{caption}</Caption>
        <Main>
          <CustomCountUp
            end={+numberString}
            duration={1}
            prefix={isCurrency ? '$' : ''}
            suffix={isPercentage ? '%' : ''}
            decimalPlaces={!!isCurrency || !!isPercentage ? 2 : 0}
          />
          {sub_main ? <SubMain component="span"> {sub_main}</SubMain> : null}
        </Main>

        {/* <Footer>{footer}</Footer> */}
      </MobileContent>
    </Box>
  );
}
