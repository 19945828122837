import { MailIcon } from 'components/Icons';
import { OptionItem, OptionText } from '../AccountStyled';
import { useState } from 'react';
import { Snackbar } from '@mui/material';

const COPY_TEXT = 'support@i-refer.global';

const ContactUs = () => {
  const [open, setOpen] = useState(false);
  const onHandleClick = async () => {
    try {
      await navigator.clipboard.writeText('support@i-refer.global');
      if (!open) {
        setOpen(true);
        setTimeout(() => {
          setOpen(false);
        }, 4000);
      }
    } catch (err) {}
  };

  return (
    <OptionItem onClick={onHandleClick}>
      <MailIcon />
      <OptionText>Contact Us</OptionText>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={open}
        onClose={() => setOpen(false)}
        message={`${COPY_TEXT} copied`}
        sx={{ textTransform: 'none' }}
      />
    </OptionItem>
  );
};

export default ContactUs;
