import { Container, AccountProfile } from '../AccountStyled';
import BackButton from '../../../components/Common/BackButton/BackButton';
import { useNavigate } from 'react-router-dom';
import { StyledLoadingButton } from '../../../components/Common/Styled/CommonStyled';
import { Formik } from 'formik';
import FormikControl, {
  CONTROL_TYPE,
} from '../../../components/Formik/FormikControl';
import * as Yup from 'yup';
import { Stack } from '@mui/material';
import { CommonTitleText } from '../../../components/Common/Styled/TypographyStyed';

const validationSchema = Yup.object({
  promotionsAndMarketing: Yup.boolean(),
  paymentsAndBilling: Yup.boolean(),
  referralData: Yup.boolean(),
});

const options = [
  {
    name: 'promotionsAndMarketing',
    title: 'Promotions & Marketing',
    subText: 'Receive iRefer marketing communications.',
  },
  {
    name: 'paymentsAndBilling',
    title: 'Payments & Billing',
    subText:
      'Notifications for payments, billing and transactions on your account.',
  },
  {
    name: 'referralData',
    title: 'Referral Data',
    subText: 'Referral creations, purchases and sales data.',
  },
];

type NotificationValues = {
  promotionsAndMarketing: boolean;
  paymentsAndBilling: boolean;
  referralData: boolean;
};

const initialValues: NotificationValues = {
  promotionsAndMarketing: false,
  paymentsAndBilling: false,
  referralData: false,
};

const Notifications = () => {
  const navigate = useNavigate();
  const onSubmit = (values: NotificationValues) => {
    console.log(values);
  };
  const formikProps = { initialValues, validationSchema, onSubmit };
  return (
    <Container>
      <AccountProfile sx={{ position: 'relative' }}>
        <BackButton onClick={() => navigate('/account')} />
        <CommonTitleText mb={2}>EMAIL NOTIFICATIONS</CommonTitleText>
        <Formik {...formikProps}>
          {({ handleSubmit }) => (
            <>
              <Stack gap={2} mt={2} mb={4} sx={{ width: '80%' }}>
                {options.map(({ name, title, subText }) => (
                  <FormikControl
                    key={name}
                    control={CONTROL_TYPE.CHECK_BOX}
                    name={name}
                    title={title}
                    subText={subText}
                  />
                ))}
              </Stack>
              <StyledLoadingButton
                variant="contained"
                sx={{ width: '80%' }}
                onClick={() => {
                  handleSubmit();
                }}
              >
                Update Preferences
              </StyledLoadingButton>
            </>
          )}
        </Formik>
      </AccountProfile>
    </Container>
  );
};

export default Notifications;
