import { StyledCloseButton } from './BackButtonStyled';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

const BackButton = ({ ...rest }) => (
  <StyledCloseButton {...rest}>
    <ArrowBackIosNewIcon />
  </StyledCloseButton>
);

export default BackButton;
