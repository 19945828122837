import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { IconButton, InputAdornment, Stack } from '@mui/material';
import { Auth, Hub } from 'aws-amplify';
import { Form, Formik } from 'formik';
import React, { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import LogoIReferIcon from '../../../assets/images/icon-logo-irefer-trademark.png';
import AppleLogo from '../../../assets/images/icon-apple.png';
import GoogleLogo from '../../../assets/images/icons8-google-48.png';
import { StyledBox } from '../../../components/Common/Styled/CommonStyled';
import {
  CommonSubtext,
  CommonSubtextLink,
  CommonTitleText,
} from '../../../components/Common/Styled/TypographyStyed';
import FormikControl, {
  CONTROL_TYPE,
} from '../../../components/Formik/FormikControl';
import { useLogin } from '../../../hooks/Auth/useAuth';
import { getIsAuthenticated } from '../../../redux/selectors/auth.selector';
import {
  Logo,
  StyledLoadingButton,
  StyledStack,
  TextButton,
} from '../AuthStyled';
import commonConstants from 'constants/common.constant';

const validationSchema = Yup.object({
  email: Yup.string()
    .email('Not a valid email address')
    .required('Username / Email Address is required'),
  password: Yup.string().required('Password is required'),
});

type LoginValues = {
  email: string;
  password: string;
};

const initialValues: LoginValues = {
  email: '',
  password: '',
};

const Login = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const isAuthenticatedVendorUser = useSelector(getIsAuthenticated);
  const { mutate: mutateLogin, isLoading } = useLogin();
  const onSubmit = (values: LoginValues) => {
    mutateLogin(values);
  };

  const fromObject = location?.state?.from;

  const redirectUrl = fromObject?.pathname || '/';
  let locationState = useMemo(() => {
    if (!!fromObject?.state) {
      return { state: { ...fromObject?.state } };
    }
    return {};
  }, [fromObject]);
  useEffect(() => {
    if (isAuthenticatedVendorUser) {
      navigate(redirectUrl, { replace: true, ...locationState });
    }
  }, [isAuthenticatedVendorUser, locationState, navigate, redirectUrl]);

  useEffect(() => {
    const unsubscribe = Hub.listen(
      'auth',
      ({ payload: { event, data } }) => {},
    );

    Auth.currentAuthenticatedUser()
      .then((currentUser) => {
        // WARNING: UPDATE THIS LOGIC IF WE HAVE MORE SOCIAL LOGIN
        const authProvider = currentUser?.username.includes(
          commonConstants.SOCIAL_MEDIA.GOOGLE,
        )
          ? commonConstants.SOCIAL_MEDIA.GOOGLE
          : commonConstants.SOCIAL_MEDIA.APPLE;
        mutateLogin({
          email: currentUser?.attributes?.email,
          authProvider,
        });
      })
      .catch(() => {
        console.log('Not signed in');
      });

    return unsubscribe;
  }, [mutateLogin]);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
  };

  const formikProps = { initialValues, validationSchema, onSubmit };

  return (
    <Formik {...formikProps}>
      {({ isValid, handleSubmit, dirty }) => (
        <Form>
          <Helmet>
            <title>Vendor Login</title>
            <meta name="description" content="iRefer Login" />
          </Helmet>
          <StyledBox>
            <StyledStack>
              <Stack alignItems="center" mb={2}>
                <Logo
                  alt="IReferLogo"
                  src={LogoIReferIcon}
                  style={{ width: '104px' }}
                />
              </Stack>
              <CommonTitleText>VENDOR LOGIN</CommonTitleText>
              <Stack mt="25px" mb="16px" gap={3}>
                <FormikControl
                  autoCapitalize="none"
                  control={CONTROL_TYPE.INPUT}
                  name="email"
                  label="Username / Email Address"
                  InputLabelProps={{ shrink: true }}
                />
                <FormikControl
                  autoCapitalize="none"
                  control={CONTROL_TYPE.INPUT}
                  name="password"
                  label="Password"
                  type={showPassword ? 'text' : 'password'}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() =>
                            setShowPassword((prevState) => !prevState)
                          }
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <Stack direction="row">
                  <TextButton
                    variant="text"
                    disabled={isLoading}
                    onClick={() => navigate('/auth/forgot-password')}
                  >
                    Forgot password
                  </TextButton>
                </Stack>
              </Stack>
              <StyledLoadingButton
                loading={isLoading}
                variant="contained"
                disabled={!(isValid && dirty)}
                onClick={() => {
                  handleSubmit();
                }}
                sx={{ marginBottom: '1.2rem' }}
              >
                Log in
              </StyledLoadingButton>
              <StyledLoadingButton
                loading={isLoading}
                variant="contained"
                onClick={() => {
                  Auth.federatedSignIn({
                    provider: CognitoHostedUIIdentityProvider.Apple,
                  });
                }}
                sx={{ marginBottom: '.5rem' }}
                startIcon={
                  <Logo
                    alt="AppleLogo"
                    src={AppleLogo}
                    style={{ width: '24px' }}
                  />
                }
              >
                Continue with Apple
              </StyledLoadingButton>
              <StyledLoadingButton
                loading={isLoading}
                variant="contained"
                onClick={() =>
                  Auth.federatedSignIn({
                    provider: CognitoHostedUIIdentityProvider.Google,
                  })
                }
                sx={{ marginBottom: '1.2rem' }}
                startIcon={
                  <Logo
                    alt="GoogleLogo"
                    src={GoogleLogo}
                    style={{ width: '20px' }}
                  />
                }
              >
                Continue with Google
              </StyledLoadingButton>
              <CommonSubtext>
                Not listed with iRefer?{' '}
                <CommonSubtextLink as="span">
                  <TextButton
                    onClick={() => navigate('/auth/sign-up')}
                    variant="text"
                    disabled={isLoading}
                  >
                    Sign up now
                  </TextButton>
                </CommonSubtextLink>
              </CommonSubtext>
            </StyledStack>
          </StyledBox>
        </Form>
      )}
    </Formik>
  );
};

export default Login;
