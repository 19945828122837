import * as React from 'react';

type Props = {
  width?: number;
  height?: number;
};

export function CreditCardIcon({
  width = 32,
  height = 32,
}: Props): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31 21.4688V7.47321C31 6.66295 30.3643 6 29.5714 6H2.42857C1.64286 6 1 6.66295 1 7.47321V25.1518C1 25.9621 1.64286 26.625 2.42857 26.625H29.5714C30.3643 26.625 31 25.9621 31 25.1518V21.4688ZM1 9.68304H31H1ZM1 14.1027H31H1Z"
        stroke="black"
      />
      <path
        d="M4.57129 19.2588H16.7141M4.57129 22.2052H16.7141H4.57129Z"
        stroke="black"
        strokeLinecap="round"
      />
      <path
        d="M23.8571 18.345C24.2356 17.9988 24.7356 17.7852 25.2856 17.7852C26.4714 17.7852 27.4285 18.7722 27.4285 19.995C27.4285 21.2177 26.4714 22.2048 25.2856 22.2048C24.7356 22.2048 24.2356 21.9912 23.8571 21.6376M24.5714 19.995C24.5714 21.2177 23.6142 22.2048 22.4285 22.2048C21.2499 22.2048 20.2856 21.2177 20.2856 19.995C20.2856 18.7722 21.2499 17.7852 22.4285 17.7852C23.6142 17.7852 24.5714 18.7722 24.5714 19.995Z"
        stroke="black"
      />
    </svg>
  );
}
