import { Stack } from '@mui/material';
import LogoIReferIcon from 'assets/images/icon-logo-irefer-trademark.png';
import { StyledBox } from 'components/Common/Styled/CommonStyled';
import {
  CommonSmallSubtext,
  CommonSubtext,
  CommonTitleText,
} from 'components/Common/Styled/TypographyStyed';
import FormikControl, { CONTROL_TYPE } from 'components/Formik/FormikControl';
import commonConstants from 'constants/common.constant';
import { Form, Formik } from 'formik';
import {
  useSendSMS,
  useVerifyCodeAndUpdatePhoneInfo,
} from 'hooks/Auth/useAuth';
import useCounter from 'hooks/Common/useCounter';
import React, { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getUser } from 'redux/selectors/auth.selector';
import { themes } from 'theme';
import { YupValidationTest } from 'utils/yup-validation-test.util';
import * as Yup from 'yup';

import {
  Logo,
  StyledLoadingButton,
  StyledStack,
  TextButton,
} from '../AuthStyled';

const validationPhoneSchema = Yup.object({
  phone: Yup.string()
    .required('Required')
    .test(
      YupValidationTest.IS_VALID_PHONE.NAME,
      YupValidationTest.IS_VALID_PHONE.MESSAGE,
      YupValidationTest.IS_VALID_PHONE.TEST,
    ),
});
const validationCodeSchema = Yup.object({
  code: Yup.string()
    .required('Required')
    .matches(/^[0-9]{7}$/, 'Must be exactly 7 digits'),
});

type PhoneValues = {
  phone?: string;
};
type CodeValues = {
  code?: string;
};

const initialPhoneValues: PhoneValues = {
  phone: '+61',
};
const initialCodeValues: CodeValues = {
  code: '',
};

const VerifyPhoneSignUp = () => {
  const [securePhoneNumber, setSecurePhoneNum] = useState('');
  const { counter, resetCounter } = useCounter(-1);
  const { mutate: mutateSendSMS, isLoading: isLoadingSendSMS } = useSendSMS();
  const user = useSelector(getUser);
  const navigate = useNavigate();
  useEffect(() => {
    if (!!user?.phone) {
      navigate('/');
    }
  }, [navigate, user]);
  const {
    mutate: mutateVerifyCodeAndUpdatePhoneInfo,
    isLoading: isLoadingVerifyCode,
  } = useVerifyCodeAndUpdatePhoneInfo();
  const onSubmitPhone = (values: any) => {
    const phone = values?.phone?.replaceAll(' ', '') ?? '';
    mutateSendSMS(
      { phone },
      {
        onSuccess: () => {
          setSecurePhoneNum(phone);
        },
      },
    );
  };

  const hiddenPhoneNumbers = useMemo(() => {
    return !!securePhoneNumber
      ? securePhoneNumber.slice(-4).padStart(securePhoneNumber.length, '•')
      : '';
  }, [securePhoneNumber]);

  const onSubmitCode = (values: any, formikBag: any) => {
    mutateVerifyCodeAndUpdatePhoneInfo(values, {
      onError: (error: any) => {
        const errorMessage =
          error?.response?.data?.message ??
          error?.message ??
          commonConstants.SOMETHING_WENT_WRONG;
        formikBag?.setFieldError('code', errorMessage);
      },
    });
  };

  const handleResend = () => {
    mutateSendSMS(
      { phone: securePhoneNumber },
      {
        onSuccess: () => {
          resetCounter();
        },
      },
    );
  };

  const isLoading = isLoadingSendSMS || isLoadingVerifyCode;

  return (
    <Formik
      initialValues={
        !!securePhoneNumber ? initialCodeValues : initialPhoneValues
      }
      validationSchema={
        !!securePhoneNumber ? validationCodeSchema : validationPhoneSchema
      }
      onSubmit={!!securePhoneNumber ? onSubmitCode : onSubmitPhone}
      enableReinitialize={true}
    >
      {({ handleSubmit }) => (
        <>
          <Form>
            <Helmet>
              <title>Verify Phone Number</title>
              <meta name="description" content="iRefer Verify Phone Number" />
            </Helmet>
            <StyledBox
              sx={{
                padding: '52px',
                display: 'flex',
                alignItems: 'center',
                position: 'relative',
              }}
            >
              <StyledStack
                sx={{
                  gap: 3,
                  height: '100%!important',
                }}
              >
                <Stack alignItems="center" mb={2}>
                  <Logo
                    alt="IReferLogo"
                    src={LogoIReferIcon}
                    style={{ width: '104px' }}
                  />
                </Stack>
                <CommonTitleText>
                  {!!securePhoneNumber
                    ? 'PLEASE ENTER VERIFICATION CODE'
                    : 'LET’S KEEP YOUR ACCOUNT SECURE!'}
                </CommonTitleText>
                <StyledStack sx={{ gap: 0 }}>
                  <CommonSubtext>
                    {!!securePhoneNumber
                      ? `We have texted a unique 7 digit code to your mobile number ${hiddenPhoneNumbers}. Please enter this code in the field below to continue.`
                      : 'Please enter your mobile number to receive your secure authentication code.'}
                  </CommonSubtext>
                </StyledStack>

                {!!securePhoneNumber ? (
                  <FormikControl
                    control={CONTROL_TYPE.INPUT}
                    name="code"
                    label="7 Digit code from SMS"
                    placeholder="7 Digit code from SMS"
                    maxLength={7}
                  />
                ) : (
                  <FormikControl control={CONTROL_TYPE.PHONE} name="phone" />
                )}

                <StyledStack direction="row" justifyContent="center">
                  <CommonSmallSubtext>
                    Your phone number won't be shared publicly
                  </CommonSmallSubtext>
                </StyledStack>

                <StyledStack sx={{ gap: 0 }}>
                  <StyledLoadingButton
                    loading={isLoading}
                    variant="contained"
                    onClick={() => {
                      handleSubmit();
                    }}
                    sx={{ marginBottom: '1.2rem' }}
                  >
                    Continue
                  </StyledLoadingButton>

                  {!!securePhoneNumber ? (
                    counter > -1 ? (
                      <CommonSubtext
                        sx={{
                          color: themes.light.colorMaximumBlueGreen,
                          fontWeight: 500,
                        }}
                      >
                        Resend code {counter}s
                      </CommonSubtext>
                    ) : (
                      <TextButton
                        disabled={isLoading}
                        onClick={() => handleResend()}
                      >
                        Resend
                      </TextButton>
                    )
                  ) : null}
                </StyledStack>
              </StyledStack>
            </StyledBox>
          </Form>
        </>
      )}
    </Formik>
  );
};

export default VerifyPhoneSignUp;
