import { Stack } from '@mui/material';
import { useLoadScript } from '@react-google-maps/api';
import { Form, FormikProvider, useFormik } from 'formik';
import { useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import LogoIReferIcon from '../../../assets/images/icon-logo-irefer-trademark.png';
import LoadingContent from '../../../components/Common/LoadingContent/LoadingContent';
import { StyledBox } from '../../../components/Common/Styled/CommonStyled';
import {
  CommonSubtext,
  CommonTitleText,
} from '../../../components/Common/Styled/TypographyStyed';
import FormikControl, {
  CONTROL_TYPE,
} from '../../../components/Formik/FormikControl';
import { useUpdateVendorInfo } from '../../../hooks/Auth/useAuth';
import { getUser } from '../../../redux/selectors/auth.selector';
import {
  Logo,
  StyledLoadingButton,
  StyledStack,
  // TextButton,
} from '../AuthStyled';

export type ValuesYourBusinessAddressType = {
  address: {
    line1: string;
    line2: string;
    city: string;
    state: string;
    stateShortVersion: string;
    postal_code: string;
    country: string;
    countryShortVersion: string;
    lat: number;
    lng: number;
  };
};

const validationSchema = Yup.object({
  address: Yup.object({
    line1: Yup.string().required('Required'),
    line2: Yup.string().notRequired(),
    city: Yup.string().required('Required'),
    state: Yup.string().required('Required'),
    stateShortVersion: Yup.string().required('Required'),
    postal_code: Yup.string().notRequired(),
    country: Yup.string().required('Required'),
    countryShortVersion: Yup.string().required('Required'),
    lat: Yup.number(),
    lng: Yup.number(),
  }),
});

const initialValues: ValuesYourBusinessAddressType = {
  address: {
    line1: '',
    line2: '',
    city: '',
    state: '',
    stateShortVersion: '',
    postal_code: '',
    country: '',
    countryShortVersion: '',
    lat: 0,
    lng: 0,
  },
};

const libraries = ['places'];

const YourBusinessAddress = () => {
  const navigate = useNavigate();
  const user = useSelector(getUser);
  const {
    mutate: mutateUpdateVendorInfo,
    isLoading: isLoadingUpdateVendorInfo,
  } = useUpdateVendorInfo();

  useEffect(() => {
    if (user?.vendor?.vendor_address?.hq_address) {
      navigate('/');
    }
  }, [navigate, user]);

  const onSubmit = (values: ValuesYourBusinessAddressType) => {
    const address = {
      hq_address: {
        ...values.address,
      },
      branches: [],
    };
    mutateUpdateVendorInfo(
      { address },
      {
        onSuccess: () => {
          onHandleComplete();
        },
      },
    );
  };

  const onHandleComplete = () => {
    navigate('/auth/upload-company-logo', {
      state: { isInitialUploadLogo: true },
    });
  };

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY || '',
    // @ts-ignore
    libraries,
  });

  const isLoading = useMemo(
    () => !isLoaded || isLoadingUpdateVendorInfo,
    [isLoaded, isLoadingUpdateVendorInfo],
  );

  const formik = useFormik({
    onSubmit,
    validationSchema,
    initialValues,
  });

  const { handleSubmit, isValid, dirty, values, setValues } = formik;

  const onHandleSetFormikValue = async (newValues: any) => {
    if (newValues) {
      const val: ValuesYourBusinessAddressType = {
        address: {
          line1: newValues?.line1 as string,
          line2: values?.address.line2 as string,
          city: newValues?.city as string,
          state: newValues?.state as string,
          stateShortVersion: newValues?.city as string,
          postal_code: newValues?.postal_code as string,
          country: newValues?.country as string,
          countryShortVersion: newValues?.countryShortVersion as string,
          lat: newValues?.lat as number,
          lng: newValues?.lng as number,
        },
      };

      if (!newValues?.line1) {
        val.address.line2 = newValues?.line1;
      }
      setValues(val);
    }
  };

  return (
    <FormikProvider value={formik}>
      <Form>
        <Helmet>
          <title>Your Business Address</title>
          <meta name="description" content="iRefer Add Your Business Address" />
        </Helmet>
        <StyledBox
          sx={{
            padding: '52px',
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            position: 'relative',
          }}
        >
          {isLoading ? (
            <LoadingContent text="Just a moment..." />
          ) : (
            <StyledStack
              sx={{
                gap: 2,
                height: '100%!important',
              }}
            >
              <Stack alignItems="center">
                <Logo
                  alt="iReferLogo"
                  src={LogoIReferIcon}
                  style={{ width: '104px' }}
                />
              </Stack>
              <CommonTitleText>ADD YOUR BUSINESS ADDRESS</CommonTitleText>
              <CommonSubtext sx={{ width: 500 }} mb={2}>
                Please add the address of your business HQ. If you are an online
                business you may skip this step.
              </CommonSubtext>
              <FormikControl
                control={CONTROL_TYPE.SEARCH_ADDRESS}
                name="address.line1"
                label="Address line 1"
                onHandleSetFormikValue={onHandleSetFormikValue}
              />
              <FormikControl
                control={CONTROL_TYPE.INPUT}
                name="address.line2"
                label="Address line 2 (Optional)"
                InputLabelProps={{ shrink: true }}
              />
              <FormikControl
                control={CONTROL_TYPE.INPUT}
                name="address.city"
                label="City"
                disabled
                InputLabelProps={{ shrink: true }}
              />
              <FormikControl
                control={CONTROL_TYPE.INPUT}
                name="address.state"
                label="State"
                disabled
                InputLabelProps={{ shrink: true }}
              />
              <FormikControl
                control={CONTROL_TYPE.INPUT}
                name="address.postal_code"
                label="Postal code"
                disabled
                InputLabelProps={{ shrink: true }}
              />
              <FormikControl
                control={CONTROL_TYPE.INPUT}
                name="address.country"
                label="Country"
                disabled
                InputLabelProps={{ shrink: true }}
              />
              <StyledStack sx={{ gap: 3 }}>
                <StyledLoadingButton
                  loading={false}
                  disabled={!isValid && dirty}
                  variant="contained"
                  onClick={() => {
                    handleSubmit();
                  }}
                  sx={{ fontSize: '1rem' }}
                >
                  Next
                </StyledLoadingButton>
                {/* <TextButton disabled={false} onClick={() => onHandleComplete()}>
                  Skip
                </TextButton> */}
              </StyledStack>
            </StyledStack>
          )}
        </StyledBox>
      </Form>
    </FormikProvider>
  );
};

export default YourBusinessAddress;
