import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from '../../redux/selectors/auth.selector';
import getQueryKey, {
  GET_LINKED_BANK_INFO_V2,
  GET_VENDOR_CARD_DETAILS,
} from '../../utils/get-query-key';
import {
  UpdatePaymentsAPIProps,
  UpdateVendorURL_APIProps,
  createStripeAccountAPI,
  getBankInfoAPI,
  sendSMSCodeAPI,
  updateVendorURL_API,
  validateBankInfoAPI,
} from './infoAPI';
import { getUserInfoAPI } from 'hooks/Auth/authAPI';
import produce from 'immer';
import { AppDispatch } from 'redux/store';
import { toast } from 'react-toastify';
import ToastMessage, {
  ToastType,
} from 'components/Common/ToastMessage/ToastMessage';
import { login } from 'redux/slices/authSlice';
import commonConstants from 'constants/common.constant';
import { expiredTimeSMSCodeAtom } from 'pages/Auth/UpdateWebsiteUrl/UpdateWebsiteURLStep2';
import { useSetAtom } from 'jotai';
import moment from 'moment';
import useAxios from 'hooks/Common/useAxios';

export type UpdateBankInfoPayload = {
  user_id?: string;
  country?: string;
  first_name?: string;
  last_name?: string;
  dob?: any;
  url?: string;
  address?: object;
  bsb?: string;
  account_number?: string;
  document?: object;
};

// validate info when link bank account then save
export const useSendBankInfo = (
  TYPE: typeof commonConstants.BANK_ACCOUNT_TYPE[keyof typeof commonConstants.BANK_ACCOUNT_TYPE],
) => {
  const user = useSelector(getUser);
  return useMutation((payload: any) => {
    const apiPayload = {
      user_id: user?.id,
      business_type: TYPE,
      ...payload,
    };
    return validateBankInfoAPI(apiPayload);
  });
};

export const useGetBankInfo = () => {
  const user = useSelector(getUser);
  return useQuery(
    getQueryKey.getLinkedBankInfo(user?.id),
    () => getBankInfoAPI(user?.id ?? ''),
    {
      onError: (error: any) => {
        console.log(error.message);
      },
    },
  );
};

export const useGetBankInfoV2 = () => {
  const axios = useAxios();
  return useQuery(
    getQueryKey.getLinkedBankInfoV2(),
    async () => {
      const response = await axios.get('/stripe/stripe-onboarding-status');
      if (response?.status === 200 && response?.data?.status) {
        return response?.data as {
          status: ValueOf<typeof commonConstants.STRIPE_STATUS_TYPE>;
          url?: string;
        };
      }
      throw new Error('Something went wrong.');
    },
    {
      onError: (error: any) => {
        console.log(error.message);
      },
    },
  );
};

export const useCreateStripeAccount = () => {
  const user = useSelector(getUser);
  return useMutation({
    mutationFn: (
      businessType: ValueOf<typeof commonConstants.BANK_ACCOUNT_TYPE>,
    ) =>
      createStripeAccountAPI({
        user_id: user?.id || '',
        action: commonConstants.LINK_BANK_ACCOUNT_ACTION.CREATE_STRIPE_ACCOUNT,
        business_type: businessType,
      }),
  });
};

export const useCreateStripeAccountV2 = () => {
  const queryClient = useQueryClient();
  const axios = useAxios();
  return useMutation({
    mutationFn: async () => {
      const response = await axios.get('/stripe/onboarding-link');
      if (response?.status === 200 && response?.data?.url) {
        return { onboardingLink: response?.data?.url };
      }
      throw new Error('Something went wrong.');
    },
    onSuccess: () => {
      queryClient.invalidateQueries([GET_LINKED_BANK_INFO_V2]);
    },
  });
};

export const useUpdatePayment = () => {
  const queryClient = useQueryClient();
  const axios = useAxios();
  return useMutation(
    async (payload: UpdatePaymentsAPIProps) =>
      axios.post('/stripe/add-refund-card', payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([GET_VENDOR_CARD_DETAILS]);
        toast(
          <ToastMessage
            text="Card Successfully Linked"
            type={ToastType.SUCCESS.type}
          />,
        );
      },
    },
  );
};

export const useUpdateVendorURL = () => {
  const user = useSelector(getUser);
  const dispatch = useDispatch<AppDispatch>();
  return useMutation(
    (payload: UpdateVendorURL_APIProps) => {
      const payloadWithId = produce(
        payload,
        (draft: UpdateVendorURL_APIProps) => {
          draft.vendor_id = user?.vendor._id;
          draft.user_id = user?.id;
        },
      );
      return updateVendorURL_API(payloadWithId);
    },
    {
      onSuccess: async () => {
        toast(
          <ToastMessage
            text="You have been successfully updated store URL."
            type={ToastType.SUCCESS.type}
          />,
        );
        const response = await getUserInfoAPI(user?.email ?? '');
        dispatch(login(response?.data));
      },
    },
  );
};

export const useSendSMSCode = () => {
  const setExpiredTimeSMSCode = useSetAtom(expiredTimeSMSCodeAtom);
  const user = useSelector(getUser);
  return useMutation(
    async () => {
      await sendSMSCodeAPI(user?.id ?? '');
    },
    {
      onSuccess: () => {
        toast(
          <ToastMessage
            text="We've sent the code."
            type={ToastType.SUCCESS.type}
          />,
        );
        const currentTime = moment(new Date());
        setExpiredTimeSMSCode(currentTime.add(2, 'm').toDate().getTime());
      },
    },
  );
};
