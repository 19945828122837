import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AddressType } from 'components/Formik/SearchAddress';

export type Vendor = {
  _id: string;
  is_approved: boolean;
  first_name?: string;
  last_name?: string;
  business_name?: string;
  abn?: string;
  year_of_incorporation?: string;
  domain?: string;
  business_category?: string;
  logo?: string;
  vendor_address?: {
    hq_address?: AddressType;
    branches?: AddressType[];
  };
};

export type User = {
  cog_id: string;
  email: string;
  role: string;
  phone: string;
  id: string;
  is_2fa: boolean;
  isSocialLogin: boolean;
  vendor: Vendor;
  checkoutInfo?: {
    paymentInfo?: {
      brand: string;
      last4: string;
    };
  };
};

type AuthState = {
  isAuthenticatedVendorUser: boolean;
  user: User | null;
};

const initialState: AuthState = {
  isAuthenticatedVendorUser: false,
  user: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (state, action: PayloadAction<User>) => {
      state.isAuthenticatedVendorUser = true;
      state.user = action.payload;
    },
    logout: (state) => {
      state.isAuthenticatedVendorUser = false;
      state.user = null;
    },
  },
});

export const { login, logout } = authSlice.actions;

export default authSlice.reducer;
