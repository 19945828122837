import { TotalPerformanceItemType } from '../../../hooks/TotalPerformance/useTotalPerformance';
import { Text16Weight600 } from '../Styled/TypographyStyed';
import { TotalPerformanceItem } from './TotalPerformanceItem';
import {
  GridContainer,
  TotalPerformanceContainer,
  TotalPerformanceHeader,
  TotalPerformanceHeaderText,
} from './TotalPerformanceStyled';
import { Grid, Skeleton, Box, styled } from '@mui/material';

const ContainerGridBox = styled(Box)({
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fit, minmax(min(22rem, 100%), 1fr))',
  gap: '16px',
});

type Props = {
  headerText: string;
  data: TotalPerformanceItemType[];
  isLoading?: boolean;
};

export function TotalPerformance({
  headerText,
  data,
  isLoading,
}: Readonly<Props>): JSX.Element {
  let renderData = (
    <GridContainer container>
      <Text16Weight600>No Results</Text16Weight600>
    </GridContainer>
  );
  if (isLoading) {
    renderData = (
      <Grid container spacing={2} padding={2}>
        {Array.from({ length: 4 }, (index) => index).map((_, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <Skeleton variant="rounded" width="100%" height={84} />
          </Grid>
        ))}
      </Grid>
    );
  }
  if (data?.length > 0) {
    renderData = (
      <ContainerGridBox>
        {data.map(
          (
            { caption, main, footer, icon, isCurrency, isPercentage, sub_main },
            index,
          ) => (
            <TotalPerformanceItem
              key={index}
              caption={caption}
              main={main}
              footer={footer}
              icon={icon}
              isCurrency={isCurrency}
              isPercentage={isPercentage}
              sub_main={sub_main}
            />
          ),
        )}
      </ContainerGridBox>
    );
  }
  return (
    <TotalPerformanceContainer>
      <TotalPerformanceHeader>
        <TotalPerformanceHeaderText>{headerText}</TotalPerformanceHeaderText>
      </TotalPerformanceHeader>
      {renderData}
    </TotalPerformanceContainer>
  );
}
