import * as React from 'react';

type Props = {
  width?: number;
  height?: number;
};

export function SuccessIcon({ width = 84, height = 85 }: Props): JSX.Element {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 84 85"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M84 42.8794C84 66.0754 65.196 84.8794 42 84.8794C18.804 84.8794 0 66.0754 0 42.8794C0 19.6834 18.804 0.879395 42 0.879395C65.196 0.879395 84 19.6834 84 42.8794ZM4.62 42.8794C4.62 63.5238 21.3556 80.2594 42 80.2594C62.6444 80.2594 79.38 63.5238 79.38 42.8794C79.38 22.235 62.6444 5.49939 42 5.49939C21.3556 5.49939 4.62 22.235 4.62 42.8794Z"
        fill="black"
      />
      <g clipPath="url(#clip0_9146_18398)">
        <path
          d="M35.9998 51.2194L27.6598 42.8794L24.8198 45.6994L35.9998 56.8794L59.9998 32.8794L57.1798 30.0594L35.9998 51.2194Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_9146_18398">
          <rect
            width="48"
            height="48"
            fill="white"
            transform="translate(18 18.8794)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
