import CheckBox from './Checkbox';
import CountrySelect from './CountrySelection';
import Input from './Input';
import NumberFormatInput from './NumberFormatInput';
import Password from './Password';
import Phone from './Phone';
import SearchAddress from './SearchAddress';
import Select from './Select';
import Textarea from './Textarea';

export const CONTROL_TYPE = {
  INPUT: 'input',
  PASSWORD: 'password',
  TEXTAREA: 'textarea',
  CHECK_BOX: 'checkbox',
  SELECT: 'select',
  PHONE: 'phone',
  NUMBER_FORMAT_INPUT: 'numberFormatInput',
  COUNTRY_SELECT: 'countrySelect',
  SEARCH_ADDRESS: 'searchAddress',
} as const;

type FormikControlProps = {
  control: typeof CONTROL_TYPE[keyof typeof CONTROL_TYPE];
  name: string;
  [key: string]: any;
};

const FormikControl = (props: FormikControlProps) => {
  const { control, ...rest } = props;
  switch (control) {
    case CONTROL_TYPE.INPUT:
      return <Input {...rest} />;
    case CONTROL_TYPE.PASSWORD:
      return <Password {...rest} />;
    case CONTROL_TYPE.SELECT:
      return <Select {...rest} />;
    case CONTROL_TYPE.TEXTAREA:
      return <Textarea {...rest} />;
    case CONTROL_TYPE.PHONE:
      return <Phone {...rest} />;
    case CONTROL_TYPE.NUMBER_FORMAT_INPUT:
      return <NumberFormatInput {...rest} />;
    case CONTROL_TYPE.CHECK_BOX:
      return <CheckBox {...rest} />;
    case CONTROL_TYPE.COUNTRY_SELECT:
      return <CountrySelect {...rest} />;
    case CONTROL_TYPE.SEARCH_ADDRESS:
      return <SearchAddress {...rest} />;
    default:
      return null;
  }
};

export default FormikControl;
