import { Button, Container, Grid, Stack, Tooltip } from '@mui/material';
import {
  Text18Weight500,
  Text32ChineseBlack,
} from 'components/Common/Styled/TypographyStyed';
import WoocommerceLogo from '../../assets/images/woocommerce-logo.svg';
import ShopifyLogo from '../../assets/images/shopify-logo.svg';
import WixLogo from '../../assets/images/wix-logo.svg';
import MagentoLogo from '../../assets/images/magento-logo.svg';
import BigcommerceLogo from '../../assets/images/bigcommerce-logo.svg';
import SquarespaceLogo from '../../assets/images/squarespace-logo.svg';
import SquareOnlineLogo from '../../assets/images/squareonline-logo.svg';
import OpenCartLogo from '../../assets/images/opencart-logo.svg';
import Cafe24Logo from '../../assets/images/cafe24-logo.png';
import SalesforceLogo from '../../assets/images/salesforce-logo.svg';
import CustomCartLogo from '../../assets/images/customcart-logo.png';

const PLUGINS = [
  {
    name: 'WooCommerce',
    logo: WoocommerceLogo,
    disabled: false,
    url: 'https://wordpress.org/plugins/i-refer/',
  },
  {
    name: 'Shopify',
    logo: ShopifyLogo,
    disabled: true,
  },
  {
    name: 'Custom Cart',
    logo: CustomCartLogo,
    disabled: true,
  },
  {
    name: 'Wix',
    logo: WixLogo,
    disabled: true,
  },
  {
    name: 'Magento',
    logo: MagentoLogo,
    disabled: true,
  },
  {
    name: 'Salesforce',
    logo: SalesforceLogo,
    disabled: true,
    height: 100,
  },
  {
    name: 'Bigcommerce',
    logo: BigcommerceLogo,
    disabled: true,
  },
  {
    name: 'Squarespace',
    logo: SquarespaceLogo,
    disabled: true,
    additionalText: 'SQUARESPACE',
  },
  {
    name: 'Square Online',
    logo: SquareOnlineLogo,
    disabled: true,
    additionalText: 'SQUARE',
  },
  {
    name: 'Open Cart',
    logo: OpenCartLogo,
    disabled: true,
  },
  {
    name: 'Cafe24',
    logo: Cafe24Logo,
    disabled: true,
  },
];

const APIPlugins = () => {
  return (
    <Container maxWidth="lg" sx={{ py: 2 }}>
      <Stack gap={5}>
        <Stack gap={2}>
          <Stack direction="row" justifyContent="center">
            <Text32ChineseBlack sx={{ textAlign: 'center' }}>
              Supported platforms
            </Text32ChineseBlack>
          </Stack>
          <Stack direction="row" justifyContent="center">
            <Text18Weight500 sx={{ textAlign: 'center' }}>
              Choose the platform on which you are running your store to proceed
              with the setup
            </Text18Weight500>
          </Stack>
        </Stack>
        <Grid container spacing={2}>
          {PLUGINS.map((plugin) => {
            const content = (
              <Button
                variant="outlined"
                sx={{
                  width: '100%',
                  minHeight: 106,
                  '& > img': { height: plugin.height ?? 40 },
                  backgroundColor: plugin.disabled ? 'gray' : 'inherit',
                  opacity: plugin.disabled ? 0.5 : 1,
                }}
                disabled={plugin.disabled}
                onClick={() => plugin.url && window.open(plugin.url, '_blank')}
              >
                <img src={plugin.logo} alt={plugin.name} />
                {plugin.additionalText ? (
                  <Text18Weight500 sx={{ ml: 1 }}>
                    {plugin.additionalText}
                  </Text18Weight500>
                ) : null}
              </Button>
            );
            return (
              <Grid item xs={12} md={4} key={plugin.name}>
                {plugin.disabled ? (
                  <Tooltip title="Coming soon..." arrow>
                    <span>{content}</span>
                  </Tooltip>
                ) : (
                  content
                )}
              </Grid>
            );
          })}
        </Grid>
      </Stack>
    </Container>
  );
};

export default APIPlugins;
