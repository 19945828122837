import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import commonConstants from 'constants/common.constant';
import { useDebounce } from 'hooks/Common/useDebounce';
import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from 'redux/selectors/auth.selector';
import { getTransactionDashboardFilterProperties } from 'redux/selectors/transactions.selector';
import {
  PROPERTY_KEY,
  setProperties,
  setProperty,
} from 'redux/slices/transactionsSlice';
import getQueryKey, { GET_VENDOR_CARD_DETAILS } from 'utils/get-query-key';
import formatUtil from 'utils/format.util';
import { useNavigate } from 'react-router-dom';
import useAxios from 'hooks/Common/useAxios';

type TransactionCommonType = {
  customer_email: string;
  transaction_id: string;
  date: string;
  total: number;
  referrer: {
    email: string;
    payout?: number;
    referral_link?: string;
  };
  transaction_fee: number;
  is_refundable: boolean;
};

export type TransactionListItemResponse = {
  vendor: {
    business_name: string;
    payout: number;
  };
} & TransactionCommonType;

type TransactionListResponse = {
  data: TransactionListItemResponse[];
  limit: string;
  page: string;
  total: number;
};

export type SplitType = {
  referrer_fee: number;
  referrer_status_text?: string;
  transaction_fee: number;
  vendor_status_text?: string;
  vendor_net: number;
};

export const REFUND_STATUS = {
  REFUNDED: 'refunded',
  PENDING: 'pending',
  REFUNDING: 'refunding',
} as const;

export type RefundType = {
  amount: number;
  created_at?: string;
  status: ValueOf<typeof REFUND_STATUS>;
};

export type TransactionDetailsResponse = {
  orderItemDetails: {
    img: string;
    title: string;
    price: number;
    quantity: number;
  }[];
  splits: SplitType;
  refund?: RefundType;
} & TransactionCommonType;

export const useGetTransactions = () => {
  const axios = useAxios();
  const dispatch = useDispatch();
  const { page, limit, keyword, direction, filter, dateFrom, dateTo } =
    useSelector(getTransactionDashboardFilterProperties);
  const queryClient = useQueryClient();
  const user = useSelector(getUser);
  const returnQueryParams = useCallback(
    (isPrefetch: boolean = false) => {
      return {
        page: isPrefetch ? page + 1 : page,
        limit: limit,
        keyword,
        direction,
        filter,
        userId: user?.id ?? '',
        ...(dateFrom ? { dateFrom } : {}),
        ...(dateTo ? { dateTo } : {}),
      };
    },
    [page, limit, keyword, direction, filter, user?.id, dateFrom, dateTo],
  );

  const handleChangeDateRange = ({
    dateFrom,
    dateTo,
  }: {
    dateFrom: string;
    dateTo: string;
  }) => {
    dispatch(
      setProperties([
        { type: PROPERTY_KEY.DATE_FROM, value: dateFrom },
        { type: PROPERTY_KEY.DATE_TO, value: dateTo },
        {
          type: PROPERTY_KEY.PAGE,
          value:
            commonConstants.TRANSACTIONS_DASHBOARD_DEFAULT_QUERY_PARAMS.page,
        },
      ]),
    );
  };

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    newPage: number,
  ) => {
    dispatch(setProperty({ type: PROPERTY_KEY.PAGE, value: newPage }));
  };
  const handleDebounceFn = useCallback(
    (inputValue: string) => {
      dispatch(
        setProperties([
          { type: PROPERTY_KEY.KEYWORD, value: inputValue },
          {
            type: PROPERTY_KEY.PAGE,
            value:
              commonConstants.TRANSACTIONS_DASHBOARD_DEFAULT_QUERY_PARAMS.page,
          },
        ]),
      );
    },
    [dispatch],
  );
  const onHandleChangeKeyword = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleChangeInput(e.target.value);
  };
  const handleChangeInput = useDebounce(handleDebounceFn);

  const query = useQuery(
    getQueryKey.transactionsDashboardQueryKey(returnQueryParams()),
    () => {
      return axios.get('/vendors/transactions', {
        params: returnQueryParams(),
      });
    },
    {
      select: (result) => result?.data as TransactionListResponse,
      keepPreviousData: true,
      staleTime: commonConstants.STALE_TIME.MIN_1, // 1m
    },
  );
  const totalPage = useMemo(() => {
    return formatUtil.parseTotalPage({
      data: query?.data,
      page: commonConstants.TRANSACTIONS_DASHBOARD_DEFAULT_QUERY_PARAMS.page,
      limit: commonConstants.TRANSACTIONS_DASHBOARD_DEFAULT_QUERY_PARAMS.limit,
    });
  }, [query?.data]);

  useEffect(() => {
    if (page <= totalPage - 1) {
      queryClient.prefetchQuery(
        getQueryKey.transactionsDashboardQueryKey(returnQueryParams(true)),
        () =>
          axios.get('/vendors/transactions', {
            params: returnQueryParams(true),
          }),
      );
    }
  }, [
    page,
    keyword,
    direction,
    queryClient,
    returnQueryParams,
    totalPage,
    filter,
    axios,
  ]);

  return {
    query,
    totalPage,
    page,
    handleChangePage,
    onHandleChangeKeyword,
    handleChangeDateRange,
    filter,
    keyword,
    dateFrom,
    dateTo,
  };
};

export const usePrefetchTransactionDetail = () => {
  const axios = useAxios();
  const queryClient = useQueryClient();
  return (transactionId: string) => {
    return queryClient.prefetchQuery(
      getQueryKey.getTransactionDetail(transactionId),
      () => axios.get(`/vendors/transactions/${transactionId}`),
      {
        staleTime:
          commonConstants.STALE_TIME
            .SEC_20 /* only pre-fetch if older than 20 seconds */,
      },
    );
  };
};

export const useGetTransactionDetail = ({
  transactionId,
}: {
  transactionId: string;
}) => {
  const axios = useAxios();
  const navigate = useNavigate();

  return useQuery(
    getQueryKey.getTransactionDetail(transactionId),
    async () => {
      const response = await axios.get(
        `/vendors/transactions/${transactionId}`,
      );
      if (!response?.data) {
        throw new Error('No Data');
      }
      return response;
    },
    {
      select: (response) => response?.data as TransactionDetailsResponse,
      staleTime: commonConstants.STALE_TIME.SEC_20 /* 20 secs */,
      onError: () => navigate('/invalid-link'),
    },
  );
};

export type RefundProcessPayloadType = {
  transactionId: string;
  amountToRefundCents: number;
  paymentMethodId: string;
  reason: string;
  password: string;
};

export const useRefundProcess = (transactionId: string) => {
  const axios = useAxios();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (payload: RefundProcessPayloadType) =>
      axios.post('/stripe/refund', payload),
    onSettled: () => {
      queryClient.invalidateQueries(
        getQueryKey.getTransactionDetail(transactionId),
      );
    },
  });
};

export const useGetStripeAccountId = () => {
  const axios = useAxios();
  const user = useSelector(getUser);
  return useQuery(
    getQueryKey.getStripeAccountId(user?.id ?? ''),
    async () => {
      const response = await axios.get('/vendors/get-stripe-account-id');
      if (!response?.data) {
        throw new Error('No Data');
      }
      return response;
    },
    {
      select: (response) => response?.data as { stripeAccountId: string },
      staleTime: commonConstants.STALE_TIME.SEC_20 /* 20 secs */,
    },
  );
};

export const useGetStripeSetupIntent = () => {
  const axios = useAxios();
  const user = useSelector(getUser);
  return useQuery(
    getQueryKey.getStripeSetupIntent(user?.id ?? ''),
    async () => {
      const response = await axios.get('/stripe/create-get-vendor-as-customer');
      if (!response?.data) {
        throw new Error('No Data');
      }
      return response;
    },
    {
      select: (response) =>
        response?.data as { customerId: string; clientSecret: string },
      staleTime: commonConstants.STALE_TIME.SEC_20 /* 20 secs */,
    },
  );
};

export type PaymentMethod = {
  last4: string;
  brand: string;
  source_id: string;
};

export const useGetVendorCardDetails = () => {
  const axios = useAxios();
  const user = useSelector(getUser);
  return useQuery(
    getQueryKey.getVendorCardDetails(user?.id ?? ''),
    async () => {
      const response = await axios.get('/stripe/get-vendor-card');
      if (!response?.data) {
        throw new Error('No Data');
      }
      return response;
    },
    {
      select: (response) =>
        response?.data as {
          cardDetails: {
            paymentMethods: PaymentMethod[] | null;
          };
        },
    },
  );
};

export const useRemovePaymentMethod = () => {
  const axios = useAxios();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (payload: { paymentMethodId: string }) =>
      axios.post('/stripe/delete-payment-method', payload),
    onSettled: () => {
      queryClient.invalidateQueries([GET_VENDOR_CARD_DETAILS]);
    },
  });
};
