import { CircularProgress, Grid } from '@mui/material';
import parse from 'html-react-parser';
import { useNavigate, useParams } from 'react-router-dom';

import AvatarWithFallback from '../../components/Common/AvatarWithFallback/AvatarWithFallback';
import { TotalPerformance } from '../../components/Common/TotalPerformance';
import commonConstants from '../../constants/common.constant';
import { usePrefetchReferrerDetail } from '../../hooks/Referrers/useReferrers';
import { useGetReferralDetails } from '../../hooks/Referrals/useReferrals';
import formatUtil from '../../utils/format.util';
import {
  BackDropContainer,
  ButtonGroup,
  Caption,
  Container,
  DetailsContainer,
  ImageSection,
  Label,
  PrimaryButton,
  Profile,
  ProfileAndDate,
  StyledStack,
  Subtitle,
  Title,
} from './ReferralDetailStyled';

export function ReferralDetail(): JSX.Element {
  const navigate = useNavigate();
  let { referralId } = useParams();

  const { data, isLoading } = useGetReferralDetails({
    referralId: referralId ?? '',
  });
  const prefetchReferrerDetail = usePrefetchReferrerDetail();

  if (isLoading) {
    return (
      <BackDropContainer>
        <CircularProgress />
      </BackDropContainer>
    );
  }

  return (
    <Container>
      <StyledStack>
        <ImageSection>
          {/* {formattedData?.productionVideo ? (
            <ReactPlayer
              url={formattedData?.productionVideo}
              controls
              width="100%"
              height="400px"
              playing
              loop
              volume={0}
            />
          ) : ( */}
          <AvatarWithFallback
            image={data?.productDetails?.product_image ?? ''}
            avatarVariant="rounded"
            fromCDN
          />
          {/* )} */}
        </ImageSection>
        <Grid container sx={{ width: '100%' }}>
          <Grid item lg={9} xs={12}>
            <DetailsContainer>
              <Title>{data?.productDetails?.product_title ?? '--'}</Title>
              <Subtitle>{data?.productDetails?.store_url}</Subtitle>

              <DetailsContainer>
                <Caption>Description</Caption>
                {parse(data?.productDetails?.product_description ?? '')}
              </DetailsContainer>

              <ProfileAndDate>
                <Profile>
                  <AvatarWithFallback
                    image={data?.refereeData?.logo ?? ''}
                    height={48}
                    width={48}
                    avatarVariant="circular"
                    fromCDN
                    hasBorder
                  />
                  <DetailsContainer>
                    <Label>Referrer</Label>
                    <Caption>{`${data?.refereeData?.first_name} ${data?.refereeData?.last_name}`}</Caption>
                  </DetailsContainer>
                </Profile>

                <DetailsContainer>
                  <Label>Referral Date</Label>
                  <Caption>
                    {!!data?.productDetails?.created_at
                      ? formatUtil.formatDate({
                          date: data?.productDetails?.created_at,
                          designateFormat: commonConstants.DATE_FORMAT.MM_DD_YY,
                        })
                      : '--'}
                  </Caption>
                </DetailsContainer>
              </ProfileAndDate>

              <ButtonGroup>
                <PrimaryButton
                  onMouseEnter={() =>
                    prefetchReferrerDetail(data?.refereeData?.id ?? '')
                  }
                  onClick={() =>
                    navigate(`/referrer-profile/${data?.refereeData?.id ?? ''}`)
                  }
                >
                  View Profile
                </PrimaryButton>
                {/* <SecondaryButton>Message</SecondaryButton> */}
              </ButtonGroup>
            </DetailsContainer>
          </Grid>
        </Grid>
      </StyledStack>
      <TotalPerformance
        headerText="Sales Performance"
        data={data?.referralAnalytics?.salesPerformance ?? []}
      />
      <TotalPerformance
        headerText="Referral Performance"
        data={data?.referralAnalytics?.referralPerformance ?? []}
      />
    </Container>
  );
}
