import React from 'react';
import FormikControl, {
  CONTROL_TYPE,
} from '../../../components/Formik/FormikControl';
import { StyledLoadingButton, StyledStack } from '../AuthStyled';
import * as Yup from 'yup';
import { YupValidationTest } from '../../../utils/yup-validation-test.util';
import { useFormikContext } from 'formik';

export const validationSchemaLYBStep2 = Yup.object({
  business_name: Yup.string()
    .transform((val) => val.trim())
    .required('Required'),
  brand_name: Yup.string()
    .transform((val) => val.trim())
    .required('Required'),
  abn: Yup.string()
    .min(11, 'Must be exactly 11 digits')
    .max(11, 'Must be exactly 11 digits')
    .transform((val) => val.trim().replaceAll(' ', ''))
    .required('Required'),
  year_of_incorporation: Yup.string()
    .transform((val) => val.trim())
    .test(
      YupValidationTest.IS_VALID_YEAR.NAME,
      YupValidationTest.IS_VALID_YEAR.MESSAGE,
      YupValidationTest.IS_VALID_YEAR.TEST,
    )
    .required('Required'),
  domain: Yup.string()
    .transform((val) => val.trim())
    .matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      'Link should be a valid url.',
    )
    .required('Required'),
});

export type ValuesLYBStep2Type = {
  business_name: string;
  brand_name: string;
  abn: string;
  year_of_incorporation: string;
  domain: string;
};

export const initialValuesLYBStep2 = (values: Partial<ValuesLYBStep2Type>) => ({
  business_name: values?.business_name ?? '',
  brand_name: values?.brand_name ?? '',
  abn: values?.abn ?? '',
  year_of_incorporation: values?.year_of_incorporation ?? '',
  domain: values?.domain ?? '',
});

const LYBStep2 = () => {
  const { handleSubmit } = useFormikContext();
  return (
    <StyledStack
      sx={{
        gap: 3,
      }}
    >
      <FormikControl
        control={CONTROL_TYPE.INPUT}
        name="business_name"
        label="Business Name"
        maxLength={100}
        displayCheck
      />
      <FormikControl
        control={CONTROL_TYPE.INPUT}
        name="brand_name"
        label="Brand Name"
        maxLength={100}
        displayCheck
      />
      <FormikControl
        control={CONTROL_TYPE.NUMBER_FORMAT_INPUT}
        name="abn"
        label="ABN"
        format="## ### ### ###"
        displayCheck
      />
      <FormikControl
        control={CONTROL_TYPE.NUMBER_FORMAT_INPUT}
        name="year_of_incorporation"
        label="Year of incorporation"
        format="####"
        displayCheck
      />
      <FormikControl
        control={CONTROL_TYPE.INPUT}
        name="domain"
        label="E-commerce Website Domain"
        maxLength={200}
        displayCheck
      />
      <StyledLoadingButton onClick={() => handleSubmit()} variant="contained">
        Next
      </StyledLoadingButton>
    </StyledStack>
  );
};

export default LYBStep2;
