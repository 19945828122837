import { Box, Typography, Button, styled } from '@mui/material';

export const Container = styled(Box)`
  margin-top: 80px;
  border-radius: 4px;
  display: grid;
  grid-template-columns: 1fr;
  gap: 35px;

  & > .MuiPaper-root {
    box-shadow: 0px 0px 5px rgba(112, 129, 163, 0.102109);
  }
`;

export const HeaderButton = styled(Button)`
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #2cccd3;
  text-transform: unset;
`;

export const RowButton = styled(Button)`
  padding: 8px 10px;
  padding-right: 12px;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #2cccd3;
  border: 1px solid #2cccd3;
  border-radius: 4px;
  text-transform: unset;
`;

export const ProfileContainer = styled(Box)`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 17px;
  align-items: center;
`;

export const Avatar = styled(Box)`
  width: 49px;
  height: 49px;
  background-color: #e5e5e5;
  border-radius: 50%;
`;

export const ProfileName = styled(Typography)`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  ${(props) => props.theme.breakpoints.down('lg')} {
    width: 170px;
  }
`;

export const Caption = styled(Typography)`
  margin-top: 4px;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #646464;
  mix-blend-mode: normal;
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  opacity: 0.9;
  ${(props) => props.theme.breakpoints.down('lg')} {
    width: 170px;
  }
`;

export const Table = styled(Box)`
  background: #ffffff;
  box-shadow: 0px 0px 5px rgba(112, 129, 163, 0.102109);
  border-radius: 4px;
  min-width: 1000px;
`;

export const TableHeader = styled('ul')`
  padding: 12px 29px;
  list-style: none;
  display: grid;
  grid-template-columns: 27% 1fr 1fr 1fr 1fr 1fr 110px;
  align-items: center;
  box-shadow: inset 0px -1px 0px #dee7fa;
  border-radius: 8px 8px 0px 0px;
  font-family: Montserrat;

  & > li:first-of-type {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #313131;
  }

  & > li:not(:first-of-type) {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #646464;
  }

  & > li:last-of-type {
    justify-self: flex-end;

    & > .MuiButtonBase-root {
      margin-right: -8px;
    }
  }
`;

export const TableHeaderItem = styled('li')`
  display: flex;
  justify-content: flex-start;
`;

export const TableBody = styled('div')<{ enablePagination?: boolean }>(
  ({ enablePagination }) => ({
    padding: '0 24px',
    display: 'grid',
    gridTemplateColumns: '1fr',

    '& > ul:last-of-type': {
      boxShadow: enablePagination ? 'inset 0px -1px 0px #dee7fa' : 'unset',
    },
  }),
);

export const TableRow = styled('ul')`
  padding: 12px 0;
  display: grid;
  grid-template-columns: 27% 1fr 1fr 1fr 1fr 1fr 111px;
  align-items: center;
  box-shadow: inset 0px -1px 0px #dee7fa;
  font-family: Montserrat;
`;

export const SingleTableRow = styled(TableRow)`
  justify-items: center;
`;

export const TableRowItem = styled('li')`
  display: flex;
  justify-content: flex-start;
`;

export const TableActionsArea = styled(Box)`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 32px;
  align-items: center;
`;

export const SearchBox = styled(Box)`
  margin-left: 10px;
  padding: 0 16px;
  width: 343px;
  height: 49px;
  display: grid;
  grid-template-columns: auto 1fr;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  border: 1px solid #eceff1;
  box-shadow: 0px 0px 5px rgba(112, 129, 163, 0.102109);
  border-radius: 4px;

  & > .MuiInputBase-root {
    margin-left: 16px;
  }

  & > .MuiInputBase-root > .MuiInputBase-input {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #535f78;
  }
`;

export const FilterGroup = styled(Box)`
  display: flex;
  gap: 16px;
  align-items: center;
`;

export const FilterButton = styled(Button)`
  height: 41px;
  min-width: 120px;
  background: #ffffff;
  border: 1px solid #2cccd3;
  border-radius: 24px;
  font-family: Montserrat;
  font-weight: 400;
  font-size: 14px;
  text-transform: capitalize;
  line-height: 17px;
  text-align: center;
  color: #2cccd3;

  &:hover {
    background: #2cccd3;
    color: #ffffff;
  }
`;

export const PaginationArea = styled(Box)`
  padding: 24px;
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
`;

export const PaginationRow = styled(Box)`
  justify-self: end;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  & > .MuiButtonBase-root:has(svg) {
    margin-left: 8px;
    width: unset;
  }
`;

export const PaginationItem = styled(Button)`
  min-width: unset;
  width: 31px;
  height: 32px;
  border-radius: 4px;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 12px;
  text-transform: capitalize;
  line-height: 15px;
  color: #000000;

  &:hover {
    background: #f4f4f5;
  }
`;

export const PrevButton = styled(PaginationItem)`
  margin-right: 8px;
  width: unset;

  & > svg {
    margin-right: 8px;
  }
`;

export const NextButton = styled(PaginationItem)`
  margin-left: 8px;
  width: unset;

  & > svg {
    margin-left: 8px;
  }
`;
