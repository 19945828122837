import { Box, Typography, Button, styled, Grid } from '@mui/material';

export const Container = styled(Box)`
  padding: 80px 56px;
  font-family: 'Montserrat';
  min-height: calc(100% - 80px);
  /* Sales performance dashboard */
  & > .MuiBox-root:nth-of-type(2) {
    margin-top: 55px;
  }

  /* Referral performance dashboard */
  & > .MuiBox-root:nth-of-type(3) {
    margin-top: 32px;
  }

  /* Referrals table */
  & > .MuiBox-root:nth-of-type(4) {
    margin-top: 64px;
  }
  ${(props) => props.theme.breakpoints.down('sm')} {
    padding: 40px 30px;
  }
`;

export const BackDropContainer = styled(Container)({
  height: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

export const ProfileContainer = styled(Box)`
  padding: 40px 32px;
  background: #ffffff;
  box-shadow: 0px 0px 5px rgba(83, 95, 120, 0.102109);
  border-radius: 4px;
`;

export const ProfileImageGrid = styled(Grid)`
  display: flex;
  justify-content: flex-start;
  max-width: 230px !important;
  ${(props) => props.theme.breakpoints.down('md')} {
    max-width: 100% !important;
    justify-content: center;
  }
`;

export const ProfileImage = styled(Box)`
  width: 182px;
  height: 182px;
  background-color: #c4c4c4;
  border: 4px solid #3fcfd5;
  border-radius: 50%;
`;

export const ProfileDetails = styled(Box)`
  display: flex;
  flex-direction: column;
  ${(props) => props.theme.breakpoints.down('md')} {
    align-items: center;
  }
`;

export const ProfileName = styled(Typography)`
  font-weight: 600;
  font-size: 40px;
  line-height: 49px;
  color: #000000;
  ${(props) => props.theme.breakpoints.down('sm')} {
    font-size: 30px;
  }
  ${(props) => props.theme.breakpoints.down(450)} {
    font-size: 26px;
  }
`;

export const ProfileStats = styled(Box)`
  margin-top: 9px;
  max-width: 569px;
  display: flex;
  align-items: center;
  ${(props) => props.theme.breakpoints.down('lg')} {
    align-items: flex-start;
  }

  & > .MuiDivider-root {
    margin: 0 38px;
    width: 1px;
    height: 41px;
    background: #000000;
    opacity: 0.5;
    border-radius: 100px;
    transform: translateY(3px);
    ${(props) => props.theme.breakpoints.down('lg')} {
      margin: 0 20px;
    }
  }
`;

export const StatItem = styled(Box)`
  display: flex;
  flex-direction: column;
  ${(props) => props.theme.breakpoints.down('md')} {
    align-items: center;
  }
`;

export const StatCaption = styled(Typography)`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #646464;
  ${(props) => props.theme.breakpoints.down('md')} {
    font-size: 13px;
    text-align: center;
  }
`;

export const StatDetail = styled(Typography)<{ color?: string }>(
  ({ theme }) =>
    ({ color }) => ({
      marginTop: '2px',
      fontWeight: 600,
      fontSize: '24px',
      lineHeight: '29px',
      textTransform: 'capitalize',
      color: color ?? '#000000',
      [theme.breakpoints.down('md')]: {
        fontSize: '20px',
        textAlign: 'center',
      },
    }),
);

export const ActionButton = styled(Button)`
  margin-top: 25px;
  width: 100%;
  max-width: 606px;
  height: 51px;
  background: #3fcfd5;
  border-radius: 7px;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  text-transform: capitalize;
  color: #ffffff;

  &:hover {
    background: #28abb0;
    color: #ffffff;
  }
`;
