import { Box, Container, Paper, Stack, styled } from '@mui/material';
import LoadingContent from 'components/Common/LoadingContent/LoadingContent';
import { ContainerExcludeTopNav } from 'components/Common/Styled/CommonStyled';
import {
  RefundType,
  REFUND_STATUS,
  SplitType,
  TransactionDetailsResponse,
  useGetTransactionDetail,
} from 'hooks/Transactions/useTransactions';
import { useParams } from 'react-router-dom';
import { InfoSection, RefundButton } from './TransactionDetailStyled';
import ReplyIcon from '@mui/icons-material/Reply';
import {
  Text14ChineseBlackWeight500,
  Text16ChineseBlackWeight500,
  Text16MaximumBlueGreenWeight600,
  Text16Weight600,
  Text18ChineseBlackWeight700,
  Text18DarkBlueGreyWeight700,
  Text24ChineseBlackWeight700,
  Text30Weight600,
  Text32ChineseBlackWeight700,
} from 'components/Common/Styled/TypographyStyed';
import formatUtil from 'utils/format.util';
import commonConstants from 'constants/common.constant';
import { ProfileContainer } from 'components/Common/CustomTable/CustomTableStyled';
import AvatarWithFallback from 'components/Common/AvatarWithFallback/AvatarWithFallback';
import { ProfileName } from 'components/Common/CustomTable/CustomTableStyled';
import useModal from 'hooks/Common/useModal';
import { MODAL_TYPES } from 'hooks/Common/useModal';
import React, { useMemo } from 'react';

const OrderDetailSection = ({ data }: { data: TransactionDetailsResponse }) => {
  return (
    <Stack gap={2}>
      <Paper sx={{ py: 2, px: 3, borderRadius: 2 }}>
        <Stack gap={3}>
          <Text18ChineseBlackWeight700>
            Order details
          </Text18ChineseBlackWeight700>
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: 'auto 1fr auto',
              rowGap: 2,
            }}
          >
            {data?.orderItemDetails?.length > 0
              ? data?.orderItemDetails?.map((item, index: number) => (
                  <React.Fragment key={`${item?.title}_${index}`}>
                    <AvatarWithFallback
                      avatarVariant="rounded"
                      image={item?.img}
                      width={50}
                      height={50}
                      fromCDN
                    />
                    <Stack justifyContent="center" ml={2}>
                      <Text16ChineseBlackWeight500>
                        {item?.title ? item?.title : '--'}
                      </Text16ChineseBlackWeight500>
                    </Stack>
                    <Stack direction="row" gap={3} alignItems="center">
                      <Text16ChineseBlackWeight500>
                        Qty: {item?.quantity ?? '--'}
                      </Text16ChineseBlackWeight500>
                      <Text16ChineseBlackWeight500 sx={{ marginLeft: 'auto' }}>
                        {formatUtil.formatCurrency(item?.price)}
                      </Text16ChineseBlackWeight500>
                    </Stack>
                  </React.Fragment>
                ))
              : null}
          </Box>
        </Stack>
      </Paper>
      <Stack
        mx={3}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Text24ChineseBlackWeight700>Order Total</Text24ChineseBlackWeight700>
        <Text32ChineseBlackWeight700>
          {formatUtil.divideTo100AndFormatCurrency(data?.total)}
        </Text32ChineseBlackWeight700>
      </Stack>
    </Stack>
  );
};

type InfoSectionProps = {
  title: string;
  value: string;
  imageUrl?: string;
};

const InfoDetailSection = ({ title, value, imageUrl }: InfoSectionProps) => {
  return (
    <ProfileContainer>
      {imageUrl ? (
        <AvatarWithFallback image={imageUrl} width={50} height={50} fromCDN />
      ) : null}
      <Box>
        <ProfileName>{title ?? '--'}</ProfileName>
        <Text16Weight600>{value ?? '--'}</Text16Weight600>
      </Box>
    </ProfileContainer>
  );
};

type SplitDetailSectionProps = Partial<SplitType>;

const StatusText = styled(Text14ChineseBlackWeight500)({
  textTransform: 'capitalize',
});

const AmountText = styled(Text16ChineseBlackWeight500)({
  marginLeft: 'auto',
});

const SplitDetailSection = ({
  referrer_fee,
  referrer_status_text,
  transaction_fee,
  vendor_status_text,
  vendor_net,
}: SplitDetailSectionProps) => {
  return (
    <Paper sx={{ py: 2, px: 3, borderRadius: 2 }}>
      <Stack gap={3}>
        <Text18ChineseBlackWeight700>Splits</Text18ChineseBlackWeight700>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: 'auto 1fr auto',
            rowGap: 1,
            columnGap: 3,
            alignItems: 'end',
          }}
        >
          <Text16ChineseBlackWeight500>
            Referrer Fee
          </Text16ChineseBlackWeight500>
          <StatusText>{referrer_status_text}</StatusText>
          <AmountText>
            {formatUtil.divideTo100AndFormatCurrency(referrer_fee!)}
          </AmountText>

          <Text16ChineseBlackWeight500>
            Transaction Fee
          </Text16ChineseBlackWeight500>
          <StatusText>{/* leave empty*/}</StatusText>
          <AmountText>
            {formatUtil.divideTo100AndFormatCurrency(transaction_fee!)}
          </AmountText>

          <Text16ChineseBlackWeight500>
            You Received
          </Text16ChineseBlackWeight500>
          <StatusText>{vendor_status_text}</StatusText>
          <AmountText sx={{ fontSize: 18, fontWeight: 700 }}>
            {formatUtil.divideTo100AndFormatCurrency(vendor_net!)}
          </AmountText>
        </Box>
      </Stack>
    </Paper>
  );
};

type RefundStatusSectionProps = RefundType;

const RefundStatusSection = ({
  amount,
  created_at,
  status,
}: RefundStatusSectionProps) => {
  const text = useMemo(() => {
    switch (status) {
      case REFUND_STATUS.REFUNDED:
        return `A ${formatUtil.divideTo100AndFormatCurrency(
          +amount,
        )} refund has been processed ${formatUtil.formatDate({
          date: created_at as string,
          designateFormat: commonConstants.DATE_FORMAT.DD_MM_YYYY,
        })}`;
      case REFUND_STATUS.PENDING:
      case REFUND_STATUS.REFUNDING:
        return `A ${formatUtil.divideTo100AndFormatCurrency(
          +amount,
        )} refund is being processed`;
      default:
        return '--';
    }
  }, [amount, created_at, status]);
  return (
    <Paper
      sx={{
        backgroundColor: 'rgba(0, 145, 191, 0.1);',
        p: 3,
        borderRadius: 2,
        textAlign: 'center',
      }}
    >
      <Text18DarkBlueGreyWeight700>{text}</Text18DarkBlueGreyWeight700>
    </Paper>
  );
};

export function TransactionDetail(): JSX.Element {
  let { transactionId } = useParams();
  const { renderModal, setOpenModal } = useModal({
    additionalStyles: { width: 'min(100%, 600px)' },
  });
  const { data, isLoading } = useGetTransactionDetail({
    transactionId: transactionId ?? '',
  });
  const onHandleRefundProcess = () => {
    setOpenModal({
      modalType: MODAL_TYPES.REFUND_PAYMENT_MODAL,
      data: {
        orderTotal: data?.total ? +(data?.total / 100).toFixed(2) : 0,
        transactionId,
      },
    });
  };
  if (isLoading) {
    return (
      <ContainerExcludeTopNav>
        <Container
          maxWidth="md"
          sx={{ display: 'flex', justifyContent: 'center' }}
        >
          <LoadingContent />
        </Container>
      </ContainerExcludeTopNav>
    );
  }
  return (
    <ContainerExcludeTopNav>
      {renderModal}
      <Container
        maxWidth="md"
        sx={{ display: 'flex', justifyContent: 'center', mb: 4 }}
      >
        <Stack width="100%" gap={5}>
          {!data?.refund ? (
            <RefundButton
              onClick={() => onHandleRefundProcess()}
              startIcon={<ReplyIcon />}
              variant="contained"
              disabled={!data?.is_refundable}
            >
              Refund...
            </RefundButton>
          ) : null}
          <Stack gap={1}>
            <Text30Weight600>
              Transaction ID: {data?.transaction_id}
            </Text30Weight600>
            <Text16Weight600>
              {formatUtil.formatDate({
                date: data?.date as string,
                designateFormat: commonConstants.DATE_FORMAT.DD_MMM_YYYY,
              })}
            </Text16Weight600>
          </Stack>
          <InfoSection>
            <InfoDetailSection
              title="Customer"
              value={data?.customer_email ?? '--'}
            />
            <div />
            {data?.referrer ? (
              <>
                <InfoDetailSection
                  title="Referrer"
                  value={data?.referrer?.email ?? '--'}
                />
                <Box>
                  <ProfileName mt={0.5}>Referral Link</ProfileName>
                  <Text16MaximumBlueGreenWeight600>
                    {data?.referrer?.referral_link
                      ? data.referrer?.referral_link
                      : '--'}
                  </Text16MaximumBlueGreenWeight600>
                </Box>
              </>
            ) : null}
          </InfoSection>
          <OrderDetailSection data={data!} />
          <SplitDetailSection {...(data?.splits ?? {})} />
          {data?.refund ? (
            <RefundStatusSection {...(data?.refund ?? {})} />
          ) : null}
        </Stack>
      </Container>
    </ContainerExcludeTopNav>
  );
}
