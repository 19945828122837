import { TotalPerformanceItemType } from './../hooks/TotalPerformance/useTotalPerformance';
import moment from 'moment';
import produce from 'immer';
import commonConstants from '../constants/common.constant';
const locales = 'en-US';

const DEFAULT_CURRENCY = 'USD';

// const CURRENCY_FORMATTER = new Intl.NumberFormat(locales, {
//   currency: 'USD',
//   style: 'currency',
// });

const defaultCurrencyFormatter = (currency: string = DEFAULT_CURRENCY) =>
  new Intl.NumberFormat(locales, {
    currency,
    style: 'currency',
  });

const NUMBER_FORMATTER = new Intl.NumberFormat(locales);

const COMPACT_NUMBER_FORMATTER = new Intl.NumberFormat(locales, {
  notation: 'compact',
});

const formatUtil = {
  convertObjectIntoQueryString: (object: any = {}): string => {
    return Object.keys(object)
      .filter(
        (key) =>
          object[key] !== undefined &&
          object[key] !== null &&
          object[key] !== '',
      )
      .map((key) => `${key}=${encodeURI(object[key])}`)
      .join('&');
  },
  formatCurrency: (num: number, currency?: string) => {
    return num ? defaultCurrencyFormatter(currency).format(num) : '--';
  },
  divideTo100AndFormatCurrency: (num: number, currency?: string) => {
    return isNaN(num) || num === undefined || num === null
      ? '--'
      : defaultCurrencyFormatter(currency).format(+(num / 100).toFixed(2));
  },
  formatNumber: (num: number) => {
    return NUMBER_FORMATTER.format(num);
  },
  formatCompactNumber: (num: number) => {
    // 100000 => 100k, 10000000 => 10M
    return COMPACT_NUMBER_FORMATTER.format(num);
  },
  formatDate: ({
    date,
    originalFormat,
    designateFormat,
  }: {
    date: Date | string;
    originalFormat?: string;
    designateFormat: typeof commonConstants.DATE_FORMAT[keyof typeof commonConstants.DATE_FORMAT];
  }) => {
    let formattedDate = '--';
    try {
      if (originalFormat) {
        formattedDate = moment(date, originalFormat).format(designateFormat);
      } else {
        formattedDate = moment(date).format(designateFormat);
      }
    } catch (err) {
      /* Do nothing */
    }
    return formattedDate;
  },
  totalPerformanceFormat: ({
    sales_performance,
    referral_performance,
  }: {
    sales_performance: TotalPerformanceItemType[];
    referral_performance: TotalPerformanceItemType[];
  }) => {
    const [salesPerformance, referralPerformance] = [
      sales_performance,
      referral_performance,
    ].map((item) => {
      let temp = null;
      if (!!item && item?.length > 0) {
        temp = produce(item, (draftState: TotalPerformanceItemType[]) => {
          for (let i = 0; i < draftState.length; i++) {
            if (
              !!commonConstants.MAPPING_TOTAL_PERFORMANCE_ICON[
                draftState[i].caption_key
              ]
            ) {
              draftState[i].icon =
                commonConstants.MAPPING_TOTAL_PERFORMANCE_ICON[
                  draftState[i].caption_key
                ];
            }
          }
          return draftState;
        });
      }
      return temp;
    });
    return { salesPerformance, referralPerformance };
  },
  parseTotalPage: ({
    data,
    page,
    limit,
  }: {
    data: any;
    page: number;
    limit: number;
  }) => {
    let tempTotalPage = page;
    if (!!data && data?.total >= limit) {
      tempTotalPage = Math.ceil(data?.total / limit);
    }
    return tempTotalPage;
  },
  formatDateRangeString: (rangeDate: Date[] | null | any[]) => {
    if (rangeDate && rangeDate?.[0] && rangeDate?.[1]) {
      const startDate = moment(rangeDate[0]).format('DD-MM-YYYY');
      const endDate = moment(rangeDate[1]).format('DD-MM-YYYY');
      return `${startDate} - ${endDate}`;
    }
    return '';
  },
};

export default formatUtil;
