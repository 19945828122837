import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import { InputAdornment, styled, TextField } from '@mui/material';
import { Field } from 'formik';
import { useState } from 'react';
import { themes } from 'theme';
import PasswordValidationItem from './PasswordValidationItem';

const StyledField = styled(TextField)({
  '& .MuiInputLabel-root': {
    '&.Mui-focused': {
      color: themes.light.colorBlack,
    },
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      border: `1px solid ${themes.light.borderColor}`,
      borderRadius: '4px',
    },
    '&.Mui-focused fieldset': {
      borderColor: themes.light.colorMaximumBlueGreen,
    },
  },
});

const Password = (props: any) => {
  const {
    name,
    label,
    maxLength,
    displayCheck,
    skipShowError,
    handleChange,
    ...rest
  } = props;

  const [showValidationSteps, setShowValidationSteps] = useState(false);
  const [passwordMeetsMinLength, setPasswordMeetsMinLength] = useState(false);
  const [passwordHasUppercase, setPasswordHasUppercase] = useState(false);
  const [passwordHasLowercase, setPasswordHasLowercase] = useState(false);
  const [passwordHasNumber, setPasswordHasNumber] = useState(false);

  return (
    <Field name={name}>
      {({ field, form, meta }: { field: any; form: any; meta: any }) => {
        return (
          <>
            <StyledField
              fullWidth
              {...field}
              label={label}
              inputProps={{
                onFocus: (e) => {
                  setShowValidationSteps(true);
                },
                onBlur: form.handleBlur,
                maxLength,
                onChange: (e) => {
                  if (!e.currentTarget.value) return;

                  setPasswordMeetsMinLength(e.currentTarget.value.length >= 8);
                  setPasswordHasLowercase(
                    /([a-z])/.test(e.currentTarget.value),
                  );
                  setPasswordHasUppercase(
                    /([A-Z])/.test(e.currentTarget.value),
                  );
                  setPasswordHasNumber(/([0-9])/.test(e.currentTarget.value));
                },
              }}
              // autoComplete="off"
              error={!skipShowError && Boolean(meta.error)}
              helperText={
                (!skipShowError && meta.touched && meta.error) ||
                (!meta.touched &&
                  'Minimum of 8 characters, including at least one uppercase character, one lowercase character, and one number')
              }
              InputProps={{
                endAdornment:
                  !!displayCheck && meta.touched && !meta.error ? (
                    <InputAdornment position="end">
                      <CheckCircleRoundedIcon
                        style={{ color: themes.light.colorIrishGreen }}
                      />
                    </InputAdornment>
                  ) : null,
              }}
              {...rest}
            />
            {(meta.touched || showValidationSteps) && (
              <div className="password-validation-checklist__wrapper">
                <PasswordValidationItem
                  shortName="length"
                  validationMessage="Minimum of 8 characters"
                  meetsRequirements={passwordMeetsMinLength}
                ></PasswordValidationItem>

                <PasswordValidationItem
                  shortName="has-uppercase"
                  validationMessage="At least one uppercase character"
                  meetsRequirements={passwordHasUppercase}
                ></PasswordValidationItem>

                <PasswordValidationItem
                  shortName="has-lowercase"
                  validationMessage="At least one lowercase character"
                  meetsRequirements={passwordHasLowercase}
                ></PasswordValidationItem>

                <PasswordValidationItem
                  shortName="has-number"
                  validationMessage="At least one number"
                  meetsRequirements={passwordHasNumber}
                ></PasswordValidationItem>
              </div>
            )}
          </>
        );
      }}
    </Field>
  );
};

export default Password;
