import * as React from 'react';

type Props = {
  width?: number;
  height?: number;
};

export function FailIcon({ width = 84, height = 85 }: Props): JSX.Element {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 84 85"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M84 42.8794C84 66.0754 65.196 84.8794 42 84.8794C18.804 84.8794 0 66.0754 0 42.8794C0 19.6834 18.804 0.879395 42 0.879395C65.196 0.879395 84 19.6834 84 42.8794ZM4.62 42.8794C4.62 63.5238 21.3556 80.2594 42 80.2594C62.6444 80.2594 79.38 63.5238 79.38 42.8794C79.38 22.235 62.6444 5.49939 42 5.49939C21.3556 5.49939 4.62 22.235 4.62 42.8794Z"
        fill="black"
      />
      <g clip-path="url(#clip0_9291_13477)">
        <path
          d="M33.0818 49.21L48.3306 33.9612L50.9182 36.5489L35.6694 51.7977L33.0818 49.21ZM33.0818 36.5489L35.6694 33.9612L50.9182 49.21L48.3306 51.7977L33.0818 36.5489Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_9291_13477">
          <rect
            width="48"
            height="48"
            fill="white"
            transform="translate(18 18.8794)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
