import { Avatar, Box, styled } from '@mui/material';

export const Container = styled(Box)`
  padding: 56px;

  & > div.MuiBox-root:first-of-type {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 24px;
  }

  & > h1 ~ .MuiBox-root:nth-of-type(2) {
    margin-top: 32px;
  }
  ${(props) => props.theme.breakpoints.down('sm')} {
    padding: 30px;
  }
`;

export const HeaderLogo = styled(Avatar)`
  width: 64px;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  border: 1px solid #000000;
  border-radius: 50%;

  & > img {
    width: 44.8px;
    height: 33.6px;
  }
`;
