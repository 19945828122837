import React, { useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { toast } from 'react-toastify';
import ToastMessage, {
  ToastType,
} from '../../../components/Common/ToastMessage/ToastMessage';
import commonConstants from '../../../constants/common.constant';
import { CenterBox } from '../../../components/Common/Styled/CommonStyled';
import { CircularProgress } from '@mui/material';

const VerifyEmailSignUp = () => {
  const { code, email } = useParams();
  const ref = useRef(false);
  const navigate = useNavigate();
  useEffect(() => {
    if (!code || !email) {
      navigate('/invalid-link');
    }
    const confirmSignUp = async () => {
      try {
        await Auth.confirmSignUp(
          email as string /* username */,
          code as string,
        );
        toast(
          <ToastMessage
            text="Your email has been verified successfully. Please login."
            type={ToastType.SUCCESS.type}
          />,
        );
        navigate('/auth/login');
      } catch (error: any) {
        toast(
          <ToastMessage
            text={error?.message ?? commonConstants.SOMETHING_WENT_WRONG}
            type={ToastType.ERROR.type}
          />,
        );
        navigate('/invalid-link');
      }
    };
    if (!ref.current) {
      confirmSignUp();
      ref.current = true;
    }
  }, [code, email, navigate]);

  return (
    <CenterBox>
      <CircularProgress />
    </CenterBox>
  );
};

export default VerifyEmailSignUp;
