import { useMutation } from '@tanstack/react-query';
import { Storage } from 'aws-amplify';
const { v4: uuidv4 } = require('uuid');

export const useUploadToS3 = () => {
  return useMutation(
    async (payload: {
      fileName: string;
      fileType: string;
      fileContents: File;
    }) => {
      const { fileName, fileType, fileContents } = payload;
      const newFileName = `${uuidv4()}_${fileName}`;
      const response = await Storage.put(newFileName, fileContents, {
        contentType: fileType,
      });
      return response?.key;
    },
  );
};

export const useUploadToStripe = () => {
  return useMutation(
    async (payload: {
      fileName: string;
      fileType: string;
      fileContents: File;
    }) => {
      const { fileContents } = payload;
      const data = new FormData();
      data.append('file', fileContents);
      data.append('purpose', 'identity_document');
      const response = await fetch('https://uploads.stripe.com/v1/files', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_STRIPE_KEY}`,
        },
        body: data,
      });
      const responseData = await response.json();
      return responseData.id;
    },
  );
};
