import * as React from 'react';

type Props = {
  width?: number;
  height?: number;
};

export function GearIcon({ width = 32, height = 32 }: Props): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
    >
      <g clipPath="url(#clip0_810_7596)">
        <g clipPath="url(#clip1_810_7596)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M26.857 13.7141H24.2741C23.257 13.7141 22.7484 12.4856 23.4684 11.7656L25.2913 9.94272C25.5141 9.71986 25.5141 9.35415 25.2913 9.13129L22.8684 6.70843C22.6456 6.48557 22.2799 6.48557 22.057 6.70843L20.2341 8.53129C19.5141 9.25129 18.2856 8.74272 18.2856 7.72557V5.14272C18.2856 4.82843 18.0284 4.57129 17.7141 4.57129H14.2856C13.9713 4.57129 13.7141 4.82843 13.7141 5.14272V7.72557C13.7141 8.74272 12.4799 9.25129 11.7599 8.53129L9.937 6.70843C9.71415 6.48557 9.35415 6.48557 9.13129 6.70843L6.70272 9.13129C6.47986 9.35415 6.47986 9.71986 6.70272 9.94272L8.53129 11.7656C9.25129 12.4856 8.737 13.7141 7.71986 13.7141H5.14272C4.82843 13.7141 4.57129 13.9713 4.57129 14.2856V17.7141C4.57129 18.0341 4.82843 18.2856 5.14272 18.2856H7.71986C8.737 18.2856 9.25129 19.5199 8.53129 20.2399L6.70272 22.0627C6.47986 22.2856 6.47986 22.6456 6.70272 22.8741L9.13129 25.297C9.35415 25.5199 9.71415 25.5199 9.937 25.297L11.7599 23.4741C12.4799 22.7541 13.7141 23.2627 13.7141 24.2799V26.857C13.7141 27.177 13.9713 27.4284 14.2856 27.4284H17.7141C18.0284 27.4284 18.2856 27.177 18.2856 26.857V24.2799C18.2856 23.2627 19.5141 22.7541 20.2341 23.4741L22.057 25.297C22.2799 25.5199 22.6456 25.5199 22.8684 25.297L25.2913 22.8741C25.5141 22.6456 25.5141 22.2856 25.2913 22.0627L23.4684 20.2399C22.7484 19.5199 23.257 18.2856 24.2741 18.2856H26.857C27.1713 18.2856 27.4284 18.0341 27.4284 17.7141V14.2856C27.4284 13.9713 27.1713 13.7141 26.857 13.7141Z"
            stroke="black"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20 16C20 18.2114 18.2057 20 16 20C13.7886 20 12 18.2114 12 16C12 13.7943 13.7886 12 16 12C18.2057 12 20 13.7943 20 16Z"
            stroke="black"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_810_7596">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(4 4)"
          />
        </clipPath>
        <clipPath id="clip1_810_7596">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(4 4)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
