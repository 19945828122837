import commonConstants from '../../constants/common.constant';
import { callAPIGet, callAPIPost } from '../Auth/commonAuthAPI';

export const validateBankInfoAPI = (payload: any) => {
  const apiName = commonConstants.API_NAME.PAYMENT;
  const path = commonConstants.API_PATH.LINK_BANK_ACCOUNT;
  return callAPIPost(payload, apiName, path);
};

export const getBankInfoAPI = (userId: string) => {
  const apiName = commonConstants.API_NAME.PAYMENT;
  const path = commonConstants.API_PATH.GET_LINKED_BANK_INFO;
  return callAPIGet(apiName, `${path}/${userId}`, {});
};

type CreateStripeAccountAPIPayload = {
  business_type: ValueOf<typeof commonConstants.BANK_ACCOUNT_TYPE>;
  action: ValueOf<typeof commonConstants.LINK_BANK_ACCOUNT_ACTION>;
  user_id: string;
};

export const createStripeAccountAPI = (
  payload: CreateStripeAccountAPIPayload,
) => {
  const apiName = commonConstants.API_NAME.PAYMENT;
  const path = commonConstants.API_PATH.LINK_BANK_ACCOUNT;
  return callAPIPost({ ...payload }, apiName, path);
};

export type UpdatePaymentsAPIProps = {
  paymentMethodId: string;
};

export const updatePaymentsAPI = (body: UpdatePaymentsAPIProps) => {
  const apiName = commonConstants.API_NAME.PAYMENT;
  const path = commonConstants.API_PATH.LINK_CARD;
  return callAPIPost(body, apiName, path);
};

export type UpdateVendorURL_APIProps = {
  user_id?: string;
  vendor_id?: string;
  newDomain: string;
  code: string;
};

export const updateVendorURL_API = (body: UpdateVendorURL_APIProps) => {
  const apiName = commonConstants.API_NAME.VENDOR;
  const path = commonConstants.API_PATH.UPDATE_DOMAIN;
  return callAPIPost(body, apiName, path);
};

export const sendSMSCodeAPI = (user_id: string) => {
  const apiName = commonConstants.API_NAME.VENDOR;
  const path = commonConstants.API_PATH.SMS_VERIFICATION;
  const body = {
    user_id,
  };
  return callAPIPost(body, apiName, path);
};
