import { Avatar, Box, Button, styled, Typography } from '@mui/material';

export const Container = styled(Box)`
  padding: 52px;
  font-family: 'Montserrat';
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const AccountProfile = styled(Box)`
  padding: 60px 0;
  width: 600px;
  background: #ffffff;
  box-shadow: 0px 0px 5px rgba(83, 95, 120, 0.102109);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${(props) => props.theme.breakpoints.down('md')} {
    max-width: 500px;
  }
  ${(props) => props.theme.breakpoints.down('sm')} {
    max-width: 90vw;
  }
  ${(props) => props.theme.breakpoints.down(500)} {
    max-width: 420px;
  }
  ${(props) => props.theme.breakpoints.down(440)} {
    max-width: 320px;
  }
`;

export const ImageContainer = styled(Avatar)`
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  border: 1px solid #000000;
  border-radius: 50%;

  & > img {
    width: 56px;
    height: 42px;
  }
`;

export const Name = styled(Typography)`
  margin-top: 24px;
  font-family: Lexend Tera;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  text-transform: uppercase;
  color: #000000;
`;

export const Caption = styled('a')`
  margin-top: 8px;
  font-family: Montserrat;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #2cccd3;
  text-decoration: none;
`;

export const Options = styled(Box)`
  margin-top: 24px;
  min-width: 375px;
  display: flex;

  & > .MuiDivider-root {
    width: 1px;
    height: 65px;
    background: #eeeeee;
    border-radius: 100px;
    transform: translateY(-8px);
  }

  ${(props) => props.theme.breakpoints.down('sm')} {
    min-width: 300px;
    max-width: 320px;
  }
`;

export const OptionItem = styled(Button)`
  width: 125px;
  height: 65px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & > .MuiTypography-root {
    text-transform: capitalize;
  }
`;

export const OptionText = styled(Typography)`
  margin-top: 4px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
`;

export const Links = styled(Box)`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(3, auto);
`;

export const LinkItem = styled(Button)`
  padding: 16px 24px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  & > .MuiTypography-root {
    text-transform: none;
  }

  & > svg {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
  }
`;

export const LinkTitle = styled(Typography)`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  mix-blend-mode: normal;
`;

export const LinkCaption = styled(Typography)`
  margin-top: 4px;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #646464;
  mix-blend-mode: normal;
`;

export function ChevronIcon(): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
    >
      <g clipPath="url(#clip0_810_7564)">
        <path
          d="M13.3331 8L11.4531 9.88L17.5598 16L11.4531 22.12L13.3331 24L21.3331 16L13.3331 8Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_810_7564">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
