import {
  Container,
  Stack,
  Toolbar,
  SwipeableDrawer,
  IconButton,
  Box,
  List,
  ListItem,
  ListItemButton,
} from '@mui/material';
import { Menu as MenuIcon, Close as CloseIcon } from '@mui/icons-material';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import AccountDisableIcon from '../../assets/images/icon-account-disable.png';
import AccountIcon from '../../assets/images/icon-account.png';
import HomeDisableIcon from '../../assets/images/icon-home-disable.png';
import HomeIcon from '../../assets/images/icon-home.png';
import TransactionsDisableIcon from '../../assets/images/icon-transactions-disable.png';
import TransactionsIcon from '../../assets/images/icon-transactions.png';
import LogoIReferIcon from '../../assets/images/icon-logo-irefer-trademark.png';
import { useFetchUserInfoOnce, useLogout } from '../../hooks/Auth/useAuth';
import { useGetBankInfoV2 } from '../../hooks/Info/useInfo';
import LinkBankAccountPopover from '../../pages/Account/Payments/LinkBankAccount/LinkBankAccountPopover';
import {
  CustomLink,
  LogoImage,
  LogoutButton,
  StyledAppBar,
} from './NavbarStyled';
import commonConstants from 'constants/common.constant';
import { useSelector } from 'react-redux';
import { getUser } from 'redux/selectors/auth.selector';

const Navbar = () => {
  useFetchUserInfoOnce();
  const user = useSelector(getUser);
  const location = useLocation();
  const navigate = useNavigate();
  const { mutate: mutateLogout } = useLogout();
  const [linkBankAnchor, setLinkBankAnchor] = useState<any>(null);
  const linksRef = useRef() as React.MutableRefObject<HTMLInputElement>;
  const [openDrawer, setOpenDrawer] = useState(false);
  const pages = useMemo(() => {
    return [
      {
        to: '/api-plugins',
        label: 'API Plugins',
      },
      {
        to: '/home',
        label: 'Home',
        icon: HomeIcon,
        disableIcon: HomeDisableIcon,
        disable: !user?.vendor?.is_approved,
      },
      {
        to: '/transactions',
        label: 'Transactions',
        icon: TransactionsIcon,
        disableIcon: TransactionsDisableIcon,
        disable: !user?.vendor?.is_approved,
      },
      {
        to: '/account',
        label: 'Account',
        icon: AccountIcon,
        disableIcon: AccountDisableIcon,
      },
    ];
  }, [user?.vendor?.is_approved]);
  const handleLogout = () => {
    mutateLogout();
  };
  const { data: linkedBankInfo, isLoading: isLoadingLinkedBankInfo } =
    useGetBankInfoV2();

  useEffect(() => {
    if (
      [
        commonConstants.STRIPE_STATUS_TYPE.NOT_CREATED,
        commonConstants.STRIPE_STATUS_TYPE.ACTION_REQUIRED,
      ].includes(
        linkedBankInfo?.status as Extract<
          ValueOf<typeof commonConstants.STRIPE_STATUS_TYPE>,
          'not-created' | 'action-required'
        >,
      ) &&
      location.pathname !== '/account/payments/link-bank-account'
    ) {
      setLinkBankAnchor(linksRef.current);
    }
  }, [linkedBankInfo, isLoadingLinkedBankInfo, location.pathname]);

  return (
    <StyledAppBar position="sticky">
      <Container maxWidth={false}>
        <Toolbar>
          <Stack
            width="100%"
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <LogoImage src={LogoIReferIcon} onClick={() => navigate('/')} />
            <Stack
              direction="row"
              gap={2}
              sx={{ height: '100%', display: { xs: 'block', md: 'none' } }}
            >
              <IconButton
                onClick={() => setOpenDrawer(true)}
                sx={{ display: { xs: 'block', md: 'none' } }}
              >
                <MenuIcon />
              </IconButton>
              <SwipeableDrawer
                anchor={'left'}
                open={openDrawer}
                onClose={() => setOpenDrawer(false)}
                onOpen={() => setOpenDrawer(true)}
              >
                <Box
                  sx={{
                    width: '100vw',
                  }}
                >
                  <Stack direction="row" justifyContent="flex-end">
                    <IconButton
                      sx={{ mt: 2, mr: 2 }}
                      onClick={() => setOpenDrawer(false)}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Stack>
                  <List>
                    {pages.map((item) => (
                      <ListItem key={item.label} disablePadding>
                        <ListItemButton
                          onClick={() => {
                            if ('disable' in item && item.disable) {
                              return;
                            }
                            navigate(item.to);
                          }}
                        >
                          <CustomLink key={item.to} {...item}>
                            {item.label}
                          </CustomLink>
                        </ListItemButton>
                      </ListItem>
                    ))}
                    <ListItem disablePadding>
                      <ListItemButton onClick={handleLogout}>
                        Logout
                      </ListItemButton>
                    </ListItem>
                  </List>
                </Box>
              </SwipeableDrawer>
            </Stack>
            <Stack
              ref={linksRef}
              direction="row"
              gap={2}
              sx={{ height: '100%', display: { xs: 'none', md: 'flex' } }}
              alignItems="center"
            >
              {pages.map((item) => (
                <CustomLink key={item.to} {...item}>
                  {item.label}
                </CustomLink>
              ))}
              <LogoutButton variant="text" onClick={handleLogout}>
                Logout
              </LogoutButton>
            </Stack>
          </Stack>
        </Toolbar>
        <LinkBankAccountPopover
          anchor={linkBankAnchor}
          setAnchor={setLinkBankAnchor}
          linkBankAccountStatus={
            linkedBankInfo?.status as Extract<
              ValueOf<typeof commonConstants.STRIPE_STATUS_TYPE>,
              'not-created' | 'action-required'
            >
          }
        />
      </Container>
    </StyledAppBar>
  );
};

export default Navbar;
