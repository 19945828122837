import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import commonConstants from 'constants/common.constant';

export const PROPERTY_KEY = {
  PAGE: 'page',
  RANGE_DATE: 'rangeDate',
  KEYWORD: 'keyword',
  DIRECTION: 'direction',
  FILTER: 'filter',
  DATE_FROM: 'dateFrom',
  DATE_TO: 'dateTo',
} as const;

const initialState = {
  page: commonConstants.REFERRALS_DASHBOARD_DEFAULT_QUERY_PARAMS.page,
  limit: commonConstants.REFERRALS_DASHBOARD_DEFAULT_QUERY_PARAMS.limit,
  keyword: commonConstants.REFERRALS_DASHBOARD_DEFAULT_QUERY_PARAMS.keyword,
  filter: commonConstants.REFERRALS_DASHBOARD_DEFAULT_QUERY_PARAMS.filter,
  direction: commonConstants.REFERRALS_DASHBOARD_DEFAULT_QUERY_PARAMS.direction,
  dateFrom: commonConstants.REFERRALS_DASHBOARD_DEFAULT_QUERY_PARAMS.dateFrom,
  dateTo: commonConstants.REFERRALS_DASHBOARD_DEFAULT_QUERY_PARAMS.dateTo,
} as const;

type PayloadProperty = {
  type: typeof PROPERTY_KEY[keyof typeof PROPERTY_KEY];
  value: typeof initialState[keyof typeof initialState];
};

type PayloadProperties = PayloadProperty[];

const ReferralsDashboardSlice = createSlice({
  name: 'referralsDashboard',
  initialState,
  reducers: {
    setProperty: (state, action: PayloadAction<PayloadProperty>) => {
      // @ts-ignore
      state[action.payload.type] = action.payload.value;
    },
    setProperties: (state, action: PayloadAction<PayloadProperties>) => {
      if (action.payload.length > 0) {
        action.payload.forEach((item: PayloadProperty) => {
          if (state.hasOwnProperty(item.type)) {
            // @ts-ignore
            state[item.type] = item.value;
          }
        });
      }
    },
  },
});

export const { setProperty, setProperties } = ReferralsDashboardSlice.actions;

export default ReferralsDashboardSlice.reducer;
