import { Box, Button, styled } from '@mui/material';

export const RefundButton = styled(Button)({
  marginLeft: 'auto',
  color: '#494d57',
  backgroundColor: '#fff',
  textTransform: 'unset',
  '&:hover': {
    backgroundColor: '#fff',
  },
});

export const InfoSection = styled(Box)(({ theme }) => ({
  display: 'grid',
  gap: '20px',
  [theme.breakpoints.up('md')]: {
    gridTemplateColumns: 'auto 1fr',
  },
}));
