import { StyledCloseButton } from './CloseButtonStyled';
import CloseIcon from '@mui/icons-material/Close';
import { useLogout } from '../../../hooks/Auth/useAuth';

type CloseButtonProps = {
  onHandleClick?: Function;
};

const CloseButton = ({ onHandleClick, ...rest }: Partial<CloseButtonProps>) => {
  const { mutate } = useLogout({
    willUseToast: false,
    willImmediatelyNavigateToLogin: true,
  });
  const onClick = onHandleClick ?? mutate;
  return (
    <StyledCloseButton onClick={() => onClick()} {...rest}>
      <CloseIcon />
    </StyledCloseButton>
  );
};

export default CloseButton;
