import { useQuery } from '@tanstack/react-query';
import commonConstants from '../../constants/common.constant';
import formatUtil from '../../utils/format.util';
import getQueryKey from '../../utils/get-query-key';
import useAxios from 'hooks/Common/useAxios';

export type TotalPerformanceItemType = {
  caption_key: keyof typeof commonConstants.MAPPING_TOTAL_PERFORMANCE_ICON;
  caption: string;
  main: string;
  footer: string;
  isCurrency?: boolean;
  isPercentage?: boolean;
  sub_main?: string;
  icon?: JSX.Element;
};

export const useGetSalesAnalytics = () => {
  const axios = useAxios();
  return useQuery({
    queryKey: getQueryKey.getSalesAnalyticsQueryKey(),
    queryFn: async () => {
      const response = await axios.get('/vendors/sales-analytics');
      if (response?.status === 200 && response?.data) {
        return formatUtil.totalPerformanceFormat({
          sales_performance: response?.data?.sales_performance ?? [],
          referral_performance: response?.data?.referral_performance ?? [],
        });
      }
      return {
        salesPerformance: [],
        referralPerformance: [],
      };
    },
    staleTime: commonConstants.STALE_TIME.MIN_1,
  });
};
