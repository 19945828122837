import moment from 'moment';
import { matchIsValidTel } from 'mui-tel-input';
import commonConstants from '../constants/common.constant';

export const YupValidationTest = {
  IS_VALID_YEAR: {
    NAME: 'is-valid-year',
    MESSAGE: 'Invalid year',
    TEST: (year: any) => {
      const formattedYear = moment(year, 'YYYY');
      const currentYear = moment(new Date()).format('YYYY');
      if (
        formattedYear.isValid() &&
        +formattedYear.format('YYYY') <= +currentYear
      ) {
        return true;
      }
      return false;
    },
  },
  IS_VALID_PHONE: {
    NAME: 'is-valid-phone',
    MESSAGE: 'Phone number is not valid',
    TEST: (val: any) => matchIsValidTel(val ?? ''),
  },
  IS_VALID_DATE: {
    NAME: 'is-valid-date',
    MESSAGE: 'Date is not valid',
    TEST: (
      date: any,
      formatDate: string = commonConstants.DATE_FORMAT.MM_DD_YYYY,
    ) => {
      return moment(date, formatDate).isValid();
    },
  },
};
