import { AppBar, Button, Stack, styled, Typography } from '@mui/material';
import React from 'react';
import { Link, useLocation, useMatch, useResolvedPath } from 'react-router-dom';
import { themes } from 'theme';

export const StyledAppBar = styled(AppBar)({
  backgroundColor: themes.light.colorWhite,
  boxShadow: 'inset 0px -1px 0px rgba(0, 0, 0, 0.12)',
  '& > .MuiContainer-root': {
    paddingBlock: 8,
  },
});

export const IconImage = styled('img')({
  width: '30px',
  cursor: 'pointer',
  marginRight: '.5rem',
});

export const LogoImage = styled('img')({
  width: '70px',
  cursor: 'pointer',
});

type StyledLinkProps = {
  isActiveLink: boolean;
  children: React.ReactNode;
  disable?: boolean;
};

export const StyledLink = ({
  isActiveLink,
  children,
  disable = false,
}: StyledLinkProps) => {
  const Component = styled(Stack)(() => ({
    alignItems: 'center',
    textTransform: 'unset',
    fontSize: '1rem',
    fontWeight: '400',
    '& a': {
      fontFamily: themes.light.fontMontserrat,
      padding: '0 10px',
      display: 'flex',
      alignItems: 'center',
      height: '100%',
      textDecoration: 'none',
      color: `${
        isActiveLink ? themes.light.colorBlack : themes.light.colorLightGray
      }`,
      cursor: disable ? 'default' : 'pointer',
      '& img': {
        cursor: disable ? 'default' : 'pointer',
      },
    },
  }));
  return <Component>{children}</Component>;
};

export const LogoutButton = styled(Button)({
  textTransform: 'unset',
  fontSize: '1rem',
  fontWeight: 400,
  padding: '10px 0px 10px 20px',
  position: 'relative',
});

export const LinkText = styled(Typography)(() => ({
  fontSize: '1rem',
}));

type CustomLinkProps = {
  to: string;
  icon?: string;
  disableIcon?: string;
  children: React.ReactNode;
  disable?: boolean;
} & React.ComponentProps<typeof Link>;

export const CustomLink = ({
  to,
  icon,
  disableIcon,
  children,
  disable = false,
  ...props
}: CustomLinkProps) => {
  const resolvePath = useResolvedPath(to);
  const location = useLocation();
  let isActiveObject = useMatch({ path: resolvePath.pathname, end: false });
  let isActive = false;
  if (
    !!isActiveObject ||
    (location.pathname === '/' && resolvePath.pathname === '/home')
  ) {
    isActive = true;
  }
  const handleClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => {
    if (disable) {
      event.preventDefault();
    }
  };
  return (
    <StyledLink disable={disable} isActiveLink={isActive}>
      <Link to={to} {...props} onClick={handleClick}>
        {icon && disableIcon ? (
          <IconImage src={isActive ? icon : disableIcon} />
        ) : null}
        <LinkText>{children}</LinkText>
      </Link>
    </StyledLink>
  );
};
