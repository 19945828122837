import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { Helmet } from 'react-helmet-async';
import {
  Logo,
  StyledLoadingButton,
  StyledStack,
  TextButton,
} from '../AuthStyled';
import UploadSection from '../../../components/Common/UploadSection/UploadSection';
import { useLocation, useNavigate } from 'react-router-dom';
import { CONTENT_PROCESSING_YOUR_APPLICATION } from '../WaitingApprove/WaitingApprove';
import { useUpdateVendorInfo } from '../../../hooks/Auth/useAuth';
import CloseButton from '../../../components/Common/CloseButton/CloseButton';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getUser } from '../../../redux/selectors/auth.selector';
import { StyledBox } from '../../../components/Common/Styled/CommonStyled';
import {
  CommonErrorText,
  CommonTitleText,
} from '../../../components/Common/Styled/TypographyStyed';
import LogoIReferIcon from '../../../assets/images/icon-logo-irefer-trademark.png';
import { Stack } from '@mui/material';

type UploadValues = {
  logoUrl: string;
};

const validationSchema = Yup.object({
  logoUrl: Yup.string().required('Logo is required.'),
});

const UploadCompanyLogo = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isInitialUploadLogo = location?.state?.isInitialUploadLogo ?? false;
  const user = useSelector(getUser);
  const initialValues: UploadValues = useMemo(
    () => ({
      logoUrl:
        user?.vendor.logo &&
        user?.vendor?.logo?.split('/').pop() !== 'undefined'
          ? user.vendor.logo
          : '',
    }),
    [user],
  );
  const {
    mutate: mutateUpdateVendorInfo,
    isLoading: isLoadingUpdateVendorInfo,
  } = useUpdateVendorInfo();
  const onSubmit = (values: UploadValues) => {
    mutateUpdateVendorInfo(
      { logo: values.logoUrl },
      {
        onSuccess: () => {
          isInitialUploadLogo ? onHandleComplete() : onHandleNavigateToHome();
        },
      },
    );
  };
  const onHandleNavigateToHome = () => {
    navigate('/');
  };

  const onHandleComplete = () => {
    navigate('/auth/waiting-approve', {
      state: {
        contentMessage: CONTENT_PROCESSING_YOUR_APPLICATION.CONGRATULATION,
      },
    });
  };

  const closeButtonProps = isInitialUploadLogo
    ? {}
    : {
        onHandleClick: () => navigate(-1),
      };

  const formikProps = { initialValues, validationSchema, onSubmit };
  return (
    <Formik {...formikProps} enableReinitialize={true}>
      {({ handleSubmit, errors, values, setFieldValue, isValid, touched }) => (
        <Form>
          <Helmet>
            <title>Upload Company Logo</title>
            <meta name="description" content="iRefer Upload Company Logo" />
          </Helmet>
          <StyledBox
            sx={{
              padding: '52px',
              display: 'flex',
              alignItems: 'center',
              width: '100%',
              position: 'relative',
            }}
          >
            <CloseButton {...closeButtonProps} />
            <StyledStack
              sx={{
                gap: 6,
                height: '100%!important',
              }}
            >
              <Stack alignItems="center">
                <Logo
                  alt="iReferLogo"
                  src={LogoIReferIcon}
                  style={{ width: '104px' }}
                />
              </Stack>
              <CommonTitleText>UPLOAD YOUR COMPANY LOGO</CommonTitleText>
              <UploadSection
                errors={errors}
                name="logoUrl"
                setFieldValue={setFieldValue}
                value={values['logoUrl']}
                typeAllow={['image']}
              />
              <StyledStack sx={{ gap: 3 }}>
                {Boolean(touched['logoUrl'] && errors['logoUrl']) && (
                  <CommonErrorText sx={{ textAlign: 'center' }}>
                    Please upload your Company Logo
                  </CommonErrorText>
                )}
                <StyledLoadingButton
                  loading={isLoadingUpdateVendorInfo}
                  disabled={!isValid}
                  variant="contained"
                  onClick={() => {
                    handleSubmit();
                  }}
                  sx={{ fontSize: '1rem' }}
                >
                  Confirm
                </StyledLoadingButton>
                {isInitialUploadLogo && (
                  <TextButton
                    disabled={isLoadingUpdateVendorInfo}
                    onClick={() => onHandleComplete()}
                  >
                    Skip for now
                  </TextButton>
                )}
              </StyledStack>
            </StyledStack>
          </StyledBox>
        </Form>
      )}
    </Formik>
  );
};

export default UploadCompanyLogo;
