import React, { useEffect } from 'react';
import { styled } from '@mui/material';
import ProtectedRoute from 'components/Auth/ProtectedRoute';
import LayoutWithNav from 'components/Layout/LayoutWithNav';
import OutsideLayout from 'components/Layout/OutsideLayout';
import { useSelector } from 'react-redux';
import { Outlet, Route, Routes, useNavigate } from 'react-router-dom';
import { getIsAuthenticated } from 'redux/selectors/auth.selector';
import { Provider as JotaiProvider } from 'jotai';
import PageNotFound from '../PageNotFound/PageNotFound';
import ForgotPassword from './ForgotPassword/ForgotPassword';
import ListYourBusiness from './ListYourBusiness/ListYourBusiness';
import Login from './Login/Login';
import ResetPassword from './ResetPassword/ResetPassword';
import ResendVerifyEmail from './SignUp/ResendVerifyEmail';
import SignUp from './SignUp/SignUp';
import VerifyEmailSignUp from './SignUp/VerifyEmailSignUp';
import VerifyPhoneSignUp from './SignUp/VerifyPhoneSignUp';
import UpdatePassword from './UpdatePassword/UpdatePassword';
import UploadCompanyLogo from './UploadCompanyLogo/UploadCompanyLogo';
import VerifyAccount from './VerifyAccount/VerifyAccount';
import WaitingApprove from './WaitingApprove/WaitingApprove';
import YourBusinessAddress from './YourBusinessAddress/YourBusinessAddress';
import UpdateWebsiteUrl from './UpdateWebsiteUrl/UpdateWebsiteURL';
import { StyledBackgroundGray } from 'components/Common/Styled/CommonStyled';
import StripeApproval from './StripeApproval/StripeApproval';

// import loadable from '@loadable/component';

// const Login = React.lazy(() => import('./Login/Login'));
// const PageNotFound = React.lazy(() => import('../PageNotFound/PageNotFound'));
// const ForgotPassword = React.lazy(
//   () => import('./ForgotPassword/ForgotPassword'),
// );
// const ResetPassword = React.lazy(() => import('./ResetPassword/ResetPassword'));
// const SignUp = React.lazy(() => import('./SignUp/SignUp'));
// const VerifyEmailSignUp = React.lazy(
//   () => import('./SignUp/VerifyEmailSignUp'),
// );
// const VerifyPhoneSignUp = React.lazy(
//   () => import('./SignUp/VerifyPhoneSignUp'),
// );
// const ProtectedRoute = React.lazy(
//   () => import('../../components/Auth/ProtectedRoute'),
// );
// const ResendVerifyEmail = React.lazy(
//   () => import('./SignUp/ResendVerifyEmail'),
// );
// const ListYourBusiness = React.lazy(
//   () => import('./ListYourBusiness/ListYourBusiness'),
// );
// const WaitingApprove = React.lazy(
//   () => import('./WaitingApprove/WaitingApprove'),
// );
// const UploadCompanyLogo = React.lazy(
//   () => import('./UploadCompanyLogo/UploadCompanyLogo'),
// );
// const VerifyAccount = React.lazy(() => import('./VerifyAccount/VerifyAccount'));
// const UpdatePassword = React.lazy(
//   () => import('./UpdatePassword/UpdatePassword'),
// );

// const Login = loadable(() => import('./Login/Login'));
// const PageNotFound = loadable(() => import('../PageNotFound/PageNotFound'));
// const ForgotPassword = loadable(
//   () => import('./ForgotPassword/ForgotPassword'),
// );
// const ResetPassword = loadable(() => import('./ResetPassword/ResetPassword'));
// const SignUp = loadable(() => import('./SignUp/SignUp'));
// const VerifyEmailSignUp = loadable(() => import('./SignUp/VerifyEmailSignUp'));
// const VerifyPhoneSignUp = loadable(() => import('./SignUp/VerifyPhoneSignUp'));
// const ProtectedRoute = loadable(
//   () => import('../../components/Auth/ProtectedRoute'),
// );
// const ResendVerifyEmail = loadable(() => import('./SignUp/ResendVerifyEmail'));
// const ListYourBusiness = loadable(
//   () => import('./ListYourBusiness/ListYourBusiness'),
// );
// const WaitingApprove = loadable(
//   () => import('./WaitingApprove/WaitingApprove'),
// );
// const UploadCompanyLogo = loadable(
//   () => import('./UploadCompanyLogo/UploadCompanyLogo'),
// );
// const VerifyAccount = loadable(() => import('./VerifyAccount/VerifyAccount'));
// const UpdatePassword = loadable(
//   () => import('./UpdatePassword/UpdatePassword'),
// );

export const StyledBackground = styled(StyledBackgroundGray)({
  flexGrow: 1,
  justifyContent: 'center',
  alignItems: 'center',
});

const RouteRedirectToHome = () => {
  const isAuthenticatedVendorUser = useSelector(getIsAuthenticated);
  const navigate = useNavigate();
  useEffect(() => {
    if (isAuthenticatedVendorUser) {
      navigate('/');
    }
  }, [isAuthenticatedVendorUser, navigate]);
  return <Outlet />;
};

const AuthRoutes = () => {
  return (
    <Routes>
      <Route element={<OutsideLayout />}>
        <Route path="/login" element={<Login />} />
        <Route
          element={
            <ProtectedRoute skipRedirectForcingPages>
              <Outlet />
            </ProtectedRoute>
          }
        >
          <Route path="/verify-phone-sign-up" element={<VerifyPhoneSignUp />} />
          <Route path="/verify-account" element={<VerifyAccount />} />
          <Route path="/list-your-business" element={<ListYourBusiness />} />
          <Route
            path="/your-business-address"
            element={<YourBusinessAddress />}
          />
          <Route path="/upload-company-logo" element={<UploadCompanyLogo />} />
          <Route path="/stripe-approval" element={<StripeApproval />} />
        </Route>

        <Route path="/waiting-approve" element={<WaitingApprove />} />
        <Route element={<RouteRedirectToHome />}>
          <Route path="/sign-up" element={<SignUp />} />
          <Route path="/resend-verify-email" element={<ResendVerifyEmail />} />
          <Route
            path="/verify-email/:code/:email"
            element={<VerifyEmailSignUp />}
          />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route
            path="/reset-password/:code/:email"
            element={<ResetPassword />}
          />
        </Route>
      </Route>
      <Route
        element={
          <ProtectedRoute skipRedirectForcingPages>
            <Outlet />
          </ProtectedRoute>
        }
      >
        <Route element={<LayoutWithNav />}>
          <Route
            path="/update-password"
            element={
              <StyledBackground>
                <UpdatePassword />
              </StyledBackground>
            }
          />
          <Route
            path="/update-website-url"
            element={
              <StyledBackground>
                <JotaiProvider>
                  <UpdateWebsiteUrl />
                </JotaiProvider>
              </StyledBackground>
            }
          />
        </Route>
      </Route>
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};

export default AuthRoutes;
