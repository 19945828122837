import * as React from 'react';
import { Divider } from '@mui/material';
import {
  ChevronIcon,
  Links,
  LinkItem,
  LinkTitle,
  LinkCaption,
  Name,
  Caption,
  Options,
  OptionItem,
  OptionText,
  Container,
} from './AccountStyled';
import { AccountProfile } from './AccountStyled';
import {
  //  BellIcon,
  CreditCardIcon,
  GearIcon,
} from '../../components/Icons';
import AvatarWithFallback from '../../components/Common/AvatarWithFallback/AvatarWithFallback';
import { useSelector } from 'react-redux';
import { getUser } from '../../redux/selectors/auth.selector';
import { useNavigate } from 'react-router-dom';
import { navigateToDifferentSite } from '../../utils/window.util';
import ContactUs from './ContactUs/ContactUs';
import commonConstants from 'constants/common.constant';

const { Fragment, useMemo } = React;

const options = [
  { name: 'Payments', icon: <CreditCardIcon />, page: '/account/payments' },
  // { name: 'Notifications', icon: <BellIcon />, page: '/account/notifications' },
  { name: 'Settings', icon: <GearIcon />, page: '/account/settings' },
  {
    component: <ContactUs />,
  },
];

const links: {
  title: string;
  caption?: string;
  externalLink?: string;
  internalLink?: string;
}[] = [
  // { title: 'Inbox', caption: 'View Messages' },
  // { title: 'Your Subscription', caption: 'Premium account.' },
  // { title: 'Referrer Settings', caption: 'View Settings' },
  {
    title: 'Terms and Conditions',
    externalLink: commonConstants.MERCHANT_TERMS_AND_CONDITIONS_LINK,
  },
  {
    title: 'Privacy Policy',
    externalLink: commonConstants.PRIVACY_POLICY_LINK,
  },
];

export function Account(): JSX.Element {
  const user = useSelector(getUser);
  const navigate = useNavigate();
  const info = useMemo(() => {
    return {
      logo: user?.vendor?.logo ?? '',
      businessName: user?.vendor?.business_name ?? '--',
      domain: user?.vendor?.domain ?? '--',
    };
  }, [user]);
  return (
    <Container>
      <AccountProfile>
        <AvatarWithFallback
          image={info?.logo}
          height={80}
          width={80}
          onClickEdit={() => navigate('/auth/upload-company-logo')}
          fromCDN
          hasBorder
        />
        <Name>{info.businessName}</Name>
        <Caption
          href={info?.domain}
          onClick={(e) => {
            navigateToDifferentSite(e, info?.domain);
          }}
        >
          {info?.domain}
        </Caption>

        <Options>
          {options.map(({ component, name, icon, page }, index) => {
            const isLastItem = options.length - 1 === index;
            return (
              <Fragment key={index}>
                {component ? (
                  component
                ) : (
                  <OptionItem onClick={() => navigate(page)}>
                    {icon}
                    <OptionText>{name}</OptionText>
                  </OptionItem>
                )}

                {!isLastItem ? (
                  <Divider orientation="vertical" variant="middle" flexItem />
                ) : null}
              </Fragment>
            );
          })}
        </Options>

        <Links>
          {links.map(({ title, caption, externalLink }, index) => {
            return (
              <LinkItem
                key={index}
                onClick={() => {
                  if (externalLink) {
                    window.open(externalLink);
                  }
                }}
              >
                {title && <LinkTitle>{title}</LinkTitle>}
                {caption && <LinkCaption>{caption}</LinkCaption>}
                <ChevronIcon />
              </LinkItem>
            );
          })}
        </Links>
      </AccountProfile>
    </Container>
  );
}
