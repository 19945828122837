import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import { Avatar, CircularProgress } from '@mui/material';
import fallBackIcon from 'assets/images/icon-fallback.png';
import { Storage } from 'aws-amplify';
import { useFileChange } from 'hooks/Common/useFileChange';
import { useUploadToS3 } from 'hooks/Common/useUploadFile';
import { useEffect, useState } from 'react';
import { themes } from 'theme';

import {
  Logo,
  StyledBadge,
  UploadSectionContainer,
} from './UploadSectionStyled';

type UploadSectionProps = {
  errors: any;
  setFieldValue: (name: string, value: string) => void;
  name: string;
  value: string;
  typeAllow: string[];
};

const UploadSection = ({
  errors,
  setFieldValue,
  name,
  value,
  typeAllow,
}: UploadSectionProps) => {
  const { fileContents, fileType, fileName, handleFileChange } = useFileChange(
    typeAllow /* ['video', 'image'] */,
  );
  const [imageUrl, setImageUrl] = useState(fallBackIcon);
  const { mutateAsync, isLoading } = useUploadToS3();
  useEffect(() => {
    const handleUpload = async () => {
      try {
        if (fileType && fileContents) {
          const response = await mutateAsync({
            fileName: fileName ?? '',
            fileType,
            fileContents,
          });
          setFieldValue(name, response);
        }
      } catch (error) {
        console.log(error);
      }
    };
    handleUpload();
  }, [fileType, fileContents, mutateAsync, setFieldValue, name, fileName]);

  useEffect(() => {
    const fetchUrlImage = async () => {
      try {
        if (value) {
          const url = value?.includes('http')
            ? value
            : await Storage.get(value);
          setImageUrl(url);
        }
      } catch (err) {}
    };
    fetchUrlImage();
  }, [value]);

  return (
    <UploadSectionContainer>
      <StyledBadge
        badgeContent={
          <>
            <label style={{ cursor: 'pointer' }}>
              <input
                style={{ display: 'none' }}
                name="upload-file"
                type="file"
                onChange={handleFileChange}
              />
              {isLoading ? (
                <CircularProgress />
              ) : value ? (
                <EditIcon
                  style={{
                    fontSize: '24px',
                  }}
                />
              ) : (
                <AddIcon
                  style={{
                    fontSize: '30px',
                  }}
                />
              )}
            </label>
          </>
        }
        color="secondary"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <Avatar
          sx={{
            bgcolor: themes.light.colorWhite,
            width: '200px',
            height: '200px',
            border: '4px solid black',
          }}
        >
          <Logo
            sx={{
              objectFit: 'cover',
              scale: imageUrl !== fallBackIcon ? '1' : '0.5',
            }}
            src={imageUrl}
          />
        </Avatar>
      </StyledBadge>
    </UploadSectionContainer>
  );
};

export default UploadSection;
