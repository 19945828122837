import EditIcon from '@mui/icons-material/Edit';
import { Avatar, Box, IconButton, styled } from '@mui/material';
import { Storage } from 'aws-amplify';
import { useEffect, useState } from 'react';
import FallbackImage from '../../../assets/images/icon-fallback.png';
import { themes } from 'theme';

type AvatarWithFallbackProps = {
  image?: string;
  width?: number;
  height?: number;
  onClickEdit?: Function;
  avatarVariant?: AvatarVariantType;
  fromCDN?: boolean;
  hasBorder?: boolean;
};

const CustomAvatar = styled(Avatar, {
  shouldForwardProp: (prop) => prop !== 'hasBorder',
})<{ hasBorder?: boolean }>(({ hasBorder }) => ({
  border: hasBorder ? `1px solid ${themes.light.borderColor}` : 'none',
  '&.MuiAvatar-root': {
    '& > img': {
      // border: hasBorder
      //   ? `4px solid ${themes.light.colorMediumTurquoise}`
      //   : `1px solid ${themes.light.borderColor}`,
      objectFit: 'contain',
    },
  },
}));

const FallBackAvatar = styled(CustomAvatar)({
  '& img': {
    width: '60%',
    height: '60%',
  },
});

const StyledIconButton = styled(IconButton)({
  position: 'absolute',
  width: 20,
  height: 20,
  bottom: 2,
  right: 2,
  backgroundColor: 'black',
  '&:hover': {
    backgroundColor: 'black',
  },
});

const AvatarWithFallback = ({
  image,
  width,
  height,
  onClickEdit,
  avatarVariant = 'circular',
  fromCDN = false,
  hasBorder = false,
}: AvatarWithFallbackProps) => {
  const [url, setUrl] = useState(FallbackImage);
  const [isFallback, setIsFallback] = useState(true);
  useEffect(() => {
    const fetchUrlImage = async () => {
      try {
        if (image) {
          if (fromCDN) {
            setUrl(image);
          } else {
            const url = await Storage.get(image);
            setUrl(url);
          }
          setIsFallback(false);
        } else {
          setUrl(FallbackImage);
        }
      } catch (err) {
        setUrl(FallbackImage);
      }
    };
    fetchUrlImage();
  }, [fromCDN, image]);
  const errorHandler = () => {
    setUrl(FallbackImage);
    setIsFallback(true);
  };

  const styleSize =
    !height || !width ? { width: '100%', height: '100%' } : { width, height };

  return (
    <Box sx={{ position: 'relative' }}>
      {isFallback ? (
        <FallBackAvatar
          variant={avatarVariant}
          src={url}
          imgProps={{
            onError: errorHandler,
          }}
          sx={styleSize}
          hasBorder={hasBorder}
          className={isFallback ? 'fallback' : ''}
        />
      ) : (
        <CustomAvatar
          variant={avatarVariant}
          src={url}
          imgProps={{
            onError: errorHandler,
          }}
          hasBorder={hasBorder}
          sx={styleSize}
          className={isFallback ? 'fallback' : ''}
        />
      )}
      {onClickEdit && (
        <StyledIconButton onClick={() => onClickEdit()}>
          <EditIcon sx={{ width: 15, color: 'white' }} />
        </StyledIconButton>
      )}
    </Box>
  );
};

export default AvatarWithFallback;
