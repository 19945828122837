export type CountryType = {
  code: string;
  label: string;
  phone: string;
  suggested?: boolean;
  value: string;
};

export const countries: readonly CountryType[] = [
  {
    code: 'AD',
    label: 'Andorra',
    phone: '376',
    value: 'AD',
  },
  {
    code: 'AE',
    label: 'United Arab Emirates',
    phone: '971',
    value: 'AE',
  },
  {
    code: 'AF',
    label: 'Afghanistan',
    phone: '93',
    value: 'AF',
  },
  {
    code: 'AG',
    label: 'Antigua and Barbuda',
    phone: '1-268',
    value: 'AG',
  },
  {
    code: 'AI',
    label: 'Anguilla',
    phone: '1-264',
    value: 'AI',
  },
  {
    code: 'AL',
    label: 'Albania',
    phone: '355',
    value: 'AL',
  },
  {
    code: 'AM',
    label: 'Armenia',
    phone: '374',
    value: 'AM',
  },
  {
    code: 'AO',
    label: 'Angola',
    phone: '244',
    value: 'AO',
  },
  {
    code: 'AQ',
    label: 'Antarctica',
    phone: '672',
    value: 'AQ',
  },
  {
    code: 'AR',
    label: 'Argentina',
    phone: '54',
    value: 'AR',
  },
  {
    code: 'AS',
    label: 'American Samoa',
    phone: '1-684',
    value: 'AS',
  },
  {
    code: 'AT',
    label: 'Austria',
    phone: '43',
    value: 'AT',
  },
  {
    code: 'AU',
    label: 'Australia',
    phone: '61',
    suggested: true,
    value: 'AU',
  },
  {
    code: 'AW',
    label: 'Aruba',
    phone: '297',
    value: 'AW',
  },
  {
    code: 'AX',
    label: 'Alland Islands',
    phone: '358',
    value: 'AX',
  },
  {
    code: 'AZ',
    label: 'Azerbaijan',
    phone: '994',
    value: 'AZ',
  },
  {
    code: 'BA',
    label: 'Bosnia and Herzegovina',
    phone: '387',
    value: 'BA',
  },
  {
    code: 'BB',
    label: 'Barbados',
    phone: '1-246',
    value: 'BB',
  },
  {
    code: 'BD',
    label: 'Bangladesh',
    phone: '880',
    value: 'BD',
  },
  {
    code: 'BE',
    label: 'Belgium',
    phone: '32',
    value: 'BE',
  },
  {
    code: 'BF',
    label: 'Burkina Faso',
    phone: '226',
    value: 'BF',
  },
  {
    code: 'BG',
    label: 'Bulgaria',
    phone: '359',
    value: 'BG',
  },
  {
    code: 'BH',
    label: 'Bahrain',
    phone: '973',
    value: 'BH',
  },
  {
    code: 'BI',
    label: 'Burundi',
    phone: '257',
    value: 'BI',
  },
  {
    code: 'BJ',
    label: 'Benin',
    phone: '229',
    value: 'BJ',
  },
  {
    code: 'BL',
    label: 'Saint Barthelemy',
    phone: '590',
    value: 'BL',
  },
  {
    code: 'BM',
    label: 'Bermuda',
    phone: '1-441',
    value: 'BM',
  },
  {
    code: 'BN',
    label: 'Brunei Darussalam',
    phone: '673',
    value: 'BN',
  },
  {
    code: 'BO',
    label: 'Bolivia',
    phone: '591',
    value: 'BO',
  },
  {
    code: 'BR',
    label: 'Brazil',
    phone: '55',
    value: 'BR',
  },
  {
    code: 'BS',
    label: 'Bahamas',
    phone: '1-242',
    value: 'BS',
  },
  {
    code: 'BT',
    label: 'Bhutan',
    phone: '975',
    value: 'BT',
  },
  {
    code: 'BV',
    label: 'Bouvet Island',
    phone: '47',
    value: 'BV',
  },
  {
    code: 'BW',
    label: 'Botswana',
    phone: '267',
    value: 'BW',
  },
  {
    code: 'BY',
    label: 'Belarus',
    phone: '375',
    value: 'BY',
  },
  {
    code: 'BZ',
    label: 'Belize',
    phone: '501',
    value: 'BZ',
  },
  {
    code: 'CA',
    label: 'Canada',
    phone: '1',
    suggested: true,
    value: 'CA',
  },
  {
    code: 'CC',
    label: 'Cocos (Keeling) Islands',
    phone: '61',
    value: 'CC',
  },
  {
    code: 'CD',
    label: 'Congo, Democratic Republic of the',
    phone: '243',
    value: 'CD',
  },
  {
    code: 'CF',
    label: 'Central African Republic',
    phone: '236',
    value: 'CF',
  },
  {
    code: 'CG',
    label: 'Congo, Republic of the',
    phone: '242',
    value: 'CG',
  },
  {
    code: 'CH',
    label: 'Switzerland',
    phone: '41',
    value: 'CH',
  },
  {
    code: 'CI',
    // eslint-disable-next-line
    label: "Cote d'Ivoire",
    phone: '225',
    value: 'CI',
  },
  {
    code: 'CK',
    label: 'Cook Islands',
    phone: '682',
    value: 'CK',
  },
  {
    code: 'CL',
    label: 'Chile',
    phone: '56',
    value: 'CL',
  },
  {
    code: 'CM',
    label: 'Cameroon',
    phone: '237',
    value: 'CM',
  },
  {
    code: 'CN',
    label: 'China',
    phone: '86',
    value: 'CN',
  },
  {
    code: 'CO',
    label: 'Colombia',
    phone: '57',
    value: 'CO',
  },
  {
    code: 'CR',
    label: 'Costa Rica',
    phone: '506',
    value: 'CR',
  },
  {
    code: 'CU',
    label: 'Cuba',
    phone: '53',
    value: 'CU',
  },
  {
    code: 'CV',
    label: 'Cape Verde',
    phone: '238',
    value: 'CV',
  },
  {
    code: 'CW',
    label: 'Curacao',
    phone: '599',
    value: 'CW',
  },
  {
    code: 'CX',
    label: 'Christmas Island',
    phone: '61',
    value: 'CX',
  },
  {
    code: 'CY',
    label: 'Cyprus',
    phone: '357',
    value: 'CY',
  },
  {
    code: 'CZ',
    label: 'Czech Republic',
    phone: '420',
    value: 'CZ',
  },
  {
    code: 'DE',
    label: 'Germany',
    phone: '49',
    suggested: true,
    value: 'DE',
  },
  {
    code: 'DJ',
    label: 'Djibouti',
    phone: '253',
    value: 'DJ',
  },
  {
    code: 'DK',
    label: 'Denmark',
    phone: '45',
    value: 'DK',
  },
  {
    code: 'DM',
    label: 'Dominica',
    phone: '1-767',
    value: 'DM',
  },
  {
    code: 'DO',
    label: 'Dominican Republic',
    phone: '1-809',
    value: 'DO',
  },
  {
    code: 'DZ',
    label: 'Algeria',
    phone: '213',
    value: 'DZ',
  },
  {
    code: 'EC',
    label: 'Ecuador',
    phone: '593',
    value: 'EC',
  },
  {
    code: 'EE',
    label: 'Estonia',
    phone: '372',
    value: 'EE',
  },
  {
    code: 'EG',
    label: 'Egypt',
    phone: '20',
    value: 'EG',
  },
  {
    code: 'EH',
    label: 'Western Sahara',
    phone: '212',
    value: 'EH',
  },
  {
    code: 'ER',
    label: 'Eritrea',
    phone: '291',
    value: 'ER',
  },
  {
    code: 'ES',
    label: 'Spain',
    phone: '34',
    value: 'ES',
  },
  {
    code: 'ET',
    label: 'Ethiopia',
    phone: '251',
    value: 'ET',
  },
  {
    code: 'FI',
    label: 'Finland',
    phone: '358',
    value: 'FI',
  },
  {
    code: 'FJ',
    label: 'Fiji',
    phone: '679',
    value: 'FJ',
  },
  {
    code: 'FK',
    label: 'Falkland Islands (Malvinas)',
    phone: '500',
    value: 'FK',
  },
  {
    code: 'FM',
    label: 'Micronesia, Federated States of',
    phone: '691',
    value: 'FM',
  },
  {
    code: 'FO',
    label: 'Faroe Islands',
    phone: '298',
    value: 'FO',
  },
  {
    code: 'FR',
    label: 'France',
    phone: '33',
    suggested: true,
    value: 'FR',
  },
  {
    code: 'GA',
    label: 'Gabon',
    phone: '241',
    value: 'GA',
  },
  {
    code: 'GB',
    label: 'United Kingdom',
    phone: '44',
    value: 'GB',
  },
  {
    code: 'GD',
    label: 'Grenada',
    phone: '1-473',
    value: 'GD',
  },
  {
    code: 'GE',
    label: 'Georgia',
    phone: '995',
    value: 'GE',
  },
  {
    code: 'GF',
    label: 'French Guiana',
    phone: '594',
    value: 'GF',
  },
  {
    code: 'GG',
    label: 'Guernsey',
    phone: '44',
    value: 'GG',
  },
  {
    code: 'GH',
    label: 'Ghana',
    phone: '233',
    value: 'GH',
  },
  {
    code: 'GI',
    label: 'Gibraltar',
    phone: '350',
    value: 'GI',
  },
  {
    code: 'GL',
    label: 'Greenland',
    phone: '299',
    value: 'GL',
  },
  {
    code: 'GM',
    label: 'Gambia',
    phone: '220',
    value: 'GM',
  },
  {
    code: 'GN',
    label: 'Guinea',
    phone: '224',
    value: 'GN',
  },
  {
    code: 'GP',
    label: 'Guadeloupe',
    phone: '590',
    value: 'GP',
  },
  {
    code: 'GQ',
    label: 'Equatorial Guinea',
    phone: '240',
    value: 'GQ',
  },
  {
    code: 'GR',
    label: 'Greece',
    phone: '30',
    value: 'GR',
  },
  {
    code: 'GS',
    label: 'South Georgia and the South Sandwich Islands',
    phone: '500',
    value: 'GS',
  },
  {
    code: 'GT',
    label: 'Guatemala',
    phone: '502',
    value: 'GT',
  },
  {
    code: 'GU',
    label: 'Guam',
    phone: '1-671',
    value: 'GU',
  },
  {
    code: 'GW',
    label: 'Guinea-Bissau',
    phone: '245',
    value: 'GW',
  },
  {
    code: 'GY',
    label: 'Guyana',
    phone: '592',
    value: 'GY',
  },
  {
    code: 'HK',
    label: 'Hong Kong',
    phone: '852',
    value: 'HK',
  },
  {
    code: 'HM',
    label: 'Heard Island and McDonald Islands',
    phone: '672',
    value: 'HM',
  },
  {
    code: 'HN',
    label: 'Honduras',
    phone: '504',
    value: 'HN',
  },
  {
    code: 'HR',
    label: 'Croatia',
    phone: '385',
    value: 'HR',
  },
  {
    code: 'HT',
    label: 'Haiti',
    phone: '509',
    value: 'HT',
  },
  {
    code: 'HU',
    label: 'Hungary',
    phone: '36',
    value: 'HU',
  },
  {
    code: 'ID',
    label: 'Indonesia',
    phone: '62',
    value: 'ID',
  },
  {
    code: 'IE',
    label: 'Ireland',
    phone: '353',
    value: 'IE',
  },
  {
    code: 'IL',
    label: 'Israel',
    phone: '972',
    value: 'IL',
  },
  {
    code: 'IM',
    label: 'Isle of Man',
    phone: '44',
    value: 'IM',
  },
  {
    code: 'IN',
    label: 'India',
    phone: '91',
    value: 'IN',
  },
  {
    code: 'IO',
    label: 'British Indian Ocean Territory',
    phone: '246',
    value: 'IO',
  },
  {
    code: 'IQ',
    label: 'Iraq',
    phone: '964',
    value: 'IQ',
  },
  {
    code: 'IR',
    label: 'Iran, Islamic Republic of',
    phone: '98',
    value: 'IR',
  },
  {
    code: 'IS',
    label: 'Iceland',
    phone: '354',
    value: 'IS',
  },
  {
    code: 'IT',
    label: 'Italy',
    phone: '39',
    value: 'IT',
  },
  {
    code: 'JE',
    label: 'Jersey',
    phone: '44',
    value: 'JE',
  },
  {
    code: 'JM',
    label: 'Jamaica',
    phone: '1-876',
    value: 'JM',
  },
  {
    code: 'JO',
    label: 'Jordan',
    phone: '962',
    value: 'JO',
  },
  {
    code: 'JP',
    label: 'Japan',
    phone: '81',
    suggested: true,
    value: 'JP',
  },
  {
    code: 'KE',
    label: 'Kenya',
    phone: '254',
    value: 'KE',
  },
  {
    code: 'KG',
    label: 'Kyrgyzstan',
    phone: '996',
    value: 'KG',
  },
  {
    code: 'KH',
    label: 'Cambodia',
    phone: '855',
    value: 'KH',
  },
  {
    code: 'KI',
    label: 'Kiribati',
    phone: '686',
    value: 'KI',
  },
  {
    code: 'KM',
    label: 'Comoros',
    phone: '269',
    value: 'KM',
  },
  {
    code: 'KN',
    // eslint-disable-next-line
    label: 'Saint Kitts and Nevis',
    phone: '1-869',
    value: 'KN',
  },
  {
    code: 'KP',
    // eslint-disable-next-line
    label: "Korea, Democratic People's Republic of",
    phone: '850',
    value: 'KP',
  },
  {
    code: 'KR',
    label: 'Korea, Republic of',
    phone: '82',
    value: 'KR',
  },
  {
    code: 'KW',
    label: 'Kuwait',
    phone: '965',
    value: 'KW',
  },
  {
    code: 'KY',
    label: 'Cayman Islands',
    phone: '1-345',
    value: 'KY',
  },
  {
    code: 'KZ',
    label: 'Kazakhstan',
    phone: '7',
    value: 'KZ',
  },
  {
    code: 'LA',
    // eslint-disable-next-line
    label: "Lao People's Democratic Republic",
    phone: '856',
    value: 'LA',
  },
  {
    code: 'LB',
    label: 'Lebanon',
    phone: '961',
    value: 'LB',
  },
  {
    code: 'LC',
    label: 'Saint Lucia',
    phone: '1-758',
    value: 'LC',
  },
  {
    code: 'LI',
    label: 'Liechtenstein',
    phone: '423',
    value: 'LI',
  },
  {
    code: 'LK',
    label: 'Sri Lanka',
    phone: '94',
    value: 'LK',
  },
  {
    code: 'LR',
    label: 'Liberia',
    phone: '231',
    value: 'LR',
  },
  {
    code: 'LS',
    label: 'Lesotho',
    phone: '266',
    value: 'LS',
  },
  {
    code: 'LT',
    label: 'Lithuania',
    phone: '370',
    value: 'LT',
  },
  {
    code: 'LU',
    label: 'Luxembourg',
    phone: '352',
    value: 'LU',
  },
  {
    code: 'LV',
    label: 'Latvia',
    phone: '371',
    value: 'LV',
  },
  {
    code: 'LY',
    label: 'Libya',
    phone: '218',
    value: 'LY',
  },
  {
    code: 'MA',
    label: 'Morocco',
    phone: '212',
    value: 'MA',
  },
  {
    code: 'MC',
    label: 'Monaco',
    phone: '377',
    value: 'MC',
  },
  {
    code: 'MD',
    label: 'Moldova, Republic of',
    phone: '373',
    value: 'MD',
  },
  {
    code: 'ME',
    label: 'Montenegro',
    phone: '382',
    value: 'ME',
  },
  {
    code: 'MF',
    label: 'Saint Martin (French part)',
    phone: '590',
    value: 'MF',
  },
  {
    code: 'MG',
    label: 'Madagascar',
    phone: '261',
    value: 'MG',
  },
  {
    code: 'MH',
    label: 'Marshall Islands',
    phone: '692',
    value: 'MH',
  },
  {
    code: 'MK',
    label: 'Macedonia, the Former Yugoslav Republic of',
    phone: '389',
    value: 'MK',
  },
  {
    code: 'ML',
    label: 'Mali',
    phone: '223',
    value: 'ML',
  },
  {
    code: 'MM',
    label: 'Myanmar',
    phone: '95',
    value: 'MM',
  },
  {
    code: 'MN',
    label: 'Mongolia',
    phone: '976',
    value: 'MN',
  },
  {
    code: 'MO',
    label: 'Macao',
    phone: '853',
    value: 'MO',
  },
  {
    code: 'MP',
    label: 'Northern Mariana Islands',
    phone: '1-670',
    value: 'MP',
  },
  {
    code: 'MQ',
    label: 'Martinique',
    phone: '596',
    value: 'MQ',
  },
  {
    code: 'MR',
    label: 'Mauritania',
    phone: '222',
    value: 'MR',
  },
  {
    code: 'MS',
    label: 'Montserrat',
    phone: '1-664',
    value: 'MS',
  },
  {
    code: 'MT',
    label: 'Malta',
    phone: '356',
    value: 'MT',
  },
  {
    code: 'MU',
    label: 'Mauritius',
    phone: '230',
    value: 'MU',
  },
  {
    code: 'MV',
    label: 'Maldives',
    phone: '960',
    value: 'MV',
  },
  {
    code: 'MW',
    label: 'Malawi',
    phone: '265',
    value: 'MW',
  },
  {
    code: 'MX',
    label: 'Mexico',
    phone: '52',
    value: 'MX',
  },
  {
    code: 'MY',
    label: 'Malaysia',
    phone: '60',
    value: 'MY',
  },
  {
    code: 'MZ',
    label: 'Mozambique',
    phone: '258',
    value: 'MZ',
  },
  {
    code: 'NA',
    label: 'Namibia',
    phone: '264',
    value: 'NA',
  },
  {
    code: 'NC',
    label: 'New Caledonia',
    phone: '687',
    value: 'NC',
  },
  {
    code: 'NE',
    label: 'Niger',
    phone: '227',
    value: 'NE',
  },
  {
    code: 'NF',
    label: 'Norfolk Island',
    phone: '672',
    value: 'NF',
  },
  {
    code: 'NG',
    label: 'Nigeria',
    phone: '234',
    value: 'NG',
  },
  {
    code: 'NI',
    label: 'Nicaragua',
    phone: '505',
    value: 'NI',
  },
  {
    code: 'NL',
    label: 'Netherlands',
    phone: '31',
    value: 'NL',
  },
  {
    code: 'NO',
    label: 'Norway',
    phone: '47',
    value: 'NO',
  },
  {
    code: 'NP',
    label: 'Nepal',
    phone: '977',
    value: 'NP',
  },
  {
    code: 'NR',
    label: 'Nauru',
    phone: '674',
    value: 'NR',
  },
  {
    code: 'NU',
    label: 'Niue',
    phone: '683',
    value: 'NU',
  },
  {
    code: 'NZ',
    label: 'New Zealand',
    phone: '64',
    value: 'NZ',
  },
  {
    code: 'OM',
    label: 'Oman',
    phone: '968',
    value: 'OM',
  },
  {
    code: 'PA',
    label: 'Panama',
    phone: '507',
    value: 'PA',
  },
  {
    code: 'PE',
    label: 'Peru',
    phone: '51',
    value: 'PE',
  },
  {
    code: 'PF',
    label: 'French Polynesia',
    phone: '689',
    value: 'PF',
  },
  {
    code: 'PG',
    label: 'Papua New Guinea',
    phone: '675',
    value: 'PG',
  },
  {
    code: 'PH',
    label: 'Philippines',
    phone: '63',
    value: 'PH',
  },
  {
    code: 'PK',
    label: 'Pakistan',
    phone: '92',
    value: 'PK',
  },
  {
    code: 'PL',
    label: 'Poland',
    phone: '48',
    value: 'PL',
  },
  {
    code: 'PM',
    label: 'Saint Pierre and Miquelon',
    phone: '508',
    value: 'PM',
  },
  {
    code: 'PN',
    label: 'Pitcairn',
    phone: '870',
    value: 'PN',
  },
  {
    code: 'PR',
    label: 'Puerto Rico',
    phone: '1',
    value: 'PR',
  },
  {
    code: 'PS',
    label: 'Palestine, State of',
    phone: '970',
    value: 'PS',
  },
  {
    code: 'PT',
    label: 'Portugal',
    phone: '351',
    value: 'PT',
  },
  {
    code: 'PW',
    label: 'Palau',
    phone: '680',
    value: 'PW',
  },
  {
    code: 'PY',
    label: 'Paraguay',
    phone: '595',
    value: 'PY',
  },
  {
    code: 'QA',
    label: 'Qatar',
    phone: '974',
    value: 'QA',
  },
  {
    code: 'RE',
    label: 'Reunion',
    phone: '262',
    value: 'RE',
  },
  {
    code: 'RO',
    label: 'Romania',
    phone: '40',
    value: 'RO',
  },
  {
    code: 'RS',
    label: 'Serbia',
    phone: '381',
    value: 'RS',
  },
  {
    code: 'RU',
    label: 'Russian Federation',
    phone: '7',
    value: 'RU',
  },
  {
    code: 'RW',
    label: 'Rwanda',
    phone: '250',
    value: 'RW',
  },
  {
    code: 'SA',
    label: 'Saudi Arabia',
    phone: '966',
    value: 'SA',
  },
  {
    code: 'SB',
    label: 'Solomon Islands',
    phone: '677',
    value: 'SB',
  },
  {
    code: 'SC',
    label: 'Seychelles',
    phone: '248',
    value: 'SC',
  },
  {
    code: 'SD',
    label: 'Sudan',
    phone: '249',
    value: 'SD',
  },
  {
    code: 'SE',
    label: 'Sweden',
    phone: '46',
    value: 'SE',
  },
  {
    code: 'SG',
    label: 'Singapore',
    phone: '65',
    value: 'SG',
  },
  {
    code: 'SH',
    label: 'Saint Helena',
    phone: '290',
    value: 'SH',
  },
  {
    code: 'SI',
    label: 'Slovenia',
    phone: '386',
    value: 'SI',
  },
  {
    code: 'SJ',
    label: 'Svalbard and Jan Mayen',
    phone: '47',
    value: 'SJ',
  },
  {
    code: 'SK',
    label: 'Slovakia',
    phone: '421',
    value: 'SK',
  },
  {
    code: 'SL',
    label: 'Sierra Leone',
    phone: '232',
    value: 'SL',
  },
  {
    code: 'SM',
    label: 'San Marino',
    phone: '378',
    value: 'SM',
  },
  {
    code: 'SN',
    label: 'Senegal',
    phone: '221',
    value: 'SN',
  },
  {
    code: 'SO',
    label: 'Somalia',
    phone: '252',
    value: 'SO',
  },
  {
    code: 'SR',
    label: 'Suriname',
    phone: '597',
    value: 'SR',
  },
  {
    code: 'SS',
    label: 'South Sudan',
    phone: '211',
    value: 'SS',
  },
  {
    code: 'ST',
    label: 'Sao Tome and Principe',
    phone: '239',
    value: 'ST',
  },
  {
    code: 'SV',
    label: 'El Salvador',
    phone: '503',
    value: 'SV',
  },
  {
    code: 'SX',
    label: 'Sint Maarten (Dutch part)',
    phone: '1-721',
    value: 'SX',
  },
  {
    code: 'SY',
    label: 'Syrian Arab Republic',
    phone: '963',
    value: 'SY',
  },
  {
    code: 'SZ',
    label: 'Swaziland',
    phone: '268',
    value: 'SZ',
  },
  {
    code: 'TC',
    label: 'Turks and Caicos Islands',
    phone: '1-649',
    value: 'TC',
  },
  {
    code: 'TD',
    label: 'Chad',
    phone: '235',
    value: 'TD',
  },
  {
    code: 'TF',
    label: 'French Southern Territories',
    phone: '262',
    value: 'TF',
  },
  {
    code: 'TG',
    label: 'Togo',
    phone: '228',
    value: 'TG',
  },
  {
    code: 'TH',
    label: 'Thailand',
    phone: '66',
    value: 'TH',
  },
  {
    code: 'TJ',
    label: 'Tajikistan',
    phone: '992',
    value: 'TJ',
  },
  {
    code: 'TK',
    label: 'Tokelau',
    phone: '690',
    value: 'TK',
  },
  {
    code: 'TL',
    label: 'Timor-Leste',
    phone: '670',
    value: 'TL',
  },
  {
    code: 'TM',
    label: 'Turkmenistan',
    phone: '993',
    value: 'TM',
  },
  {
    code: 'TN',
    label: 'Tunisia',
    phone: '216',
    value: 'TN',
  },
  {
    code: 'TO',
    label: 'Tonga',
    phone: '676',
    value: 'TO',
  },
  {
    code: 'TR',
    label: 'Turkey',
    phone: '90',
    value: 'TR',
  },
  {
    code: 'TT',
    label: 'Trinidad and Tobago',
    phone: '1-868',
    value: 'TT',
  },
  {
    code: 'TV',
    label: 'Tuvalu',
    phone: '688',
    value: 'TV',
  },
  {
    code: 'TW',
    label: 'Taiwan, Province of China',
    phone: '886',
    value: 'TW',
  },
  {
    code: 'TZ',
    label: 'United Republic of Tanzania',
    phone: '255',
    value: 'TZ',
  },
  {
    code: 'UA',
    label: 'Ukraine',
    phone: '380',
    value: 'UA',
  },
  {
    code: 'UG',
    label: 'Uganda',
    phone: '256',
    value: 'UG',
  },
  {
    code: 'US',
    label: 'United States',
    phone: '1',
    suggested: true,
    value: 'US',
  },
  {
    code: 'UY',
    label: 'Uruguay',
    phone: '598',
    value: 'UY',
  },
  {
    code: 'UZ',
    label: 'Uzbekistan',
    phone: '998',
    value: 'UZ',
  },
  {
    code: 'VA',
    label: 'Holy See (Vatican City State)',
    phone: '379',
    value: 'VA',
  },
  {
    code: 'VC',
    label: 'Saint Vincent and the Grenadines',
    phone: '1-784',
    value: 'VC',
  },
  {
    code: 'VE',
    label: 'Venezuela',
    phone: '58',
    value: 'VE',
  },
  {
    code: 'VG',
    label: 'British Virgin Islands',
    phone: '1-284',
    value: 'VG',
  },
  {
    code: 'VI',
    label: 'US Virgin Islands',
    phone: '1-340',
    value: 'VI',
  },
  {
    code: 'VN',
    label: 'Vietnam',
    phone: '84',
    value: 'VN',
  },
  {
    code: 'VU',
    label: 'Vanuatu',
    phone: '678',
    value: 'VU',
  },
  {
    code: 'WF',
    label: 'Wallis and Futuna',
    phone: '681',
    value: 'WF',
  },
  {
    code: 'WS',
    label: 'Samoa',
    phone: '685',
    value: 'WS',
  },
  {
    code: 'XK',
    label: 'Kosovo',
    phone: '383',
    value: 'XK',
  },
  {
    code: 'YE',
    label: 'Yemen',
    phone: '967',
    value: 'YE',
  },
  {
    code: 'YT',
    label: 'Mayotte',
    phone: '262',
    value: 'YT',
  },
  {
    code: 'ZA',
    label: 'South Africa',
    phone: '27',
    value: 'ZA',
  },
  {
    code: 'ZM',
    label: 'Zambia',
    phone: '260',
    value: 'ZM',
  },
  {
    code: 'ZW',
    label: 'Zimbabwe',
    phone: '263',
    value: 'ZW',
  },
];
