import { Box, Typography, Button, styled, Grid, Stack } from '@mui/material';

export const Container = styled(Box)`
  padding: 56px;
  font-family: 'Montserrat';
  min-height: calc(100% - 80px);
  > .MuiBox-root:last-of-type {
    margin-top: 64px;
  }
  ${(props) => props.theme.breakpoints.down('sm')} {
    padding: 30px;
  }
`;

export const BackDropContainer = styled(Container)({
  height: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

export const StyledStack = styled(Stack)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '4rem',
  [theme.breakpoints.down('lg')]: {
    flexDirection: 'column',
    gap: '1rem',
  },
}));

export const ImageSection = styled(Box)(({ theme }) => ({
  maxHeight: '100%',
  maxWidth: 550,
  minWidth: 500,
  [theme.breakpoints.down('lg')]: {
    maxHeight: 440,
    '& img': {
      maxHeight: 320,
    },
  },
  '& .fallback': {
    padding: '1rem',
  },
}));

export const ReferralItem = styled(Box)`
  display: grid;
  grid-template-columns: auto 1fr;
`;

export const ImageContainerGrid = styled(Grid)`
  display: flex;
  justify-content: flex-start;
  max-width: 440px !important;
  margin-right: 30px;
  height: 100%;
  ${(props) => props.theme.breakpoints.down('md')} {
    max-width: 100% !important;
    max-height: 218px;
    justify-content: center;
    margin-right: 0px;
  }
`;

export const ImageContainer = styled(Box)`
  width: 400px;
  height: 400px;
  background: #c4c4c4;
  border-radius: 8px;
  ${(props) => props.theme.breakpoints.down(1000)} {
    width: 350px;
    height: 350px;
  }
  ${(props) => props.theme.breakpoints.down('sm')} {
    width: 300px;
    height: 300px;
  }
  ${(props) => props.theme.breakpoints.down(450)} {
    width: 250px;
    height: 250px;
  }
`;

export const DetailsContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  & > .MuiBox-root:first-of-type {
    margin-top: 32px;
  }
  & > .MuiBox-root:first-of-type > .MuiTypography-root:first-of-type {
    margin: unset;
  }
`;

export const Title = styled(Typography)`
  font-weight: 600;
  font-size: 40px;
  line-height: 49px;
  color: #000000;
  ${(props) => props.theme.breakpoints.down('md')} {
    margin-top: 10px;
    text-align: center;
  }
  ${(props) => props.theme.breakpoints.down('sm')} {
    font-size: 30px;
  }
  ${(props) => props.theme.breakpoints.down(450)} {
    font-size: 26px;
  }
`;

export const Caption = styled(Typography)`
  margin-top: 16px;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
`;

export const Subtitle = styled(Caption)`
  ${(props) => props.theme.breakpoints.down('md')} {
    text-align: center;
  }
`;

export const Description = styled(Typography)`
  margin-top: 4px;
  width: 100%;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  ${(props) => props.theme.breakpoints.down('sm')} {
    width: 100%;
  }
`;

export const ProfileAndDate = styled(Box)`
  margin-top: 32px;
  display: flex;
  gap: 42px;
  > .MuiBox-root:nth-of-type(2) {
    justify-content: center;
  }
  > .MuiBox-root:nth-of-type(2) > .MuiTypography-root {
    margin-top: 4px;
  }
`;

export const Profile = styled(Box)`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 14px;
  > .MuiBox-root {
    justify-content: center;
  }
  > .MuiBox-root > .MuiTypography-root:nth-of-type(2) {
    margin-top: 4px;
  }
`;

export const ProfileImage = styled('span')`
  width: 48px;
  height: 48px;
  background: #c4c4c4;
  border-radius: 50%;
`;

export const Label = styled(Typography)`
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #999999;
`;

export const ButtonGroup = styled(Box)`
  margin-top: 82px;
  width: 100%;
  height: 46px;
  display: flex;
  gap: 17.29px;
  > .MuiButtonBase-root {
    width: 50%;
  }
  ${(props) => props.theme.breakpoints.down(1000)} {
    margin-top: 40px;
    width: 100%;
  }
  ${(props) => props.theme.breakpoints.down('sm')} {
    margin-top: 20px;
    margin-bottom: 80px;
    flex-direction: column;
    gap: 0px;
    > .MuiButtonBase-root {
      width: 100%;
      margin-top: 17px;
      padding: 13px 0px;
    }
  }
`;

export const PrimaryButton = styled(Button)`
  border-radius: 4px;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  background: #2cccd3;
  color: #ffffff;
  &:hover {
    background: #44d7dd;
    color: #ffffff;
  }
`;

export const SecondaryButton = styled(Button)`
  border-radius: 4px;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  background: #909090;
  color: #fcfcfc;
  &:hover {
    background: #9c9b9b;
    color: #fcfcfc;
  }
`;
