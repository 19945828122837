import CloseIcon from '@mui/icons-material/Close';
import React, { ReactNode } from 'react';
import { ToastContentProps } from 'react-toastify';
import { themes } from 'theme';

import { AlertBox, AlertText, CloseButton } from './ToastMessageStyled';

export const ToastType = {
  SUCCESS: {
    type: 'SUCCESS',
    styled: {
      color: themes.light.colorWhite,
      backgroundColor: themes.light.backgroundColorBlack,
    },
  },
  ERROR: {
    type: 'ERROR',
    styled: {
      color: themes.light.colorWhite,
      backgroundColor: themes.light.backgroundColorBlack,
    },
  },
  WARNING: {
    type: 'WARNING',
    styled: {
      color: themes.light.colorWhite,
      backgroundColor: themes.light.backgroundColorBlack,
    },
  },
};

type ToastMessageProps = {
  type: string;
  text: string | ReactNode;
};

const ToastMessage = ({
  closeToast,
  toastProps,
  type = ToastType.SUCCESS.type,
  text,
}: Partial<ToastContentProps & ToastMessageProps>) => {
  // @ts-ignore
  const styled = ToastType[type]?.styled ?? ToastType.SUCCESS.styled;
  return (
    <AlertBox sx={styled}>
      <AlertText>{text}</AlertText>
      <CloseButton onClick={closeToast}>
        <CloseIcon
          style={{ color: themes.light.colorWhite, fontSize: '20px' }}
        />
      </CloseButton>
    </AlertBox>
  );
};

export default ToastMessage;
