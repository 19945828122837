import { Box, Grid, styled, Typography } from '@mui/material';

export const TotalPerformanceContainer = styled(Box)`
  margin-top: 52px;
  box-shadow: 0px 0px 5px rgba(83, 95, 120, 0.102109);
  border-radius: 4px;
  background-color: #ffffff;
`;

export const TotalPerformanceHeader = styled(Box)`
  padding: 12px;
  padding-left: 23.85px;
  box-shadow: inset 0px -1px 0px #dee7fa;
  border-radius: 8px 8px 0px 0px;
`;

export const TotalPerformanceHeaderText = styled(Typography)`
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #313131;
`;

export const ContentContainer = styled(Box)`
  padding: 16px 24px 24px;
  display: grid;
  grid-template-columns: repeat(4, 308px);
  gap: 16px;
`;

export const GridContainer = styled(Grid)`
  padding: 16px 24px 24px;
  ${(props) => props.theme.breakpoints.down('sm')} {
    padding: 16px 0px 0px 0px;
  }
`;

export const Content = styled(Box)`
  padding: 16px;
  display: flex;
  flex-direction: column;
  border: 1px solid #dee7fa;
  border-radius: 4px;
  position: relative;

  & > .MuiBox-root {
    position: absolute;
    top: 8px;
    right: 20px;
  }

  ${(props) => props.theme.breakpoints.down('sm')} {
    display: none;
  }
`;

export const MobileContent = styled(Box)`
  display: none;
  & > .MuiTypography-root {
    text-align: center;
  }
  ${(props) => props.theme.breakpoints.down('sm')} {
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #dee7fa;
    border-radius: 4px;
    position: relative;
  }
`;

export const Caption = styled(Typography)`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #646464;
`;

export const Main = styled(Typography)`
  margin-top: 4px;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #313131;
  ${(props) => props.theme.breakpoints.down('sm')} {
    font-size: 18px;
  }
`;

export const SubMain = styled(Main)`
  font-size: 18px;
  ${(props) => props.theme.breakpoints.down('sm')} {
    font-size: 16px;
  }
` as typeof Typography;

export const Footer = styled(Typography)`
  margin-top: 13px;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #1ead54;
`;

export const IconContainer = styled(Box)`
  width: 64px;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
