import { useState, useEffect } from 'react';

const INITIAL_COUNT_DOWN_TIME = 20;

const useCounter = (initialValue = INITIAL_COUNT_DOWN_TIME) => {
  const [counter, setCounter] = useState(initialValue);
  const resetCounter = () => {
    setCounter(INITIAL_COUNT_DOWN_TIME);
  };
  useEffect(() => {
    let timer: ReturnType<typeof setInterval> | undefined;
    timer =
      counter > -1
        ? setInterval(() => setCounter(counter - 1), 1000)
        : undefined;
    return () => clearInterval(timer);
  }, [counter]);
  return { counter, setCounter, resetCounter };
};

export default useCounter;
