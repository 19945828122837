import { Stack, styled } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { themes } from 'theme';

import { Text72Weight600 } from '../Common/Styled/TypographyStyed';

const StyledContainer = styled(Stack)({
  flexDirection: 'row',
  flexWrap: 'wrap',
  height: '100vh',
  width: '100%',
});

const LeftItem = styled(Stack)(({ theme }) => ({
  flex: '1 40%',
  backgroundColor: themes.light.backgroundColorBlack,
  justifyContent: 'center',
  alignItems: 'center',
  padding: '0 70px',
  [theme.breakpoints.down('lg')]: {
    display: 'none',
  },
}));

const RightItem = styled(Stack)({
  flex: '1 60%',
  backgroundColor: themes.light.backgroundColorWhite,
  justifyContent: 'center',
  alignItems: 'center',
});

const OutsideLayout = () => (
  <StyledContainer>
    <LeftItem>
      <Stack>
        <Text72Weight600 sx={{ color: themes.light.colorWhite }}>
          Connect.
        </Text72Weight600>
        <Text72Weight600 sx={{ color: themes.light.colorWhite }}>
          Be Referred.
        </Text72Weight600>
        <Text72Weight600 sx={{ color: themes.light.colorMaximumBlueGreen }}>
          Earn.
        </Text72Weight600>
      </Stack>
    </LeftItem>
    <RightItem>
      <Outlet />
    </RightItem>
  </StyledContainer>
);

export default OutsideLayout;
