// @ts-nocheck
import { Autocomplete, styled, TextField } from '@mui/material';
import { useField } from 'formik';
import { useDebounce } from 'hooks/Common/useDebounce';
import { useCallback, useEffect } from 'react';
import { themes } from 'theme';
import usePlacesAutocomplete, {
  getDetails,
  getGeocode,
} from 'use-places-autocomplete';

const StyledField = styled(TextField)({
  '& .MuiInputLabel-root': {
    '&.Mui-focused': {
      color: themes.light.colorBlack,
    },
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      border: `1px solid ${themes.light.borderColor}`,
      borderRadius: '4px',
    },
    '&.Mui-focused fieldset': {
      borderColor: themes.light.colorMaximumBlueGreen,
    },
  },
});

function placeToAddress(place) {
  var address = {};
  place.address_components.forEach(function (c) {
    switch (c.types[0]) {
      case 'street_number':
        address.streetNumber = c;
        break;
      case 'route':
        address.streetName = c;
        break;
      case 'neighborhood':
      case 'locality': // North Hollywood or Los Angeles?
        address.city = c;
        break;
      case 'administrative_area_level_1': //  Note some countries don't have states
        address.state = c;
        break;
      case 'postal_code':
        address.postal_code = c;
        break;
      case 'country':
        address.country = c;
        break;
      /*
       *   . . .
       */
    }
  });

  return address;
}

export type AddressType = {
  line1: string;
  line2?: string;
  city: string;
  state: string;
  stateShortVersion?: string;
  country: string;
  countryShortVersion?: string;
  postal_code: string;
  lat?: number;
  lng?: number;
};

const SearchAddress = (props: any) => {
  const { parentName, name, label, onHandleSetFormikValue, ...rest } = props;
  const [, meta] = useField(name);
  const {
    ready,
    value: suggestionValue,
    setValue: setSuggestionValue,
    suggestions: { status, data },
    clearSuggestions,
  } = usePlacesAutocomplete({
    defaultValue: meta.value ?? '',
    requestOptions: { language: 'en-AU' },
  });
  useEffect(() => {
    if (meta.value) {
      setSuggestionValue(meta.value, false);
    }
  }, [meta, setSuggestionValue]);

  const onHandleChange = async (e: any, value: any) => {
    let address: AddressType = {
      line1: '',
      city: '',
      state: '',
      stateShortVersion: '',
      postal_code: '',
      country: '',
      countryShortVersion: '',
      lat: 0,
      lng: 0,
    };
    setSuggestionValue('');
    clearSuggestions();
    if (!value) {
      onHandleSetFormikValue && onHandleSetFormikValue(address);
      return;
    }

    const results = await getGeocode({ address: value.description });
    const place = await getDetails({
      placeId: results[0].place_id,
      fields: ['name', 'place_id', 'geometry', 'address_components'],
    });
    const parsedAddress = placeToAddress(place);
    address.line1 = `${parsedAddress?.streetNumber?.long_name ?? ''} ${
      parsedAddress?.streetName?.long_name ?? ''
    }`.trim();
    address.city = parsedAddress?.city?.long_name ?? '';
    address.state = parsedAddress?.state?.long_name ?? '';
    address.stateShortVersion = parsedAddress?.state?.short_name ?? '';
    address.country = parsedAddress?.country?.short_name ?? '';
    address.countryShortVersion = parsedAddress?.country?.short_name ?? '';
    address.postal_code = parsedAddress?.postal_code?.long_name ?? '';
    address.lat = place?.geometry?.location?.lat();
    address.lng = place?.geometry?.location?.lng();

    onHandleSetFormikValue && onHandleSetFormikValue(address);
    setSuggestionValue(address.line1, false);
  };
  const handleDebounceFn = useCallback(
    (newInputValue: string) => {
      setSuggestionValue(newInputValue);
    },
    [setSuggestionValue],
  );
  const handleChangeInput = useDebounce(handleDebounceFn);
  return (
    <Autocomplete
      disablePortal
      getOptionLabel={(option: any) =>
        typeof option === 'string' ? option : option.description
      }
      disabled={!ready}
      onInputChange={(event: any, newInputValue: string) => {
        handleChangeInput(newInputValue);
      }}
      onChange={onHandleChange}
      fullWidth
      filterOptions={(x) => x}
      value={suggestionValue}
      id="combo-box-demo"
      options={status === 'OK' ? data : []}
      isOptionEqualToValue={(option: any, value: any) => {
        return option?.description.includes(value);
      }}
      renderInput={(params) => (
        <StyledField
          // {...field}
          {...params}
          label={label}
          fullWidth
          error={Boolean(meta.error)}
          helperText={meta.error}
          InputLabelProps={{ shrink: true }}
        />
      )}
      {...rest}
    />
  );
};

export default SearchAddress;
