import React from 'react';
import { Outlet } from 'react-router-dom';
import {
  StyledBackgroundAlabaster,
  StyledBoxContent,
} from '../Common/Styled/CommonStyled';
import Navbar from './Navbar';

const LayoutWithNav = () => {
  return (
    <StyledBackgroundAlabaster>
      <Navbar />
      <StyledBoxContent>
        <Outlet />
      </StyledBoxContent>
    </StyledBackgroundAlabaster>
  );
};

export default LayoutWithNav;
