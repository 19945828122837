/* eslint-disable react-hooks/exhaustive-deps */
import { useQuery, useQueryClient } from '@tanstack/react-query';
import useAxios from 'hooks/Common/useAxios';
import { TotalPerformanceItemType } from 'hooks/TotalPerformance/useTotalPerformance';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import commonConstants from '../../constants/common.constant';
import { getUser } from '../../redux/selectors/auth.selector';
import { getReferrersDashboardFilterProperties } from '../../redux/selectors/referrers.selector';
import {
  PROPERTY_KEY,
  setProperties,
  setProperty,
} from '../../redux/slices/referrersSlice';
import formatUtil from '../../utils/format.util';
import getQueryKey from '../../utils/get-query-key';
import { useDebounce } from '../Common/useDebounce';

export const FILTER_CONSTANT = {
  'd:desc,f:d': 0,
  'd:asc,f:d': 1,
  'd:desc,f:p': 2,
  'd:asc,f:p': 3,
};

type ReferrersDashboardType = {
  data: ReferrersListItem[];
  limit: string;
  page: string;
  total: number;
};

export type ReferrersListItem = {
  link_purchases: number;
  referee_profit: number;
  referral_sales: number;
  referrer: {
    active_referrals: number;
    first_name: string;
    id: string;
    last_name: string;
    logo: string;
  };
  vendor_net_profit: number;
};

export const useGetReferrersDashboard = (isDashboard: boolean) => {
  const axios = useAxios();
  const dispatch = useDispatch();
  const { page, limit, keyword, direction, filter } = useSelector(
    getReferrersDashboardFilterProperties,
  );
  const queryClient = useQueryClient();
  const user = useSelector(getUser);
  const returnQueryParams = useCallback(
    (isPrefetch: boolean = false) => {
      return {
        page: isPrefetch ? page + 1 : page,
        limit: isDashboard ? 5 : limit,
        keyword,
        direction,
        filter,
        userId: user?.id ?? '',
      };
    },
    [page, limit, keyword, direction, user, isDashboard, filter],
  );

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    newPage: number,
  ) => {
    dispatch(setProperty({ type: PROPERTY_KEY.PAGE, value: newPage }));
  };
  const handleDebounceFn = useCallback(
    (inputValue: string) => {
      dispatch(
        setProperties([
          { type: PROPERTY_KEY.KEYWORD, value: inputValue },
          {
            type: PROPERTY_KEY.PAGE,
            value:
              commonConstants.REFERRERS_DASHBOARD_DEFAULT_QUERY_PARAMS.page,
          },
        ]),
      );
    },
    [dispatch],
  );
  const onHandleChangeKeyword = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleChangeInput(e.target.value);
  };
  const handleChangeInput = useDebounce(handleDebounceFn);
  const onHandleChangeFilter = (direction: string, filter: string) => {
    dispatch(
      setProperties([
        {
          type: PROPERTY_KEY.DIRECTION,
          value: direction,
        },
        {
          type: PROPERTY_KEY.FILTER,
          value: filter,
        },
        {
          type: PROPERTY_KEY.PAGE,
          value: commonConstants.REFERRERS_DASHBOARD_DEFAULT_QUERY_PARAMS.page,
        },
      ]),
    );
  };

  const query = useQuery<ReferrersDashboardType>(
    getQueryKey.referrersDashboardQueryKey(
      page,
      keyword,
      direction,
      filter,
      isDashboard,
    ),
    async () => {
      const response = await axios.get(
        '/vendors/referrer-paginated-analytics',
        {
          params: returnQueryParams(),
        },
      );
      return response.data;
    },
    {
      keepPreviousData: true,
      staleTime: commonConstants.STALE_TIME.MIN_1, // 1m
    },
  );
  const totalPage = useMemo(() => {
    return formatUtil.parseTotalPage({
      data: query?.data,
      page: commonConstants.REFERRERS_DASHBOARD_DEFAULT_QUERY_PARAMS.page,
      limit: isDashboard
        ? 5
        : commonConstants.REFERRERS_DASHBOARD_DEFAULT_QUERY_PARAMS.limit,
    });
  }, [query?.data, isDashboard]);

  useEffect(() => {
    if (page <= totalPage - 1 && !isDashboard) {
      queryClient.prefetchQuery(
        getQueryKey.referrersDashboardQueryKey(
          page + 1,
          keyword,
          direction,
          filter,
          isDashboard,
        ),
        async () => {
          const response = await axios.get(
            '/vendors/referrer-paginated-analytics',
            {
              params: returnQueryParams(),
            },
          );
          return response.data;
        },
      );
    }
  }, [
    page,
    keyword,
    direction,
    queryClient,
    returnQueryParams,
    totalPage,
    filter,
  ]);

  const filterSelected = useMemo(() => {
    if (
      direction === commonConstants.DIRECTION.DESC &&
      filter === commonConstants.FILTER.DATE
    ) {
      return FILTER_CONSTANT['d:desc,f:d'];
    } else if (
      direction === commonConstants.DIRECTION.ASC &&
      filter === commonConstants.FILTER.DATE
    ) {
      return FILTER_CONSTANT['d:asc,f:d'];
    } else if (
      direction === commonConstants.DIRECTION.DESC &&
      filter === commonConstants.FILTER.PERFORMANCE
    ) {
      return FILTER_CONSTANT['d:desc,f:p'];
    } else {
      return FILTER_CONSTANT['d:asc,f:p'];
    }
  }, [direction, filter]);
  return {
    query,
    totalPage,
    page,
    handleChangePage,
    onHandleChangeKeyword,
    onHandleChangeFilter,
    filterSelected,
    keyword,
  };
};

export const usePrefetchReferrerDetail = () => {
  const axios = useAxios();
  const queryClient = useQueryClient();
  return (referrerId: string) => {
    return queryClient.prefetchQuery(
      getQueryKey.getReferrerDetail(referrerId),
      async () => {
        const response = await axios.get(
          `/vendors/referrer-analytics/${referrerId}`,
        );
        const { referral_performance, sales_performance, ...referrerDetails } =
          response.data as ResponseReferrerDetailsSaleAnalyticsTypes;
        return {
          name: `${referrerDetails.first_name} ${referrerDetails.last_name}`,
          image: referrerDetails.avatar,
          creditPoint: referrerDetails.credit_point ?? '--',
          premiumMember: !!referrerDetails?.premium_member,
          activeReferrals: referrerDetails?.active_referrals,
          ...formatUtil.totalPerformanceFormat({
            sales_performance: sales_performance ?? [],
            referral_performance: referral_performance ?? [],
          }),
        };
      },
      {
        staleTime:
          commonConstants.STALE_TIME
            .SEC_20 /* only pre-fetch if older than 20 seconds */,
      },
    );
  };
};

type ReferrerDetailsType = {
  active_referrals: number;
  avatar: string;
  credit_point: number | null;
  first_name: string;
  last_name: string;
  premium_member: boolean | null;
  user_id: string;
};

type ResponseReferrerDetailsSaleAnalyticsTypes = {
  referral_performance: TotalPerformanceItemType[];
  sales_performance: TotalPerformanceItemType[];
} & ReferrerDetailsType;

export const useGetReferrerDetailsSaleAnalytics = ({
  referrerId,
}: {
  referrerId: string;
}) => {
  const axios = useAxios();
  return useQuery({
    queryKey: getQueryKey.getReferrerDetail(referrerId),
    queryFn: async () => {
      const response = await axios.get(
        `/vendors/referrer-analytics/${referrerId}`,
      );
      const { referral_performance, sales_performance, ...referrerDetails } =
        response.data as ResponseReferrerDetailsSaleAnalyticsTypes;
      return {
        name: `${referrerDetails.first_name} ${referrerDetails.last_name}`,
        image: referrerDetails.avatar,
        creditPoint: referrerDetails.credit_point ?? '--',
        premiumMember: !!referrerDetails?.premium_member,
        activeReferrals: referrerDetails?.active_referrals,
        ...formatUtil.totalPerformanceFormat({
          sales_performance: sales_performance ?? [],
          referral_performance: referral_performance ?? [],
        }),
      };
    },
    staleTime: commonConstants.STALE_TIME.MIN_1,
    enabled: !!referrerId,
  });
};

export const useGetReferralsForReferrer = ({
  referrerId,
}: {
  referrerId: string;
}) => {
  const axios = useAxios();
  const navigate = useNavigate();
  const [page, setPage] = useState<number>(
    commonConstants.REFERRALS_DASHBOARD_DEFAULT_QUERY_PARAMS.page,
  );
  const [keyword, setKeyword] = useState<string>(
    commonConstants.REFERRALS_DASHBOARD_DEFAULT_QUERY_PARAMS.keyword,
  );
  const handleDebounceFn = useCallback((inputValue: string) => {
    setPage(commonConstants.REFERRALS_DASHBOARD_DEFAULT_QUERY_PARAMS.page);
    setKeyword(inputValue);
  }, []);
  const onHandleChangeKeyword = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleChangeInput(e.target.value);
  };
  const handleChangeInput = useDebounce(handleDebounceFn);
  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    newPage: number,
  ) => {
    setPage(newPage);
  };
  const query = useQuery(
    getQueryKey.getReferralsForReferrer({ referrerId, page, keyword }),
    async () => {
      const response = await axios.get(
        `/vendors/referral-paginated-analytics/referrer/${referrerId}`,
        {
          params: {
            page,
            limit:
              commonConstants.REFERRALS_DASHBOARD_DEFAULT_QUERY_PARAMS.limit,
            searchTerm: keyword,
          },
        },
      );
      return response.data;
    },
    {
      enabled: !!referrerId,
      select: (response) => response?.data,
      staleTime: commonConstants.STALE_TIME.SEC_20 /* 20 secs */,
      onError: () => navigate('/invalid-link', { replace: true }),
      keepPreviousData: true,
    },
  );
  const totalPage = useMemo(() => {
    return formatUtil.parseTotalPage({
      data: query?.data?.referrals,
      page: commonConstants.REFERRALS_DASHBOARD_DEFAULT_QUERY_PARAMS.page,
      limit: commonConstants.REFERRALS_DASHBOARD_DEFAULT_QUERY_PARAMS.limit,
    });
  }, [query?.data?.referrals]);
  return {
    query,
    page,
    keyword,
    totalPage,
    handleChangePage,
    onHandleChangeKeyword,
  };
};
