import { StyledLoadingButton, StyledStack } from '../AuthStyled';
import * as Yup from 'yup';
import {
  styled,
  FormGroup,
  Typography,
  Stack,
  Autocomplete,
  TextField,
  Chip,
  CircularProgress,
} from '@mui/material';
import { useFormikContext } from 'formik';
import { useState } from 'react';
import { themes } from 'theme';
import { v4 as uuidv4 } from 'uuid';
import { useGetTechnologies } from 'hooks/Auth/useAuth';

const StyledField = styled(TextField)({
  '& .MuiInputLabel-root': {
    '&.Mui-focused': {
      color: themes.light.colorBlack,
    },
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      border: `1px solid ${themes.light.borderColor}`,
      borderRadius: '4px',
    },
    '&.Mui-focused fieldset': {
      borderColor: themes.light.colorMaximumBlueGreen,
    },
  },
});

export const validationSchemaLYBStep4 = Yup.object({
  technology_use: Yup.array()
    .min(1, 'Please select least 1')
    .required('Required'),
});

export type ValuesLYBStep4Type = {
  technology_use: string[];
};

export const initialValuesLYBStep4 = (values: Partial<ValuesLYBStep4Type>) => ({
  technology_use: values?.technology_use ?? [],
});

const LYBStep4 = () => {
  const [key, setKey] = useState(uuidv4());
  const [inputValue, setInputValue] = useState('');
  const { values, setFieldValue, errors, handleSubmit } =
    useFormikContext<ValuesLYBStep4Type>();
  const { data: technologies, isLoading } = useGetTechnologies();
  const handleChange = (value: string, isAdded: boolean) => {
    const updatedList = new Set(values?.technology_use || []);
    if (isAdded) {
      updatedList.add(value);
      setKey(uuidv4());
    } else {
      updatedList.delete(value);
    }
    setFieldValue('technology_use', Array.from(updatedList));
  };

  if (isLoading) {
    return (
      <Stack alignItems="center" justifyContent="center">
        <CircularProgress />
      </Stack>
    );
  }
  return (
    <Stack gap={2}>
      <FormGroup>
        <StyledStack
          sx={{
            gap: 3,
          }}
        >
          <Autocomplete
            key={key}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue);
            }}
            onChange={(event: any, newValue: string | null) => {
              if (newValue) {
                handleChange(newValue as string, true /* isAdded */);
                setInputValue('');
              }
            }}
            id="controllable-states-demo"
            options={technologies ?? TECHNOLOGY_USE}
            renderInput={(params) => (
              <StyledField
                {...params}
                label="Select your technology (e.g., WooCommerce)"
              />
            )}
          />
          <Stack direction="row" gap={2} flexWrap="wrap">
            {values?.technology_use?.map((value) => (
              <Chip
                key={value}
                label={value}
                variant="outlined"
                onDelete={() =>
                  handleChange(value as string, false /* isAdded */)
                }
              />
            ))}
          </Stack>
        </StyledStack>
        {errors?.technology_use ? (
          <Typography color="red" mt={2}>
            {errors?.technology_use}
          </Typography>
        ) : null}
      </FormGroup>
      <StyledLoadingButton onClick={() => handleSubmit()} variant="contained">
        Next
      </StyledLoadingButton>
    </Stack>
  );
};

export default LYBStep4;

const TECHNOLOGY_USE: string[] = [
  'WooCommerce',
  'Shopify',
  'Custom Cart',
  'Wix',
  'Squarespace',
  'Bandcamp',
  'Square Online',
  'OpenCart',
  'PrestaShop',
  'Big Cartel',
  'Ecwid',
  'GoDaddy Online Store',
  'Magento',
  'Cafe24',
  'Tiendanube',
  'BASE',
  'Odoo',
  'Takeaway',
  'Mercado Shops',
  'JouwWeb',
  'Weebly Commerce',
  'BigCommerce',
  'SHOPLINE',
  'Loja Integrada',
  'prom',
  'Shopware',
  'Kajabi',
  'Thinkific',
  "I'm Web",
  'Spring',
  'Color Me Shop',
  'Shoptet',
  'Teachable',
  'Alibaba Minisite',
  'STORES',
  'Tray Commerce',
  'Lightspeed',
  'Salla',
  'Yampi',
  'BaseKit',
  'Menufy',
  'LnwShop',
  'Shoper',
  'VirtueMart',
  'Shoplazza',
  'Gumroad',
  'nopCommerce',
  'OEMSaaS',
  'ePages',
  'Webflow',
  'ueeshop',
  'Sapo',
  'Drupal Commerce',
  'Haravan',
  'YouCan',
  'osCommerce',
  'Etsy Pattern',
  'ShopBase',
  'Ticimax',
  'Art Storefronts',
  'IdeaSoft',
  'JTL-Shop',
  'Gambio',
  'NHN Godo',
  'EasyStore',
  'The Print Bar',
  'UNAS',
  '3DCart',
  'Jumpseller',
  'SumUp',
  'sixshop',
  'Empretienda',
  'Zid',
  'Mijnwebwinkel',
  'VTEX',
  'Podia',
  'Zen Cart',
  'Fourthwall',
  'Sage',
  'Dooca',
  'Shoprenter',
  'Farmacie in Italia',
  'MakeWebEasy',
  'Salesforce Commerce Cloud',
  'Pixels',
  'ikas',
  'MakanE',
  'SatuKZ',
  'BeatStars',
  'CCV Shop',
  'IdoSell',
  'cartX',
  'Dukaan',
  'SAP Commerce Cloud',
  'Yahoo Store',
  'AspDotNetStorefront',
  'MakeShop',
  'Unicart',
  'NetSuite SuiteCommerce',
  'T1paginas',
  'Spreadshop',
  'Quickbutik',
  'Rain',
  'Abicart',
  'Cyberbiz',
  'OXID eShop',
  'Threadless',
  'Lazyshop',
  'TeeChip',
  'Sitedish',
  'plentymarkets',
  'neto',
  'MerchantPro',
  'Shopyy',
  'Oracle Commerce',
  'Shopmatic',
  'wBuy',
  'Future Shop',
  'EC-CUBE',
  'meepShop',
  'Dropify',
  'WiziShop',
  'Volusion',
  'Irroba',
  'QDM',
  'Boutir',
  'Miva Merchant',
  'Wuilt',
  'SHOPAGE',
  'City Hive',
  'AmeriCommerce',
  'Magazord',
  'WineDirect',
  'Commerce7',
  '91APP',
  'CommentSold',
  'Zoho Commerce',
  'Oddle',
  'SmartEtailing',
  'Instamojo',
  'ShopDeck',
  'Spree Commerce',
  'Network Solutions Commerce',
  'Wikinggruppen',
  'HCL Commerce',
  'Booqable',
  'Shopkit',
  'Launch Cart',
  'Visualsoft',
  'PhotoShelter',
  'Vnda',
  'Getshop Today',
  'Dotpe',
  'WACA',
  'Finqu',
  'Ordable',
  'Funnelish',
  'Music Glue',
  'Tradift',
  'Zyda',
  'Sellfy',
  'Cartzy',
  'AllValue',
  'Coachannel',
  'ecbeing',
  'StorEnvy',
  'Crystal Commerce',
  'Storeino',
  'Cococart',
  'Sana Commerce',
  'Sodexo',
  'Townsend Music',
  'Intershop',
  'Starweb',
  'Easy My Shop',
  'Zammit',
  'BW Commerce',
  'Coachy',
  'Meshop',
  'E37',
  'GrazeCart',
  'Fenicio',
  'Bookmanager',
  'Ochre',
  'Saffire',
  'Askås',
  'Printify',
  'StoreHippo',
  'THG Ingenuity',
  'Microweber',
  'Fbits',
  'Mshop',
  'goimagine',
  'Norce',
  'SellersCommerce',
  'Jetshop',
  'Webmercs',
  'Vendre',
  'ecomz',
  'Linx Commerce',
  'tossdown',
  'Fastcommerce',
  'ExpandCart',
  'weedmaps',
  'Scayle',
  'Moovin',
  '2Cshop',
  'Oracle CX Commerce',
  'XShoppy',
  'JET',
  'Twice Commerce',
  'Treet',
  'ebisumart',
  'Bentoweb',
  'STOREBERRY',
  'Shopcada',
  'Centra',
  'Xonic',
  'Spryker',
  'CommerceHQ',
  'Unilog',
  '82Cart',
  'Novomind',
  'NinjaOS',
  'B2B Store',
  'cleverbridge',
  'Swell',
  'FunPinPin',
  'Elevar',
  'Sprwt',
  'Samishop',
  'zVendo',
  '29 Next',
  'Sharetribe',
  'Oro',
  'Zupain',
  'Viskan',
  'ShopTill-E',
  'Commercev3',
  'Convertize',
  'WSHOP',
  'Delivery Biz Pro',
  'Sitecore Commerce',
  'Matajer',
  'TapGoods',
  'CommerceUp',
  'Shoplo',
  'Kometia',
  'SIRCLO',
  'ShopExpress',
  'Dutchie Plus',
  'F1 Commerce',
  'TokoTalk',
  'Codshopy',
  'Inovarti',
  'Stoom',
  'Digital River',
  'Tiu.ru',
  'Workarea',
  'Wx3',
  'Bleez',
  'Ultra Commerce',
  'StackCommerce',
  'Continued Fashion',
];
