import { useCallback, useMemo } from 'react';
import { StyledStack } from '../AuthStyled';
import { Stack, CircularProgress } from '@mui/material';
import * as Yup from 'yup';
import StripeLogo from 'assets/images/stripe-logo.png';
import { useCreateStripeAccountV2, useGetBankInfoV2 } from 'hooks/Info/useInfo';
import {
  StyledLoadingButton,
  StyledOutlinedLoadingButton,
} from 'components/Common/Styled/CommonStyled';
import { Text16Weight600 } from 'components/Common/Styled/TypographyStyed';
import { themes } from 'theme';
import commonConstants from 'constants/common.constant';
import { useNavigate } from 'react-router-dom';
import { CONTENT_PROCESSING_YOUR_APPLICATION } from '../WaitingApprove/WaitingApprove';

export const validationSchemaLYBStep7 = Yup.object().shape({});

const LYBStep7 = () => {
  const {
    data: linkedBankInfo,
    isLoading: isLoadingLinkedBankInfo,
    isFetching,
    isError: isErrorLinkedBankInfo,
  } = useGetBankInfoV2();
  const navigate = useNavigate();

  const {
    mutate: mutateCreateStripeAccount,
    isLoading: isLoadingCreateStripeAccount,
    isError: isErrorCreateStripeAccount,
  } = useCreateStripeAccountV2();
  const isLoading = isLoadingLinkedBankInfo || isFetching;

  const onHandleCreateStripeAccount = useCallback(() => {
    mutateCreateStripeAccount(undefined, {
      onSuccess: (response) => {
        window.open(
          `${
            window.location.origin
          }/transition-stripe-connect?stripe-link=${encodeURIComponent(
            response?.onboardingLink,
          )}`,
        );
      },
    });
  }, [mutateCreateStripeAccount]);

  const content = useMemo(() => {
    if (isLoading) {
      return (
        <Stack direction="row" alignItems="center" justifyContent="center">
          <CircularProgress />
        </Stack>
      );
    }
    if (isErrorLinkedBankInfo || isErrorCreateStripeAccount) {
      return (
        <StyledLoadingButton
          loading={isLoadingCreateStripeAccount}
          onClick={() =>
            navigate('/auth/waiting-approve', {
              state: {
                contentMessage:
                  CONTENT_PROCESSING_YOUR_APPLICATION.CONGRATULATION,
              },
            })
          }
          variant="contained"
        >
          <Text16Weight600 sx={{ color: themes.light.colorWhite }}>
            Continue
          </Text16Weight600>
        </StyledLoadingButton>
      );
    }
    if (
      linkedBankInfo?.status === commonConstants.STRIPE_STATUS_TYPE.NOT_CREATED
    ) {
      return (
        <StyledLoadingButton
          loading={isLoadingCreateStripeAccount}
          onClick={onHandleCreateStripeAccount}
          variant="contained"
        >
          <Text16Weight600 sx={{ color: themes.light.colorWhite }}>
            Create Stripe Account
          </Text16Weight600>
        </StyledLoadingButton>
      );
    }
    if (linkedBankInfo?.url) {
      return (
        <>
          <StyledLoadingButton
            loading={isLoadingCreateStripeAccount}
            onClick={() =>
              navigate('/auth/waiting-approve', {
                state: {
                  contentMessage:
                    CONTENT_PROCESSING_YOUR_APPLICATION.CONGRATULATION,
                },
              })
            }
            variant="contained"
          >
            <Text16Weight600 sx={{ color: themes.light.colorWhite }}>
              I’ve completed this step
            </Text16Weight600>
          </StyledLoadingButton>
          <StyledOutlinedLoadingButton
            loading={isLoadingCreateStripeAccount}
            onClick={() =>
              window.open(
                `${
                  window.location.origin
                }/transition-stripe-connect?stripe-link=${encodeURIComponent(
                  linkedBankInfo?.url ?? '',
                )}`,
              )
            }
            variant="contained"
          >
            <Text16Weight600>I need to make changes</Text16Weight600>
          </StyledOutlinedLoadingButton>
        </>
      );
    }
    return null;
  }, [
    isErrorCreateStripeAccount,
    isErrorLinkedBankInfo,
    isLoading,
    isLoadingCreateStripeAccount,
    linkedBankInfo,
    navigate,
    onHandleCreateStripeAccount,
  ]);

  return (
    <StyledStack
      sx={{
        gap: 3,
      }}
    >
      <Stack direction="row" alignItems="center" justifyContent="center">
        <img src={StripeLogo} style={{ width: 200 }} alt="Stripe logo" />
      </Stack>
      {content}
    </StyledStack>
  );
};

export default LYBStep7;
