import { Paper, Stack } from '@mui/material';
import { StyledLoadingButton } from 'components/Common/Styled/CommonStyled';
import {
  CommonSubtext,
  CommonTitleText,
  Text16Weight600,
} from 'components/Common/Styled/TypographyStyed';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { themes } from 'theme';

const SELF_CLOSE = 'self-close';

const StripeConnectSuccess = () => {
  const navigate = useNavigate();
  const [params] = useSearchParams();

  const isMobileView = params.get('type') === SELF_CLOSE;

  return (
    <>
      <Helmet>
        <title>iRefer</title>
        <meta name="description" content="iRefer Link Bank Account" />
      </Helmet>
      <Stack
        justifyContent="center"
        alignItems="center"
        sx={{ minHeight: '100dvh' }}
      >
        <Paper sx={{ p: '5rem 3rem', maxWidth: '600px' }} elevation={3}>
          <Stack gap={4}>
            <Stack gap={1}>
              <CommonTitleText>
                STRIPE IS PROCESSING YOUR SUBMISSION
              </CommonTitleText>
              <CommonSubtext>
                {isMobileView
                  ? 'Please close this screen to return to the app.'
                  : 'Return to the dashboard to continue.'}
              </CommonSubtext>
            </Stack>
            {isMobileView ? null : (
              <StyledLoadingButton
                onClick={() => navigate('/')}
                variant="contained"
              >
                <Text16Weight600 sx={{ color: themes.light.colorWhite }}>
                  Return home
                </Text16Weight600>
              </StyledLoadingButton>
            )}
          </Stack>
        </Paper>
      </Stack>
    </>
  );
};

export default StripeConnectSuccess;
