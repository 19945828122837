import { StyledLoadingButton, StyledStack } from '../AuthStyled';
import * as Yup from 'yup';
import { useFormikContext } from 'formik';
import { useLoadScript } from '@react-google-maps/api';
import FormikControl, { CONTROL_TYPE } from 'components/Formik/FormikControl';
import LoadingContent from 'components/Common/LoadingContent/LoadingContent';
import { useMemo } from 'react';

export const validationSchemaLYBStep5 = Yup.object({
  address: Yup.object({
    hq_address: Yup.object({
      line1: Yup.string().required('Required'),
      line2: Yup.string().notRequired(),
      city: Yup.string().required('Required'),
      state: Yup.string().required('Required'),
      stateShortVersion: Yup.string().required('Required'),
      postal_code: Yup.string().required('Required'),
      country: Yup.string().required('Required'),
      countryShortVersion: Yup.string().required('Required'),
      lat: Yup.number(),
      lng: Yup.number(),
    }),
    branches: Yup.array(),
  }),
});

export type ValuesLYBStep5Type = {
  address: {
    hq_address: {
      line1: string;
      line2: string;
      city: string;
      state: string;
      stateShortVersion: string;
      postal_code: string;
      country: string;
      countryShortVersion: string;
      lat: number;
      lng: number;
    };
    branches: [];
  };
};

export const initialValuesLYBStep5 = (values: Partial<ValuesLYBStep5Type>) => ({
  address: values?.address ?? {
    hq_address: {
      line1: '',
      line2: '',
      city: '',
      state: '',
      stateShortVersion: '',
      postal_code: '',
      country: '',
      countryShortVersion: '',
      lat: 0,
      lng: 0,
    },
    branches: [],
  },
});

const libraries = ['places'];

const LYBStep5 = () => {
  const { values, setValues, handleSubmit } =
    useFormikContext<ValuesLYBStep5Type>();
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY ?? '',
    // @ts-ignore
    libraries,
  });
  const isLoading = useMemo(() => !isLoaded, [isLoaded]);
  const onHandleSetFormikValue = async (newValues: any) => {
    if (newValues) {
      const val: ValuesLYBStep5Type = {
        address: {
          hq_address: {
            line1: newValues?.line1 as string,
            line2: values?.address.hq_address.line2 as string,
            city: newValues?.city as string,
            state: newValues?.state as string,
            stateShortVersion: newValues?.city as string,
            postal_code: newValues?.postal_code as string,
            country: newValues?.country as string,
            countryShortVersion: newValues?.countryShortVersion as string,
            lat: newValues?.lat as number,
            lng: newValues?.lng as number,
          },
          branches: [],
        },
      };

      if (!newValues?.line1) {
        val.address.hq_address.line2 = newValues?.line1;
      }
      setValues(val);
    }
  };
  if (isLoading) {
    return <LoadingContent text="Just a moment..." />;
  }
  return (
    <StyledStack
      sx={{
        gap: 3,
      }}
    >
      <FormikControl
        control={CONTROL_TYPE.SEARCH_ADDRESS}
        name="address.hq_address.line1"
        label="Address line 1"
        onHandleSetFormikValue={onHandleSetFormikValue}
      />
      <FormikControl
        control={CONTROL_TYPE.INPUT}
        name="address.hq_address.line2"
        label="Address line 2 (Optional)"
        InputLabelProps={{ shrink: true }}
      />
      <FormikControl
        control={CONTROL_TYPE.INPUT}
        name="address.hq_address.city"
        label="City"
        disabled
        InputLabelProps={{ shrink: true }}
      />
      <FormikControl
        control={CONTROL_TYPE.INPUT}
        name="address.hq_address.state"
        label="State"
        disabled
        InputLabelProps={{ shrink: true }}
      />
      <FormikControl
        control={CONTROL_TYPE.INPUT}
        name="address.hq_address.postal_code"
        label="Postal code"
        disabled
        InputLabelProps={{ shrink: true }}
      />
      <FormikControl
        control={CONTROL_TYPE.INPUT}
        name="address.hq_address.country"
        label="Country"
        disabled
        InputLabelProps={{ shrink: true }}
      />
      <StyledLoadingButton onClick={() => handleSubmit()} variant="contained">
        Next
      </StyledLoadingButton>
    </StyledStack>
  );
};

export default LYBStep5;
