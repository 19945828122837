export const REFERRERS_DASHBOARD = 'REFERRERS_DASHBOARD';
export const PREVIEW_REFERRERS_DASHBOARD = 'PREVIEW_REFERRERS_DASHBOARD';
export const REFERRALS_DASHBOARD = 'REFERRALS_DASHBOARD';
export const TRANSACTION_DASHBOARD = 'TRANSACTION_DASHBOARD';
export const PREVIEW_REFERRALS_DASHBOARD = 'PREVIEW_REFERRALS_DASHBOARD';
export const GET_LINKED_BANK_INFO = 'GET_LINKED_BANK_INFO';
export const GET_LINKED_BANK_INFO_V2 = 'GET_LINKED_BANK_INFO_V2';
export const GET_REFERRAL_DETAIL = 'GET_REFERRAL_DETAIL';
export const GET_REFERRER_DETAIL = 'GET_REFERRER_DETAIL';
export const GET_TRANSACTION_DETAIL = 'GET_TRANSACTION_DETAIL';
export const GET_SALES_ANALYTICS = 'GET_SALES_ANALYTICS';
export const GET_BUSINESS_CATEGORIES = 'GET_BUSINESS_CATEGORIES';
export const GET_TECHNOLOGIES = 'GET_TECHNOLOGIES';
export const GET_STRIPE_ACCOUNT_ID = 'GET_STRIPE_ACCOUNT_ID';
export const GET_STRIPE_SETUP_INTENT = 'GET_STRIPE_SETUP_INTENT';
export const GET_VENDOR_CARD_DETAILS = 'GET_VENDOR_CARD_DETAILS';
export const GET_REFERRALS_FOR_REFERRER = 'GET_REFERRALS_FOR_REFERRER';

const getQueryKey = {
  previewReferrersDashboardQueryKey: (): any[] => [PREVIEW_REFERRERS_DASHBOARD],
  getSalesAnalyticsQueryKey: (): any[] => [GET_SALES_ANALYTICS],
  referrersDashboardQueryKey: (
    page: number,
    keyword: string,
    direction: string,
    filter: string,
    isDashboard: boolean,
  ): any[] => [
    REFERRERS_DASHBOARD,
    { page },
    { keyword },
    { direction },
    { filter },
    { isDashboard },
  ],
  previewReferralsDashboardQueryKey: (): any[] => [PREVIEW_REFERRALS_DASHBOARD],
  referralsDashboardQueryKey: (payload: any): any[] => [
    REFERRALS_DASHBOARD,
    payload,
  ],
  transactionsDashboardQueryKey: (payload: any): any[] => [
    TRANSACTION_DASHBOARD,
    payload,
  ],
  getLinkedBankInfo: (userId?: string): any[] => [GET_LINKED_BANK_INFO, userId],
  getLinkedBankInfoV2: (): any[] => [GET_LINKED_BANK_INFO_V2],
  getReferralDetails: (referralId: string): any[] => [
    GET_REFERRAL_DETAIL,
    { id: referralId },
  ],
  getTransactionDetail: (transactionId: string): any[] => [
    GET_TRANSACTION_DETAIL,
    { id: transactionId },
  ],
  getReferrerDetail: (referrerId: string): any[] => [
    GET_REFERRER_DETAIL,
    { referrerId },
  ],
  getReferralsForReferrer: (payload: {
    referrerId: string;
    page: number;
    keyword: string;
  }): any[] => [GET_REFERRALS_FOR_REFERRER, payload],
  getBusinessCategories: (): any[] => [GET_BUSINESS_CATEGORIES],
  getTechnologies: (): any[] => [GET_TECHNOLOGIES],
  getStripeAccountId: (userId: string): any[] => [
    GET_STRIPE_ACCOUNT_ID,
    userId,
  ],
  getStripeSetupIntent: (userId: string): any[] => [
    GET_STRIPE_SETUP_INTENT,
    userId,
  ],
  getVendorCardDetails: (userId: string): any[] => [
    GET_VENDOR_CARD_DETAILS,
    userId,
  ],
};

export default getQueryKey;
