import DoneIcon from '@mui/icons-material/Done';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import { Typography, styled } from '@mui/material';

interface ValidationProps {
  shortName: string;
  classNames?: string | null;
  meetsRequirements: boolean;
  validationMessage: string;
}

const ValidationMessage = styled(Typography)`
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;

  &.invalid {
    color: black;
  }

  &.valid {
    color: green;
  }
`;

function PasswordValidationItem(props: ValidationProps) {
  return (
    <ValidationMessage
      className={`password-validation-checklist__${props.shortName}-wrapper ${
        props.classNames || ''
      }${props.meetsRequirements ? 'valid' : 'invalid'}`}
    >
      {props.meetsRequirements ? <DoneIcon /> : <HorizontalRuleIcon />}
      <Typography
        variant="subtitle1"
        fontSize={12}
        className={`password-validation-checklist__${props.shortName}-description`}
      >
        {props.validationMessage}
      </Typography>
    </ValidationMessage>
  );
}

export default PasswordValidationItem;
