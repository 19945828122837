import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import ToastMessage, {
  ToastType,
} from '../../components/Common/ToastMessage/ToastMessage';
import httpStatusCode from '../../constants/http-status-code.constant';
import { logout } from '../../redux/slices/authSlice';
import axios from '../../utils/axios.util';
import { AxiosRequestConfig } from 'axios';
import { getAuthToken } from 'hooks/Auth/commonAuthAPI';

const useAxios = (name: string = '') => {
  const dispatch = useDispatch();
  useEffect(() => {
    const requestInterceptor = axios.interceptors.request.use(
      async (config: AxiosRequestConfig) => {
        const accessToken = await getAuthToken();
        if (accessToken) {
          if (config.headers) {
            config.headers.Authorization = `Bearer ${accessToken}`;
          } else {
            config.headers = { Authorization: `Bearer ${accessToken}` };
          }
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      },
    );
    const responseInterceptor = axios.interceptors.response.use(
      (response: any) => response,
      (error: any) => {
        const errorMessage = error?.response?.data ?? {
          message: 'Something went wrong.',
        };
        if (error?.response?.status === httpStatusCode.UNAUTHENTICATED) {
          toast(
            <ToastMessage
              text={errorMessage.message}
              type={ToastType.ERROR.type}
            />,
          );
          dispatch(logout());
        }
        return Promise.reject(error);
      },
    );
    return () => {
      axios.interceptors.response.eject(responseInterceptor);
      axios.interceptors.request.eject(requestInterceptor);
    };
  }, [dispatch, name]);

  return axios;
};

export default useAxios;
