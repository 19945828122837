import { StyledLoadingButton, StyledStack } from '../AuthStyled';
import * as Yup from 'yup';
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
  Stack,
  CircularProgress,
} from '@mui/material';
import { useFormikContext } from 'formik';
import { useGetBusinessCategories } from 'hooks/Auth/useAuth';

export const validationSchemaLYBStep3 = Yup.object({
  business_category: Yup.array()
    .min(1, 'Please select least 1')
    .required('Required'),
});

export type ValuesLYBStep3Type = {
  business_category: string;
};

export const initialValuesLYBStep3 = (values: Partial<ValuesLYBStep3Type>) => ({
  business_category: values?.business_category ?? [],
});

const LYBStep3 = () => {
  const { values, setFieldValue, errors, handleSubmit } =
    useFormikContext<ValuesLYBStep3Type>();
  const { data: businessCategoryList, isLoading } = useGetBusinessCategories();
  const handleChange = (value: string) => {
    const list = new Set(values?.business_category || []);
    if (!list.has(value)) {
      list.add(value);
    } else {
      list.delete(value);
    }
    setFieldValue('business_category', Array.from(list));
  };
  if (isLoading) {
    return (
      <Stack alignItems="center" justifyContent="center">
        <CircularProgress />
      </Stack>
    );
  }
  return (
    <Stack gap={2}>
      <FormGroup>
        <StyledStack
          sx={{
            gap: 3,
            height: 'min(480px, 40vh)',
            overflowY: 'scroll',
          }}
        >
          {businessCategoryList?.map(({ label, value }) => (
            <FormControlLabel
              key={value}
              control={
                <Checkbox
                  checked={values?.business_category?.includes(value) || false}
                  onChange={() => handleChange(value)}
                />
              }
              label={label}
            />
          ))}
        </StyledStack>
        {errors?.business_category ? (
          <Typography color="red" mt={2}>
            {errors?.business_category}
          </Typography>
        ) : null}
      </FormGroup>
      <StyledLoadingButton onClick={() => handleSubmit()} variant="contained">
        Next
      </StyledLoadingButton>
    </Stack>
  );
};

export default LYBStep3;
