import { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import { Helmet } from 'react-helmet-async';
import {
  UpdateVendorInfoPayload,
  useSendWelcomeEmail,
  useUpdateVendorInfo,
} from '../../../hooks/Auth/useAuth';
import { useMultistepForm } from '../../../hooks/Common/useMultistepForm';
import { Logo, ProgressBar, StepText, StyledStack } from '../AuthStyled';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getUser } from '../../../redux/selectors/auth.selector';
import BackButton from '../../../components/Common/BackButton/BackButton';
import LoadingContent from '../../../components/Common/LoadingContent/LoadingContent';
import produce from 'immer';
import { StyledBox } from '../../../components/Common/Styled/CommonStyled';
import {
  CommonSubtext,
  CommonTitleText,
} from '../../../components/Common/Styled/TypographyStyed';
import { Stack } from '@mui/material';
import LogoIReferIcon from '../../../assets/images/icon-logo-irefer-trademark.png';
import LYBStep1, {
  initialValuesLYBStep1,
  validationSchemaLYBStep1,
} from './LYBStep1';
import LYBStep2, {
  initialValuesLYBStep2,
  validationSchemaLYBStep2,
} from './LYBStep2';
import LYBStep3, {
  initialValuesLYBStep3,
  validationSchemaLYBStep3,
} from './LYBStep3';
import LYBStep5, {
  initialValuesLYBStep5,
  validationSchemaLYBStep5,
} from './LYBStep5';
import LYBStep6, {
  initialValuesLYBStep6,
  validationSchemaLYBStep6,
} from './LYBStep6';
import LYBStep7, { validationSchemaLYBStep7 } from './LYBStep7';
import LYBStep4, {
  initialValuesLYBStep4,
  validationSchemaLYBStep4,
} from './LYBStep4';

const STEP_TO_SUBMIT = 5; /* Upload Logo Company */

const ListYourBusiness = () => {
  const navigate = useNavigate();
  const [noAutoNavigate, setNoAutoNavigate] = useState(true);
  const user = useSelector(getUser);
  useEffect(() => {
    if (user?.vendor?.abn && noAutoNavigate) {
      navigate('/');
    }
  }, [navigate, noAutoNavigate, user]);

  const {
    mutate: mutateUpdateVendorInfo,
    isLoading: isLoadingUpdateVendorInfo,
  } = useUpdateVendorInfo();
  const {
    mutate: mutateSendWelcomeEmail,
    isLoading: isLoadingSendWelcomeEmail,
  } = useSendWelcomeEmail();
  const [businessValues, setBusinessValues] = useState({
    first_name: '',
    last_name: '',
    business_name: '',
    brand_name: '',
    abn: '',
    year_of_incorporation: '',
    domain: '',
    business_category: [],
    technology_use: [],
    logo: '',
    address: {
      hq_address: {
        line1: '',
        line2: '',
        city: '',
        state: '',
        stateShortVersion: '',
        postal_code: '',
        country: '',
        countryShortVersion: '',
        lat: 0,
        lng: 0,
      },
      branches: [],
    },
  });

  const onSubmitMain = async (values: any) => {
    // Check Validation for Step 2 (ABN and Domain)
    // if (values?.abn && values?.domain) {
    //   let isValid = true;
    //   try {
    //     const validationResponse = await mutateValidateBusiness({
    //       abn: values.abn,
    //       domain: values.domain,
    //     });
    //     if (validationResponse?.data?.duplicate) {
    //       for (const [key, value] of Object.entries(
    //         validationResponse.data.duplicate,
    //       )) {
    //         if (!!value) {
    //           isValid = false;
    //           formikBag.setFieldError(
    //             key,
    //             ERRORS[key as keyof ValidateBusinessPayload] ?? '',
    //           );
    //         }
    //       }
    //     }
    //   } catch (error) {
    //     isValid = false;
    //   }
    //   if (!isValid) {
    //     return;
    //   }
    // }
    const latestValues = produce(businessValues, (draft: any) => {
      for (const [key, value] of Object.entries(values)) {
        draft[key] = value;
      }
    });
    setBusinessValues(latestValues);
    STEP_TO_SUBMIT === currentStepIndex
      ? onHandleComplete(latestValues)
      : next();
  };

  const onHandleComplete = (latestValues: UpdateVendorInfoPayload) => {
    setNoAutoNavigate(false);
    mutateUpdateVendorInfo(latestValues, {
      onSuccess: () => {
        mutateSendWelcomeEmail(undefined, {
          onSettled: () => {
            next();
          },
        });
      },
    });
  };

  const onHandleSkip = () => {
    STEP_TO_SUBMIT === currentStepIndex
      ? onHandleComplete(businessValues)
      : next();
  };

  const {
    currentStepIndex,
    step,
    steps,
    next,
    back,
    initialValues,
    validationSchema,
    onSubmit,
    percentageProgress,
    // isLastStep,
    isFirstStep,
    //@ts-ignore
    title,
    //@ts-ignore
    subTitle,
  } = useMultistepForm([
    {
      component: <LYBStep1 />,
      onSubmit: onSubmitMain,
      initialValues: initialValuesLYBStep1,
      validationSchema: validationSchemaLYBStep1,
      title: 'BRAND REPRESENTATIVE DETAILS',
    },
    {
      component: <LYBStep2 />,
      onSubmit: onSubmitMain,
      initialValues: initialValuesLYBStep2,
      validationSchema: validationSchemaLYBStep2,
      title: 'LIST YOUR BUSINESS WITH iREFER',
    },
    {
      component: <LYBStep3 />,
      onSubmit: onSubmitMain,
      initialValues: initialValuesLYBStep3,
      validationSchema: validationSchemaLYBStep3,
      title: 'SELECT YOUR INDUSTRY',
    },
    {
      component: <LYBStep4 />,
      onSubmit: onSubmitMain,
      initialValues: initialValuesLYBStep4,
      validationSchema: validationSchemaLYBStep4,
      title: 'SELECT YOUR TECHNOLOGY STACK',
    },
    {
      component: <LYBStep5 />,
      onSubmit: onSubmitMain,
      initialValues: initialValuesLYBStep5,
      validationSchema: validationSchemaLYBStep5,
      title: 'ADD YOUR BUSINESS ADDRESS',
      subTitle: 'Please add the address of your business HQ.',
    },
    {
      component: (
        <LYBStep6
          handleSkip={onHandleSkip}
          isLoading={isLoadingUpdateVendorInfo || isLoadingSendWelcomeEmail}
        />
      ),
      onSubmit: onSubmitMain,
      initialValues: initialValuesLYBStep6,
      validationSchema: validationSchemaLYBStep6,
      title: 'UPLOAD YOUR COMPANY LOGO',
    },
    {
      component: <LYBStep7 />,
      onSubmit: onSubmitMain,
      initialValues: () => ({}),
      validationSchema: validationSchemaLYBStep7,
      title: `ONE LAST STEP. 
      LET’S VERIFY YOUR ACCOUNT WITH STRIPE`,
      subTitle:
        'To start creating the stripe account click on the button below.',
    },
  ]);
  const formikProps = {
    initialValues: initialValues(businessValues),
    validationSchema,
    onSubmit,
  };
  const isLoading = isLoadingUpdateVendorInfo;
  return (
    <Formik {...formikProps} enableReinitialize={true}>
      <Form>
        <Helmet>
          <title>List Your Business</title>
          <meta name="description" content="iRefer List Your business" />
        </Helmet>
        {isLoading ? (
          <LoadingContent text="Just a moment while we verify your business details..." />
        ) : (
          <StyledBox
            sx={{
              padding: '52px',
              display: 'flex',
              alignItems: 'center',
              position: 'relative',
            }}
          >
            {!isFirstStep && <BackButton onClick={back} />}
            <StyledStack sx={{ gap: 3, width: '100%', height: '100%' }}>
              <Stack alignItems="center" mb={2}>
                <Logo
                  alt="iReferLogo"
                  src={LogoIReferIcon}
                  style={{ width: '104px' }}
                />
              </Stack>
              <CommonTitleText sx={{ textTransform: 'none' }}>
                {title ?? 'LIST YOUR BUSINESS WITH IREFER'}
              </CommonTitleText>
              {subTitle ? (
                <CommonSubtext sx={{ width: 500 }}>{subTitle}</CommonSubtext>
              ) : null}
              <StepText>
                Step {currentStepIndex + 1} of {steps.length}
              </StepText>
              <ProgressBar
                sx={{ colorPrimary: 'red' }}
                variant="determinate"
                value={percentageProgress}
              />
              {step}
            </StyledStack>
          </StyledBox>
        )}
      </Form>
    </Formik>
  );
};

export default ListYourBusiness;
