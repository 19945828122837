import BackButton from 'components/Common/BackButton/BackButton';
import { CommonTitleText } from 'components/Common/Styled/TypographyStyed';
import { useNavigate } from 'react-router-dom';

import {
  AccountProfile,
  ChevronIcon,
  Container,
  LinkCaption,
  LinkItem,
  Links,
  LinkTitle,
} from '../AccountStyled';

const links = [
  {
    title: 'Linked bank account',
    caption: 'Update your bank account details.',
    page: '/account/payments/link-bank-account',
  },
  {
    title: 'Transactions',
    caption: 'View your transaction history.',
    page: '/transactions',
  },
];

const Payments = () => {
  const navigate = useNavigate();
  return (
    <Container>
      <AccountProfile sx={{ position: 'relative' }}>
        <BackButton onClick={() => navigate('/account')} />
        <CommonTitleText mb={2}>PAYMENTS</CommonTitleText>
        <Links>
          {links.map(({ title, caption, page }, index) => {
            return (
              <LinkItem key={index} onClick={() => navigate(page)}>
                <LinkTitle>{title}</LinkTitle>
                <LinkCaption>{caption}</LinkCaption>
                <ChevronIcon />
              </LinkItem>
            );
          })}
        </Links>
      </AccountProfile>
    </Container>
  );
};

export default Payments;
