/* eslint-disable quotes */
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';
import AvatarWithFallback from '../../components/Common/AvatarWithFallback/AvatarWithFallback';
import { HeaderText } from '../../components/Common/Styled/CommonStyled';
import { TotalPerformance } from '../../components/Common/TotalPerformance';
import { ReferrersDashboard } from '../../components/ReferrersDashboard';
import { ReferralsDashboard } from '../../components/ReferralsDashboard';
import { useGetSalesAnalytics } from '../../hooks/TotalPerformance/useTotalPerformance';
import { getUser } from '../../redux/selectors/auth.selector';
import { Container } from './HomeStyled';

export function Home(): JSX.Element {
  const user = useSelector(getUser);
  const { data: totalPerformance, isLoading: isLoadingTotalPerformance } =
    useGetSalesAnalytics();
  return (
    <Container>
      <Box>
        <AvatarWithFallback
          height={80}
          width={80}
          image={user?.vendor?.logo}
          fromCDN
          hasBorder
        />
        <HeaderText variant="h1">{user?.vendor?.business_name}</HeaderText>
      </Box>

      <TotalPerformance
        headerText="Total Sales Performance"
        isLoading={isLoadingTotalPerformance}
        data={totalPerformance?.salesPerformance ?? []}
      />
      <TotalPerformance
        headerText="Total Referral Performance"
        isLoading={isLoadingTotalPerformance}
        data={totalPerformance?.referralPerformance ?? []}
      />
      <ReferrersDashboard isDashboard />
      <ReferralsDashboard isDashboard />
    </Container>
  );
}
