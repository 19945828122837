import * as React from 'react';

type Props = {
  width?: number;
  height?: number;
};

export function SearchIcon({ width = 22, height = 22 }: Props): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 22 22"
      fill="none"
    >
      <ellipse
        cx="8.76635"
        cy="8.89104"
        rx="7.76635"
        ry="7.89104"
        stroke="#535F78"
        strokeWidth="1.5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.9603 15.2803L15.2273 14.0105L22 20.6591L20.7331 21.929L13.9603 15.2803Z"
        fill="#535F78"
      />
    </svg>
  );
}
