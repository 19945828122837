import { CircularProgress, Grid, InputBase, Stack } from '@mui/material';
import commonConstants from 'constants/common.constant';
import { useGetReferrersDashboard } from 'hooks/Referrers/useReferrers';
import { useNavigate } from 'react-router-dom';
import { themes } from 'theme';

import DashBoard, {
  useGetConstructReferrersDashboard,
} from '../Common/Dashboard/Dashboard';
import { SearchBox } from '../Common/Dashboard/DashboardStyled';
import {
  CircleButton,
  HeaderText,
  PageContainer,
} from '../Common/Styled/CommonStyled';
import { SearchIcon } from '../Icons';

type ReferrersDashboardProps = {
  isDashboard?: boolean;
};

const FILTER_BUTTON = [
  {
    text: 'Most Recent',
    direction: commonConstants.DIRECTION.DESC,
    filter: commonConstants.FILTER.DATE,
  },
  {
    text: 'Oldest to Newest',
    direction: commonConstants.DIRECTION.ASC,
    filter: commonConstants.FILTER.DATE,
  },
  {
    text: 'Performance: High - Low',
    direction: commonConstants.DIRECTION.DESC,
    filter: commonConstants.FILTER.PERFORMANCE,
  },
  {
    text: 'Performance: Low - High',
    direction: commonConstants.DIRECTION.ASC,
    filter: commonConstants.FILTER.PERFORMANCE,
  },
];

export function ReferrersDashboard({
  isDashboard = false,
}: ReferrersDashboardProps): JSX.Element {
  const navigate = useNavigate();
  const {
    query: { data, isFetching, isLoading },
    totalPage,
    handleChangePage,
    onHandleChangeKeyword,
    onHandleChangeFilter,
    page,
    keyword,
    filterSelected,
  } = useGetReferrersDashboard(isDashboard);
  let { columns, noDataText, renderSkeletons, renderValue } =
    useGetConstructReferrersDashboard();

  let customColumns: any = columns;

  if (isDashboard && columns) {
    customColumns = columns.map((item) =>
      item.id === 'action'
        ? {
            ...item,
            label: 'View All',
            headerStyle: {
              ...item.headerStyle,
              color: themes.light.colorMaximumBlueGreen,
              fontWeight: 700,
              cursor: 'pointer',
            },
            onClick: () => navigate('/referrers'),
          }
        : item,
    );
  }

  return (
    <PageContainer sx={{ padding: isDashboard ? '10px 0px' : '58px 56px' }}>
      {!isDashboard && (
        <>
          <HeaderText>Referrers</HeaderText>
          <Grid
            sx={{
              marginTop: isDashboard ? '50px' : '20px',
              alignItems: 'center',
              gap: '10px',
            }}
            container
            spacing={2}
          >
            <Grid item lg={3} sm={5} xs={12}>
              <SearchBox>
                <SearchIcon />
                <InputBase
                  placeholder={'Search Referrers'}
                  onChange={onHandleChangeKeyword}
                  defaultValue={keyword}
                />
              </SearchBox>
            </Grid>
            <Grid item lg={6} sm={6} xs={12}>
              <Stack direction="row" gap={2} alignItems="center">
                {FILTER_BUTTON.map(({ text, direction, filter }, index) => (
                  <CircleButton
                    key={text}
                    variant={
                      filterSelected === index ? 'contained' : 'outlined'
                    }
                    onClick={() => onHandleChangeFilter(direction, filter)}
                  >
                    {text}
                  </CircleButton>
                ))}
                {isFetching && <CircularProgress />}
              </Stack>
            </Grid>
          </Grid>
        </>
      )}
      <DashBoard
        data={data?.data ?? []}
        page={page}
        columns={customColumns}
        totalPage={totalPage}
        renderValue={renderValue}
        renderSkeletons={renderSkeletons}
        handleChangePage={handleChangePage}
        isLoading={isLoading}
        noDataText={noDataText}
        showPagination={!isDashboard}
      />
    </PageContainer>
  );
}
