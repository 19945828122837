import * as React from 'react';

type Props = {
  width?: number;
  height?: number;
};

export function MailIcon({ width = 32, height = 32 }: Props): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      version="1.1"
      width={width}
      height={height}
      viewBox="0 0 256 256"
      xmlSpace="preserve"
    >
      <defs></defs>
      <g transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)">
        <path
          d="M 81.956 77.972 H 8.044 C 3.608 77.972 0 74.363 0 69.928 V 20.072 c 0 -4.435 3.608 -8.043 8.044 -8.043 h 73.912 c 4.436 0 8.044 3.608 8.044 8.043 v 49.855 C 90 74.363 86.392 77.972 81.956 77.972 z M 8.044 14.029 C 4.711 14.029 2 16.74 2 20.072 v 49.855 c 0 3.333 2.711 6.044 6.044 6.044 h 73.912 c 3.333 0 6.044 -2.711 6.044 -6.044 V 20.072 c 0 -3.333 -2.711 -6.043 -6.044 -6.043 H 8.044 z"
          transform=" matrix(1 0 0 1 0 0) "
          strokeLinecap="round"
        />
        <path
          d="M 45 54.395 c -0.242 0 -0.485 -0.088 -0.676 -0.263 L 2.531 15.79 c -0.407 -0.373 -0.435 -1.006 -0.061 -1.413 c 0.373 -0.407 1.006 -0.436 1.413 -0.061 L 45 52.037 l 41.118 -37.721 c 0.405 -0.374 1.039 -0.347 1.413 0.061 c 0.373 0.407 0.346 1.04 -0.062 1.413 L 45.676 54.132 C 45.485 54.307 45.242 54.395 45 54.395 z"
          transform=" matrix(1 0 0 1 0 0) "
          strokeLinecap="round"
        />
        <path
          d="M 15.948 64.258 c -0.271 0 -0.54 -0.109 -0.737 -0.324 c -0.374 -0.406 -0.346 -1.039 0.061 -1.413 l 19.902 -18.257 c 0.407 -0.375 1.039 -0.346 1.413 0.061 c 0.374 0.407 0.346 1.04 -0.061 1.413 L 16.624 63.995 C 16.432 64.171 16.189 64.258 15.948 64.258 z"
          transform=" matrix(1 0 0 1 0 0) "
          strokeLinecap="round"
        />
        <path
          d="M 74.053 64.258 c -0.242 0 -0.484 -0.087 -0.676 -0.263 L 53.475 45.737 c -0.407 -0.373 -0.435 -1.006 -0.062 -1.413 c 0.374 -0.409 1.008 -0.435 1.413 -0.061 l 19.902 18.257 c 0.407 0.374 0.435 1.006 0.062 1.413 C 74.593 64.148 74.323 64.258 74.053 64.258 z"
          transform=" matrix(1 0 0 1 0 0) "
          strokeLinecap="round"
        />
      </g>
    </svg>
  );
}
