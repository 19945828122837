import React from 'react';
import { Stack } from '@mui/material';
import LogoIReferIcon from 'assets/images/icon-logo-irefer-trademark.png';
import { StyledBox } from 'components/Common/Styled/CommonStyled';
import {
  CommonSubtext,
  CommonTitleText,
} from 'components/Common/Styled/TypographyStyed';
import { useResendConfirmationEmail } from 'hooks/Auth/useAuth';
import useCounter from 'hooks/Common/useCounter';
import { Helmet } from 'react-helmet-async';
import { Navigate, useLocation } from 'react-router-dom';
import { themes } from 'theme';

import { Logo, StyledStack, TextButton } from '../AuthStyled';

const ResendVerifyEmail = () => {
  const location = useLocation();

  const { mutate: mutateResendEmail, isLoading } = useResendConfirmationEmail();
  const { counter, resetCounter } = useCounter(-1);
  if (!location?.state?.verificationInfo) {
    return <Navigate to="/auth/login" />;
  }

  const handleResend = () => {
    mutateResendEmail(
      {
        email: location?.state?.verificationInfo?.username ?? '',
      },
      {
        onSuccess: () => resetCounter(),
      },
    );
  };

  return (
    <StyledBox sx={{ padding: '52px', position: 'relative' }}>
      <StyledStack
        sx={{
          height: '100%',
          width: '100%',
          justifyContent: 'center',
          gap: 3,
        }}
      >
        <Helmet>
          <title>Verify Your Email</title>
          <meta name="description" content="iRefer Verify Your Email" />
        </Helmet>
        <Stack alignItems="center" mb={2}>
          <Logo
            alt="iReferLogo"
            src={LogoIReferIcon}
            style={{ width: '104px' }}
          />
        </Stack>
        <CommonTitleText>VERIFY YOUR EMAIL ADDRESS</CommonTitleText>
        <StyledStack>
          <CommonSubtext>
            We’ve sent a verification email to{' '}
            {location?.state?.verificationInfo?.username}
          </CommonSubtext>
          <CommonSubtext>Click the link in the email to verify.</CommonSubtext>
        </StyledStack>
        {counter > -1 ? (
          <CommonSubtext
            sx={{
              color: themes.light.colorMaximumBlueGreen,
              fontWeight: 500,
            }}
          >
            Resend email {counter}s
          </CommonSubtext>
        ) : (
          <TextButton disabled={isLoading} onClick={() => handleResend()}>
            Resend
          </TextButton>
        )}
      </StyledStack>
    </StyledBox>
  );
};

export default ResendVerifyEmail;
