import useCounter from 'hooks/Common/useCounter';
import { CommonSubtext, TextButton } from '../Styled/TypographyStyed';
import { themes } from 'theme';

type CountDownButtonProps = {
  initialValue?: number;
  isLoading?: boolean;
  callBack?: () => void;
  resetText: string;
  countingText: string;
};

const CountDownButton = ({
  initialValue = -1,
  isLoading,
  callBack,
  countingText,
  resetText,
}: CountDownButtonProps) => {
  const { counter, resetCounter } = useCounter(initialValue);

  const handleResend = () => {
    resetCounter();
    callBack && callBack();
  };

  return counter > -1 ? (
    <CommonSubtext
      sx={{
        color: themes.light.colorMaximumBlueGreen,
        fontWeight: 500,
      }}
    >
      {countingText} {counter}s
    </CommonSubtext>
  ) : (
    <TextButton disabled={isLoading} onClick={handleResend}>
      {resetText}
    </TextButton>
  );
};

export default CountDownButton;
