import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {
  Pagination,
  PaginationItem,
  Paper,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import {
  ReferrersListItem,
  usePrefetchReferrerDetail,
} from 'hooks/Referrers/useReferrers';
import {
  ReferralsListItem,
  usePrefetchReferralDetail,
} from 'hooks/Referrals/useReferrals';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { themes } from 'theme';
import formatUtil from 'utils/format.util';

import { ReferrersColumn } from '../../ReferrersDashboard';
import { ReferralsColumn } from '../../ReferralsDashboard';
import { RoundedButton } from '../Styled/CommonStyled';
import { CustomerColumn } from 'components/Transactions/TransactionsDashboard';
import commonConstants from 'constants/common.constant';
import {
  TransactionListItemResponse,
  usePrefetchTransactionDetail,
} from 'hooks/Transactions/useTransactions';

export enum DashBoardType {
  ReferrersDashboard,
  ReferralsDashboard,
  TransactionsDashboard,
}

const NO_DATA_TEXT = {
  [DashBoardType.ReferrersDashboard]: 'There are no referrers to display.',
  [DashBoardType.ReferralsDashboard]: 'There are no referrals to display.',
  [DashBoardType.TransactionsDashboard]:
    'There are no transactions to display.',
};

const ID_TYPES = {
  [DashBoardType.ReferrersDashboard]: {
    REFERRER: { id: 'referrer', label: 'Referrer' },
    // REFERRALS_SENT: { id: 'referrals_sent', label: 'Referrals Sent' },
    LINK_PURCHASES: { id: 'link_purchases', label: 'Link Purchases' },
    REFERRAL_SALES: { id: 'referral_sales', label: 'Referral Sales' },
    REFUNDED_SALES: { id: 'refund_total', label: 'Refunded Sales' },
    REFERRER_COMMISSION: { id: 'referee_profit', label: 'Referrer Commission' },
    VENDOR_NET: { id: 'vendor_net_profit', label: 'Merchant Net Sales' },
    ACTIONS: { id: 'action', label: '' },
  },
  [DashBoardType.ReferralsDashboard]: {
    REFERRALS: { id: 'referrals', label: 'Referrals' },
    DURATION: { id: 'duration', label: 'Referral Duration' },
    LINK_PURCHASES: { id: 'link_purchases', label: 'Link Purchases' },
    REFERRAL_SALES: { id: 'referral_sales', label: 'Referral Sales' },
    REFUNDED_SALES: { id: 'refund_total', label: 'Refunded Sales' },
    REFERRER_COMMISSION: { id: 'referee_profit', label: 'Referrer Commission' },
    VENDOR_NET: { id: 'vendor_net_profit', label: 'Merchant Net Sales' },
    ACTIONS: { id: 'action', label: '' },
  },
  [DashBoardType.TransactionsDashboard]: {
    CUSTOMER: { id: 'customer', label: 'Customer' },
    TRANSACTION_ID: { id: 'transaction_id', label: 'Transaction ID' },
    DATE: { id: 'date', label: 'Date' },
    TOTAL_AMOUNT: { id: 'total', label: 'Total' },
    REFERRER_COMMISSION: {
      id: 'referrer_commission',
      label: 'Referrer Commission',
    },
    VENDOR_NET: { id: 'vendor_net', label: 'Merchant Net Sales' },
    TRANSACTION_FEE: { id: 'transaction_fee', label: 'Transaction fee' },
    ACTIONS: { id: 'action', label: '' },
  },
};

const COLUMNS: Record<DashBoardType, ColumnDashboard[]> = {
  [DashBoardType.ReferrersDashboard]: [
    {
      id: ID_TYPES[DashBoardType.ReferrersDashboard].REFERRER.id,
      label: ID_TYPES[DashBoardType.ReferrersDashboard].REFERRER.label,
      headerStyle: {
        minWidth: 170,
        maxWidth: 370,
      },
    },
    // {
    //   id: ID_TYPES[DashBoardType.ReferrersDashboard].REFERRALS_SENT.id,
    //   label: ID_TYPES[DashBoardType.ReferrersDashboard].REFERRALS_SENT.label,
    //   headerStyle: {
    //     minWidth: 170,
    //   },
    // },
    {
      id: ID_TYPES[DashBoardType.ReferrersDashboard].LINK_PURCHASES.id,
      label: ID_TYPES[DashBoardType.ReferrersDashboard].LINK_PURCHASES.label,
      headerStyle: {
        minWidth: 170,
      },
    },
    {
      id: ID_TYPES[DashBoardType.ReferrersDashboard].REFERRAL_SALES.id,
      label: ID_TYPES[DashBoardType.ReferrersDashboard].REFERRAL_SALES.label,
      headerStyle: {
        minWidth: 170,
      },
    },
    {
      id: ID_TYPES[DashBoardType.ReferrersDashboard].REFUNDED_SALES.id,
      label: ID_TYPES[DashBoardType.ReferrersDashboard].REFUNDED_SALES.label,
      headerStyle: {
        minWidth: 170,
      },
    },
    {
      id: ID_TYPES[DashBoardType.ReferrersDashboard].REFERRER_COMMISSION.id,
      label:
        ID_TYPES[DashBoardType.ReferrersDashboard].REFERRER_COMMISSION.label,
      headerStyle: {
        minWidth: 170,
      },
    },
    {
      id: ID_TYPES[DashBoardType.ReferrersDashboard].VENDOR_NET.id,
      label: ID_TYPES[DashBoardType.ReferrersDashboard].VENDOR_NET.label,
      headerStyle: {
        minWidth: 170,
      },
    },
    {
      id: ID_TYPES[DashBoardType.ReferrersDashboard].ACTIONS.id,
      label: ID_TYPES[DashBoardType.ReferrersDashboard].ACTIONS.label,
      headerStyle: {
        minWidth: 170,
        textAlign: 'right',
      },
    },
  ],
  [DashBoardType.ReferralsDashboard]: [
    {
      id: ID_TYPES[DashBoardType.ReferralsDashboard].REFERRALS.id,
      label: ID_TYPES[DashBoardType.ReferralsDashboard].REFERRALS.label,
      headerStyle: {
        minWidth: 170,
        maxWidth: 370,
      },
    },
    {
      id: ID_TYPES[DashBoardType.ReferralsDashboard].DURATION.id,
      label: ID_TYPES[DashBoardType.ReferralsDashboard].DURATION.label,
      headerStyle: {
        minWidth: 170,
      },
    },
    {
      id: ID_TYPES[DashBoardType.ReferralsDashboard].LINK_PURCHASES.id,
      label: ID_TYPES[DashBoardType.ReferralsDashboard].LINK_PURCHASES.label,
      headerStyle: {
        minWidth: 170,
      },
    },
    {
      id: ID_TYPES[DashBoardType.ReferralsDashboard].REFERRAL_SALES.id,
      label: ID_TYPES[DashBoardType.ReferralsDashboard].REFERRAL_SALES.label,
      headerStyle: {
        minWidth: 170,
      },
    },
    {
      id: ID_TYPES[DashBoardType.ReferralsDashboard].REFUNDED_SALES.id,
      label: ID_TYPES[DashBoardType.ReferralsDashboard].REFUNDED_SALES.label,
      headerStyle: {
        minWidth: 170,
      },
    },
    {
      id: ID_TYPES[DashBoardType.ReferralsDashboard].REFERRER_COMMISSION.id,
      label:
        ID_TYPES[DashBoardType.ReferralsDashboard].REFERRER_COMMISSION.label,
      headerStyle: {
        minWidth: 170,
      },
    },
    {
      id: ID_TYPES[DashBoardType.ReferralsDashboard].VENDOR_NET.id,
      label: ID_TYPES[DashBoardType.ReferralsDashboard].VENDOR_NET.label,
      headerStyle: {
        minWidth: 170,
      },
    },
    {
      id: ID_TYPES[DashBoardType.ReferralsDashboard].ACTIONS.id,
      label: ID_TYPES[DashBoardType.ReferralsDashboard].ACTIONS.label,
      headerStyle: {
        minWidth: 170,
        textAlign: 'right',
      },
    },
  ],
  [DashBoardType.TransactionsDashboard]: [
    {
      id: ID_TYPES[DashBoardType.TransactionsDashboard].CUSTOMER.id,
      label: ID_TYPES[DashBoardType.TransactionsDashboard].CUSTOMER.label,
      headerStyle: {
        minWidth: 170,
        maxWidth: 370,
      },
    },
    {
      id: ID_TYPES[DashBoardType.TransactionsDashboard].TRANSACTION_ID.id,
      label: ID_TYPES[DashBoardType.TransactionsDashboard].TRANSACTION_ID.label,
      headerStyle: {
        minWidth: 170,
      },
    },
    {
      id: ID_TYPES[DashBoardType.TransactionsDashboard].DATE.id,
      label: ID_TYPES[DashBoardType.TransactionsDashboard].DATE.label,
      headerStyle: {
        minWidth: 170,
      },
    },
    {
      id: ID_TYPES[DashBoardType.TransactionsDashboard].TOTAL_AMOUNT.id,
      label: ID_TYPES[DashBoardType.TransactionsDashboard].TOTAL_AMOUNT.label,
      headerStyle: {
        minWidth: 170,
      },
    },
    {
      id: ID_TYPES[DashBoardType.TransactionsDashboard].REFERRER_COMMISSION.id,
      label:
        ID_TYPES[DashBoardType.TransactionsDashboard].REFERRER_COMMISSION.label,
      headerStyle: {
        minWidth: 170,
      },
    },
    {
      id: ID_TYPES[DashBoardType.TransactionsDashboard].VENDOR_NET.id,
      label: ID_TYPES[DashBoardType.TransactionsDashboard].VENDOR_NET.label,
      headerStyle: {
        minWidth: 170,
      },
    },
    {
      id: ID_TYPES[DashBoardType.TransactionsDashboard].TRANSACTION_FEE.id,
      label:
        ID_TYPES[DashBoardType.TransactionsDashboard].TRANSACTION_FEE.label,
      headerStyle: {
        minWidth: 170,
      },
    },
    {
      id: ID_TYPES[DashBoardType.TransactionsDashboard].ACTIONS.id,
      label: ID_TYPES[DashBoardType.TransactionsDashboard].ACTIONS.label,
      headerStyle: {
        minWidth: 170,
        textAlign: 'right',
      },
    },
  ],
};

export const useGetConstructReferralsDashboard = () => {
  const prefetchReferralDetail = usePrefetchReferralDetail();
  const noDataText = useMemo(
    () => NO_DATA_TEXT[DashBoardType.ReferralsDashboard],
    [],
  );
  const navigate = useNavigate();
  const columns = useMemo(() => COLUMNS[DashBoardType.ReferralsDashboard], []);
  const renderSkeletons = useCallback((id: string) => {
    let returnValue = null;
    switch (id) {
      case ID_TYPES[DashBoardType.ReferralsDashboard].REFERRALS.id:
        returnValue = (
          <ReferralsColumn
            logo={''}
            name={''}
            subText={''}
            avatarVariant="circular"
            isLoading
          />
        );
        break;
      default:
        returnValue = <Skeleton variant="rounded" width={50} height={20} />;
    }
    return returnValue;
  }, []);
  const renderValue = useCallback(
    (id: keyof ReferralsListItem, row: ReferralsListItem) => {
      const value = row?.[id];
      let returnValue = null;
      switch (id) {
        case ID_TYPES[DashBoardType.ReferralsDashboard].REFERRALS.id:
          returnValue = (
            <ReferralsColumn
              logo={row?.referral.product_image}
              name={`${row?.referral?.product_title}`}
              subText={row?.referral?.referral_url}
            />
          );
          break;
        case ID_TYPES[DashBoardType.ReferralsDashboard].REFERRAL_SALES.id:
        case ID_TYPES[DashBoardType.ReferralsDashboard].REFUNDED_SALES.id:
        case ID_TYPES[DashBoardType.ReferralsDashboard].REFERRER_COMMISSION.id:
        case ID_TYPES[DashBoardType.ReferralsDashboard].VENDOR_NET.id:
          returnValue = formatUtil.divideTo100AndFormatCurrency(+value);
          break;
        case ID_TYPES[DashBoardType.ReferralsDashboard].ACTIONS.id:
          returnValue = (
            <RoundedButton
              variant="outlined"
              onClick={() => navigate(`/referral/${row.referral.id}`)}
              onMouseEnter={() => prefetchReferralDetail(row.referral.id)}
            >
              View More
            </RoundedButton>
          );
          break;
        case ID_TYPES[DashBoardType.ReferralsDashboard].DURATION.id:
          returnValue = row.referral.referral_duration;
          break;
        default:
          returnValue = value || '--';
      }

      return returnValue;
    },
    [navigate, prefetchReferralDetail],
  );
  return { noDataText, columns, renderSkeletons, renderValue };
};

export const useGetConstructTransactionsDashboard = () => {
  const prefetchTransactionDetail = usePrefetchTransactionDetail();
  const noDataText = useMemo(
    () => NO_DATA_TEXT[DashBoardType.TransactionsDashboard],
    [],
  );
  const navigate = useNavigate();
  const columns = useMemo(
    () => COLUMNS[DashBoardType.TransactionsDashboard],
    [],
  );
  const renderSkeletons = useCallback((id: string) => {
    let returnValue = null;
    switch (id) {
      case ID_TYPES[DashBoardType.TransactionsDashboard].CUSTOMER.id:
        returnValue = (
          <CustomerColumn name={''} avatarVariant="circular" isLoading />
        );
        break;
      default:
        returnValue = <Skeleton variant="rounded" width={50} height={20} />;
    }
    return returnValue;
  }, []);
  const renderValue = useCallback(
    (
      id: keyof TransactionListItemResponse,
      row: TransactionListItemResponse,
    ) => {
      const value = row?.[id as keyof TransactionListItemResponse];
      let returnValue = null;
      switch (id) {
        case ID_TYPES[DashBoardType.TransactionsDashboard].CUSTOMER.id:
          returnValue = <CustomerColumn name={row.customer_email} />;
          break;
        case ID_TYPES[DashBoardType.TransactionsDashboard].DATE.id:
          returnValue = formatUtil.formatDate({
            date: row?.date,
            designateFormat: commonConstants.DATE_FORMAT.DD_MMM_YYYY,
          });
          break;
        case ID_TYPES[DashBoardType.TransactionsDashboard].TOTAL_AMOUNT.id:
        case ID_TYPES[DashBoardType.TransactionsDashboard].TRANSACTION_FEE.id:
          returnValue = formatUtil.divideTo100AndFormatCurrency(+value);
          break;
        case ID_TYPES[DashBoardType.TransactionsDashboard].REFERRER_COMMISSION
          .id:
          returnValue = `${
            row?.referrer?.email
          } - ${formatUtil.divideTo100AndFormatCurrency(
            row?.referrer?.payout as number,
          )}`;
          break;
        case ID_TYPES[DashBoardType.TransactionsDashboard].VENDOR_NET.id:
          returnValue = `${
            row?.vendor?.business_name
          } - ${formatUtil.divideTo100AndFormatCurrency(row?.vendor?.payout)}`;
          break;
        case ID_TYPES[DashBoardType.TransactionsDashboard].ACTIONS.id:
          returnValue = (
            <RoundedButton
              variant="outlined"
              onClick={() => navigate(`/transactions/${row.transaction_id}`)}
              onMouseEnter={() => prefetchTransactionDetail(row.transaction_id)}
            >
              View More
            </RoundedButton>
          );
          break;
        default:
          returnValue = value || '--';
      }

      return returnValue;
    },
    [navigate, prefetchTransactionDetail],
  );
  return { noDataText, columns, renderSkeletons, renderValue };
};

export const useGetConstructReferrersDashboard = () => {
  const prefetchReferrerDetail = usePrefetchReferrerDetail();
  const noDataText = useMemo(
    () => NO_DATA_TEXT[DashBoardType.ReferrersDashboard],
    [],
  );
  const navigate = useNavigate();
  const columns = useMemo(() => COLUMNS[DashBoardType.ReferrersDashboard], []);
  const renderSkeletons = useCallback((id: string) => {
    let returnValue = null;
    switch (id) {
      case ID_TYPES[DashBoardType.ReferrersDashboard].REFERRER.id:
        returnValue = (
          <ReferrersColumn
            logo={''}
            name={''}
            subText={''}
            avatarVariant="circular"
            isLoading
          />
        );
        break;
      default:
        returnValue = <Skeleton variant="rounded" width={50} height={20} />;
    }
    return returnValue;
  }, []);
  const renderValue = useCallback(
    (id: keyof ReferrersListItem, row: ReferrersListItem) => {
      const value = row?.[id];
      let returnValue = null;
      switch (id) {
        case ID_TYPES[DashBoardType.ReferrersDashboard].REFERRER.id:
          returnValue = (
            <ReferrersColumn
              logo={row?.referrer?.logo}
              name={`${row.referrer?.first_name} ${row.referrer?.last_name}`}
              subText={row?.referrer?.active_referrals?.toString()}
            />
          );
          break;
        case ID_TYPES[DashBoardType.ReferrersDashboard].REFERRAL_SALES.id:
        case ID_TYPES[DashBoardType.ReferrersDashboard].REFUNDED_SALES.id:
        case ID_TYPES[DashBoardType.ReferrersDashboard].REFERRER_COMMISSION.id:
        case ID_TYPES[DashBoardType.ReferrersDashboard].VENDOR_NET.id:
          returnValue = formatUtil.divideTo100AndFormatCurrency(+value);
          break;
        case ID_TYPES[DashBoardType.ReferrersDashboard].ACTIONS.id:
          returnValue = (
            <RoundedButton
              variant="outlined"
              onMouseEnter={() => prefetchReferrerDetail(row?.referrer?.id)}
              onClick={() => navigate(`/referrer-profile/${row?.referrer?.id}`)}
            >
              View Profile
            </RoundedButton>
          );
          break;
        default:
          returnValue = value ?? '--';
      }

      return returnValue;
    },
    [navigate, prefetchReferrerDetail],
  );
  return { noDataText, columns, renderSkeletons, renderValue };
};

export type ColumnType = {
  id: string;
  label: string;
  minWidth?: number;
  maxWidth?: number;
  textAlign?: string;
};

const PreviousButton = () => {
  return (
    <Stack direction="row" alignItems="center">
      <NavigateBeforeIcon />
      Prev
    </Stack>
  );
};
const NextButton = () => {
  return (
    <Stack direction="row" alignItems="center">
      Next <NavigateNextIcon />
    </Stack>
  );
};

type DashboardProps<T> = {
  data: T[];
  columns: ColumnDashboard[];
  handleChangePage: (
    _event: React.ChangeEvent<unknown>,
    newPage: number,
  ) => void;
  renderValue: (id: keyof T, row: T) => React.ReactNode | any;
  renderSkeletons?: (id: string) => React.ReactNode;
  totalPage: number;
  page: number;
  isLoading?: boolean;
  noDataText?: string;
  showPagination?: boolean;
};

const DashBoard = <T,>({
  data,
  columns,
  handleChangePage,
  renderValue,
  totalPage,
  page,
  renderSkeletons,
  isLoading,
  noDataText = 'No Data Available',
  showPagination = true,
}: DashboardProps<T>) => {
  return (
    <Paper sx={{ width: '100%', overflow: 'hidden', margin: '2rem 0 0' }}>
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column, index: number) => (
                <TableCell
                  key={index}
                  style={{
                    color:
                      index === 0
                        ? themes.light.colorBlack
                        : themes.light.colorGray,
                    fontSize: index === 0 ? '18px' : '14px',
                    fontWeight: index === 0 ? 700 : 400,
                    ...column.headerStyle,
                  }}
                  onClick={column.onClick}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading && renderSkeletons && (
              <TableRow role="checkbox" tabIndex={-1}>
                {columns.map((column, indexColumn: number) => {
                  return (
                    <TableCell
                      key={indexColumn}
                      sx={{
                        fontSize: '14px',
                        fontWeight: 500,
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        ...column.cellStyle,
                      }}
                    >
                      {renderSkeletons(column.id)}
                    </TableCell>
                  );
                })}
              </TableRow>
            )}
            {data?.length === 0 && !isLoading && (
              <TableRow role="checkbox" tabIndex={-1}>
                <TableCell
                  colSpan={columns.length ?? 1}
                  sx={{ textAlign: 'center' }}
                >
                  {noDataText}
                </TableCell>
              </TableRow>
            )}
            {data?.length > 0 &&
              renderValue &&
              data?.map((row: any, indexRow: number) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={indexRow}>
                    {columns.map((column: any, indexColumn: number) => {
                      return (
                        <TableCell
                          key={indexColumn}
                          sx={{
                            fontSize: '14px',
                            fontWeight: 500,
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            ...column.style,
                          }}
                        >
                          {renderValue(column.id, row)}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      {showPagination && (
        <Pagination
          sx={{ display: 'flex', justifyContent: 'flex-end', margin: '24px' }}
          count={totalPage}
          shape="rounded"
          onChange={handleChangePage}
          page={page}
          renderItem={(item) => (
            <PaginationItem
              components={{
                previous: PreviousButton,
                next: NextButton,
              }}
              {...item}
            />
          )}
        />
      )}
    </Paper>
  );
};

export default DashBoard;
