import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import { InputAdornment, styled, TextField } from '@mui/material';
import { Field } from 'formik';
import React from 'react';
import { PatternFormat } from 'react-number-format';
import { themes } from 'theme';

const StyledField = styled(TextField)({
  '& .MuiInputLabel-root': {
    '&.Mui-focused': {
      color: themes.light.colorBlack,
    },
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      border: `1px solid ${themes.light.borderColor}`,
      borderRadius: '4px',
    },
    '&.Mui-focused fieldset': {
      borderColor: themes.light.colorMaximumBlueGreen,
    },
  },
});

const NumberFormatInput = (props: any) => {
  const {
    name,
    label,
    maxLength,
    format,
    displayCheck,
    endAdornment,
    ...rest
  } = props;
  return (
    <Field name={name}>
      {({ field, form, meta }: { field: any; form: any; meta: any }) => {
        return (
          <PatternFormat
            format={format}
            fullWidth
            customInput={StyledField}
            {...field}
            label={label}
            inputProps={{
              onBlur: form.handleBlur,
              maxLength,
            }}
            error={Boolean(meta.error)}
            helperText={meta.touched && meta.error}
            InputProps={{
              endAdornment:
                endAdornment ||
                (!!displayCheck && meta.touched && !meta.error ? (
                  <InputAdornment position="end">
                    <CheckCircleRoundedIcon
                      style={{ color: themes.light.colorIrishGreen }}
                    />
                  </InputAdornment>
                ) : null),
            }}
            {...rest}
          />
        );
      }}
    </Field>
  );
};

export default NumberFormatInput;
