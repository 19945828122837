import { useCountUp } from 'use-count-up';

type CountUpProps = {
  start?: number;
  end: number;
  duration?: number;
  decimalPlaces?: number;
  thousandsSeparator?: string;
  decimalSeparator?: string;
  prefix?: string;
  suffix?: string;
};

const CustomCountUp = ({
  start = 0,
  end,
  duration = 2,
  decimalPlaces = 2,
  thousandsSeparator = ',',
  decimalSeparator = '.',
  prefix = '',
  suffix = '',
}: CountUpProps) => {
  const { value } = useCountUp({
    isCounting: true,
    start,
    end,
    duration,
    decimalPlaces,
    thousandsSeparator,
    decimalSeparator,
  });
  return <>{`${prefix}${value}${suffix}`}</>;
};

export default CustomCountUp;
